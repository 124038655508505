/* eslint-disable */
import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@material-ui/core";

import { floor_summary } from "../../model/result";

const HouseholdsSummaryTable = (props: any) => {
  const householdData = props;
  // 합계
  const calSum = (mapData: any, model: any) => {
    let result = 0;
    if (props.unitState === "㎡") {
      result = mapData
        .map((tableData: any, index: number) => {
          return model.subKey && Number(tableData.areaInfo[model.subKey].toFixed(2));
        })
        .reduce((a: number, b: number) => a + b, 0);
    } else {
      result = mapData
        .map((tableData: any, index: number) => {
          return model.subKey && Number(Number(Number(tableData.areaInfo[model.subKey].toFixed(2)) / (1 / 0.3025)).toFixed(2)); // index < 5
        })
        .reduce((a: number, b: number) => a + b, 0);
    }

    return result === 0 ? 0 : result;
  };

  const convertArea = (v: number): string => {
    if (v.toString() === "-") return "-";

    if (props.unitState === "㎡") return Number(v).toFixed(2);

    return Number(Number(v) / (1 / 0.3025)).toFixed(2);
  };

  const renderCell = (model: any) => {
    const result = calSum(householdData.props.householdAreaTables, model);

    if (props.unitState === "㎡") {
      return result > 0 ? result.toFixed(2) + props.unitState : "-";
    } else {
      return result > 0 ? result.toFixed(2) + props.unitState : "-";
    }
  };

  const renderTableBody = (tableData: any, model: any) => {
    if (tableData.areaInfo[model.subKey]) {
      return (
        <TableCell align="left" className="td" key={model.subKey}>
          {convertArea(tableData.areaInfo[model.subKey].toFixed(2))}
          {props.unitState}
        </TableCell>
      );
    } else if (tableData.areaInfo[model.subKey] === 0) {
      return (
        <TableCell align="left" className="td" key={model.subKey}>
          {" - "}
        </TableCell>
      );
    }
  };

  return (
    <div>
      {
        <TableContainer>
          <Table size="small" className={`HouseholdSummary-table`}>
            <TableHead className="table-head">
              <TableRow className="tr">
                <TableCell className="th tc-125" align="left">
                  층
                </TableCell>
                {floor_summary.map(
                  (data, index: number) =>
                    index > 0 &&
                    data.subKey && (
                      <TableCell className="th tc-125" align="left" key={data.label + data.value}>
                        {data.label}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {householdData.props.householdAreaTables.map((tableData: any) => (
                <TableRow className="tr" key={tableData.name + "table-body"}>
                  <TableCell className="td" align="left" width="100px">
                    {tableData.name}
                  </TableCell>
                  {/* 201호 */}
                  {floor_summary.map((model: any, index: number) => index > 0 && renderTableBody(tableData, model))}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow className="tr">
                <TableCell className="th highlight" align="left" width="125">
                  합계
                </TableCell>
                {floor_summary.map(
                  (model: any) =>
                    model.subKey && (
                      <TableCell className="th highlight" align="left" width="100px" key={`${model.subKey}합계`}>
                        {renderCell(model)}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      }
    </div>
  );
};

export default HouseholdsSummaryTable;
