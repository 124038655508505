import React, { Component } from "react";
import { Scene } from "./Scene";
import * as THREE from "three";
import wkx from "wkx";
import { DEMData } from "./DataManager";
import * as jsts from "jsts";
import _ from "lodash";
// import queryString from 'query-string';
// import { default as moment } from 'moment';
import { MakeSubBuildingMesh, loadTexture } from "./MeshManager";
import Axios from "axios";
import { GetS3UrlAPI } from "../api/FilesApi";

// import dataJson from './data/result.json';

interface SubBuildingData {
  id: string;
  geom: string;
  shape: string;
  baseHeight: number;
  height: number | null;
  floors: number | null;
}

interface VisualizerProps {}

interface VisualizerState {
  centerInTM: THREE.Vector3;
  siteCenterInScene: THREE.Vector3;
  demFinished: boolean;
  subModelFinished: boolean;
  dem: THREE.Group;
  subBuilding: THREE.Group;
  pnu: string;
  showLightSlider: boolean;
  slider: boolean;
  lightIndex: number;
  sunButtonOver: boolean;
  showInfo: boolean;
  demWithRoad: boolean;
  loadingText: string;
  buildingYValue: number;
  bottomHeight: number;
}

export class Visualizer extends Component<any, VisualizerState> {
  state: VisualizerState = {
    siteCenterInScene: new THREE.Vector3(0),
    centerInTM: new THREE.Vector3(0),
    demFinished: false,
    subModelFinished: false,
    dem: new THREE.Group(),
    subBuilding: new THREE.Group(),
    pnu: "",
    showLightSlider: true,
    slider: false,
    lightIndex: 4,
    sunButtonOver: false,
    showInfo: false,
    demWithRoad: false,
    loadingText: "프로젝트 로딩 중입니다.",
    bottomHeight: 0,
    buildingYValue: 0,
  };

  siteLine: THREE.Line[] = [];
  demData: DEMData[] = [];
  siteVertsArray: THREE.Vector2[][] = [];
  subBuilding = new THREE.Group();
  subBuildingRange = 50;
  lightTimeText = "12:00";
  lightMiddleIndex = 4;

  resultJson: any = "";
  json: any = "";
  result: any;

  demTexture: any; // = loadTexture('./data/demTexture.png');
  demRoadtexture: any;
  raycaster = new THREE.Raycaster();
  reader = new jsts.io.WKTReader();
  demMaterial = new THREE.MeshStandardMaterial({ color: 0xffffff });

  subBuildingMaterial: THREE.Material[] = [
    new THREE.MeshStandardMaterial({ color: "#bbbbbb", transparent: true, opacity: 0.8 }),
    new THREE.MeshStandardMaterial({ color: "#bbbbbb", transparent: true, opacity: 0.8, side: THREE.BackSide }),
  ];
  nearSubBuildingMaterial: THREE.Material[] = [
    new THREE.MeshStandardMaterial({ color: "#bbbbbb", transparent: true, opacity: 0.3, depthWrite: false }),
    new THREE.MeshStandardMaterial({ color: "#bbbbbb", transparent: true, opacity: 0.3, depthWrite: false, side: THREE.BackSide }),
  ];

  componentDidUpdate(previousProps: VisualizerProps, previousState: VisualizerState) {
    if (previousState.siteCenterInScene !== this.state.siteCenterInScene) {
      this.state.subBuilding.position.set(this.state.siteCenterInScene.x, 0, this.state.siteCenterInScene.z);
    }
  }

  componentDidMount = async () => {
    this.getResultData();
  };

  getResultData = async () => {
    let load = false;
    try {
      let credentialedURL = await GetS3UrlAPI("s3://teneleven-engine-result/prod/" + this.props.projID + `/${this.props.reportsNumber}/result/apiResult.json`, "teneleven-engine-result");
      this.result = (await Axios.get(credentialedURL.data)).data; //r.data;
      let demTextureCredentialURL = await GetS3UrlAPI(this.result.resultData.surroundingInfo.textureInfo.demTextureDir, "teneleven-engine-result");
      this.demTexture = loadTexture(demTextureCredentialURL.data);
      let demRoadTextureCredentialURL = await GetS3UrlAPI(this.result.resultData.surroundingInfo.textureInfo.roadTextureDir, "teneleven-engine-result");
      this.demRoadtexture = loadTexture(demRoadTextureCredentialURL.data);
      load = true;
    } catch (e) {
      console.log(e);
      this.setState({
        loadingText: "프로젝트 로딩에 실패했습니다.",
      });
    }

    if (load) {
      this.resultJson = this.result.resultData; // this.json.resultJson;
      let projectSite = this.resultJson.surroundingInfo.projectSite; // = this.json.shapeJson;
      let center = new THREE.Vector2(this.resultJson.surroundingInfo.siteCenter.x, this.resultJson.surroundingInfo.siteCenter.y); // this.resultJson.surroundingInfo.siteCenter;

      // @ts-ignore
      let gj = wkx.Geometry.parse(projectSite).toGeoJSON().coordinates as number[][][];

      gj.forEach((g) => {
        let vArray: THREE.Vector2[] = [];
        g.forEach((c) => {
          center.add(new THREE.Vector2(c[0], c[1]));
          vArray.push(new THREE.Vector2(c[0], c[1]));
        });
        this.siteVertsArray.push(vArray);
      });
      var jstsGeom = this.reader.read(projectSite);
      let gcenter = jstsGeom.getCentroid();
      await this.getDEM(gcenter.getX(), gcenter.getY());
      await this.getSubBuilding(gcenter.getX(), gcenter.getY());
    } else {
      this.setState({
        loadingText: "프로젝트 로딩에 실패했습니다.",
      });
    }
  };

  getScenePos = (x: number, y: number) => {
    let newPosX = (x - this.state.centerInTM.x) / 10;
    let newPosY = -((y - this.state.centerInTM.z) / 10);

    return new THREE.Vector3(newPosX, 0, newPosY);
  };

  getDEM = async (TMx: number, TMy: number) => {
    let dem = this.resultJson.surroundingInfo.demInfo;
    this.setState(
      {
        centerInTM: new THREE.Vector3((dem.x0 + dem.x1) / 2, 0, (dem.y0 + dem.y1) / 2),
      },
      () =>
        this.setState({
          siteCenterInScene: this.getScenePos(TMx, TMy),
        })
    );

    let demWidth = 11;
    const geometry = new THREE.PlaneBufferGeometry(demWidth - 1, demWidth - 1, demWidth - 1, demWidth - 1);
    geometry.rotateX(-Math.PI / 2);

    for (let y = 0; y < demWidth; y++) {
      for (let x = 0; x < demWidth; x++) {
        geometry.attributes.position.setY(y * demWidth + x, dem.data[x * demWidth + (demWidth - y - 1)] * 0.1);
      }
    }

    geometry.computeVertexNormals();
    this.demMaterial.map = this.demTexture;

    let mesh = new THREE.Mesh(geometry, this.demMaterial);
    mesh.receiveShadow = true;
    mesh.castShadow = true;
    this.state.dem.add(mesh);
    this.demData.push({ pos: new THREE.Vector2(dem.x0, dem.y0), value: dem.data });

    let aroundMat = new THREE.MeshStandardMaterial({ color: 0xffffff });
    aroundMat.color = new THREE.Color(0xbbbbbb);

    //frount mesh;
    let frountGeo = new THREE.PlaneBufferGeometry(10, 10, 10, 1);
    for (let i = 0; i < demWidth; i++) {
      frountGeo.attributes.position.setY(i, dem.data[demWidth * i] * 0.1);
    }
    let frountMesh = new THREE.Mesh(frountGeo, aroundMat);
    frountMesh.position.set(0, 0, 5);
    this.state.dem.add(frountMesh);

    //back mesh;
    let backGeo = new THREE.PlaneBufferGeometry(10, 10, 10, 1);
    for (let i = 0; i < demWidth; i++) {
      backGeo.attributes.position.setY(i, dem.data[demWidth * (demWidth - i - 1) + demWidth - 1] * 0.1);
    }
    let backMesh = new THREE.Mesh(backGeo, aroundMat);
    backMesh.position.set(0, 0, -5);
    backMesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI);
    this.state.dem.add(backMesh);

    //right mesh;
    let rightGeo = new THREE.PlaneBufferGeometry(10, 10, 10, 1);
    for (let i = 0; i < demWidth; i++) {
      rightGeo.attributes.position.setY(i, dem.data[demWidth * (demWidth - 1) + i] * 0.1);
    }
    let rightMesh = new THREE.Mesh(rightGeo, aroundMat);
    rightMesh.position.set(5, 0, 0);
    rightMesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI / 2);
    this.state.dem.add(rightMesh);

    //left mesh;
    let leftGeo = new THREE.PlaneBufferGeometry(10, 10, 10, 1);
    for (let i = 0; i < demWidth; i++) {
      leftGeo.attributes.position.setY(i, dem.data[demWidth - i - 1] * 0.1);
    }
    let leftMesh = new THREE.Mesh(leftGeo, aroundMat);
    leftMesh.position.set(-5, 0, 0);
    leftMesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), -Math.PI / 2);
    this.state.dem.add(leftMesh);

    await this.setSiteInformation();
  };

  switchDemTexture = () => {
    let withRoad = !this.state.demWithRoad;
    if (withRoad) {
      this.demMaterial.map = this.demRoadtexture;
    } else {
      this.demMaterial.map = this.demTexture;
    }

    this.setState({
      demWithRoad: withRoad,
    });
  };

  getDemHeight = (point: THREE.Vector3) => {
    let rp = new THREE.Vector3(point.x + this.state.siteCenterInScene.x, 1000, point.z + this.state.siteCenterInScene.z);
    this.raycaster.set(rp, new THREE.Vector3(0, -1, 0));
    this.raycaster.near = -1000;
    this.raycaster.far = 1000;

    let points = this.raycaster.intersectObjects(this.state.dem.children, true);
    if (points[0]) {
      return points[0].point.y;
    } else return 0;
  };

  setSiteInformation = async () => {
    let maxHeight = 0;
    let minHeight = 10000;

    let dem = this.resultJson.surroundingInfo.demInfo;
    // console.log(dem);
    for (let i = 0; i < dem.data.length; i++) {
      maxHeight = Math.max(maxHeight, Number(dem.data[i]) / 10);
      minHeight = Math.min(maxHeight, Number(dem.data[i]) / 10);
    }

    this.setState({
      demFinished: true,
      siteCenterInScene: new THREE.Vector3(this.state.siteCenterInScene.x, maxHeight, this.state.siteCenterInScene.z),
    });
  };

  getSubBuilding = async (TMx: number, TMy: number) => {
    this.state.subBuilding.scale.set(0.1, 0.1, -0.1);

    var siteGeom = this.reader.read(this.resultJson.surroundingInfo.projectSite);
    //@ts-ignore
    let subBuildingArray = this.resultJson.surroundingInfo.surrioundingBuildingInfo as SubBuildingData[];
    let dir = new THREE.Vector2(0);
    let newSubBuildingArray: SubBuildingData[] = [];

    for (const subbuilding of subBuildingArray) {
      let subGeo = this.reader.read(subbuilding.shape);
      //@ts-ignore
      let simplify = new jsts.simplify.DouglasPeuckerSimplifier(subGeo);
      simplify.setDistanceTolerance(1.5);
      let simplifiedGeo = simplify.getResultGeometry();
      let center = simplifiedGeo.getCentroid();
      let intersectionWithSite = simplifiedGeo.intersection(siteGeom);

      if (intersectionWithSite.getArea() < subGeo.getArea() * 0.3 && intersectionWithSite.getArea() < siteGeom.getArea() * 0.5) {
        if (intersectionWithSite.getCoordinates().length > 0) {
          let interCenter = intersectionWithSite.getCentroid();
          let moveDir = new THREE.Vector2(center.getX() - interCenter.getX(), center.getY() - interCenter.getY());
          let lineWkt = `LINESTRING (
              ${center.getX()} ${center.getY()},
              ${interCenter.getX() - moveDir.x} ${interCenter.getY() - moveDir.y}
              )`;
          let lineGeo = this.reader.read(lineWkt);
          let cutLine = lineGeo.intersection(intersectionWithSite);
          let coords = cutLine.getCoordinates();
          if (coords.length > 0) dir.add(new THREE.Vector2(coords[0].x - coords[1].x, coords[0].y - coords[1].y));
        }
        newSubBuildingArray.push(subbuilding);
      }
    }
    let dem = this.resultJson.surroundingInfo.demInfo;
    //@ts-ignore
    let demWkt = `POLYGON ((
      ${dem.x0 - dir.x} ${dem.y0 - dir.y}, 
      ${dem.x1 - dir.x} ${dem.y0 - dir.y}, 
      ${dem.x1 - dir.x} ${dem.y1 - dir.y}, 
      ${dem.x0 - dir.x} ${dem.y1 - dir.y}, 
      ${dem.x0 - dir.x} ${dem.y0 - dir.y}
      ))`;
    let demBoundGeo = this.reader.read(demWkt);
    let offset = new THREE.Vector2(TMx - dir.x, TMy - dir.y);
    for (const subbuilding of newSubBuildingArray) {
      // let subbuilding = subBuildingArray[25];
      let height = 0;

      if (subbuilding.height) height = subbuilding.height;
      else height = Math.max(subbuilding.floors!, 1) * 2.8;
      height += subbuilding.baseHeight * 0.1;
      let subGeo = this.reader.read(subbuilding.shape);

      for (let i = 0; i < subGeo.getNumGeometries(); i++) {
        let geo = subGeo.getGeometryN(i);
        //@ts-ignore
        let simplify = new jsts.simplify.DouglasPeuckerSimplifier(geo);
        simplify.setDistanceTolerance(0.6);
        let simplifiedGeo = simplify.getResultGeometry();
        let material = this.subBuildingMaterial;
        let intersection = demBoundGeo.intersection(simplifiedGeo);

        for (let j = 0; j < intersection.getNumGeometries(); j++) {
          let newCoord: [number, number][] = [];
          let minHeight = 10000,
            maxHeight = 0;

          intersection
            .getGeometryN(j)
            .getCoordinates()
            .forEach((coord) => {
              newCoord.push([coord.x, coord.y]);
              let demHeight = this.getDemHeight(new THREE.Vector3((coord.x - offset.x) / 10, 0, -(coord.y - offset.y) / 10)) * 10;
              minHeight = Math.min(minHeight, demHeight);
              maxHeight = Math.max(maxHeight, demHeight);
            });

          if (newCoord.length > 0) this.state.subBuilding.add(MakeSubBuildingMesh(newCoord, material, offset, height + (maxHeight - minHeight), minHeight, false));
        }
      }
    }

    let maxZHeight = 0;
    (this.result.resultData.buildingInfo as []).forEach((b: any) => {
      b.outlines.forEach((ol: any) => {
        ol.node.data.forEach((data: any) => {
          let height = this.getDemHeight(new THREE.Vector3(data.x / 10 - this.state.siteCenterInScene.x, 0, -data.y / 10 - this.state.siteCenterInScene.z));
          maxZHeight = Math.max(height, maxZHeight);
        });
      });
    });

    this.setState({
      buildingYValue: maxZHeight,
      subModelFinished: true,
    });
  };

  deg2LightIndex = (deg: number) => {
    let index = this.lightMiddleIndex;
    if (deg < 0) {
      index = this.lightMiddleIndex - (deg + 180) / 10;
    } else if (deg > 0) {
      index = (180 - deg) / 10 + this.lightMiddleIndex;
    }
    return index;
  };

  render = () => {
    if (this.state.demFinished && this.state.subModelFinished) {
      return (
        <React.Fragment>
          <div className="mainView" onMouseLeave={() => this.setState({ slider: false })} onMouseUp={() => this.setState({ slider: false })} onTouchEnd={() => this.setState({ slider: false })}>
            <div className="Visualizer">
              <div className="canvases">
                <Scene
                  demTextureWithRoad={this.state.demWithRoad}
                  switchDemTexture={this.switchDemTexture}
                  pnu={this.state.pnu}
                  dem={this.state.dem}
                  siteCenter={this.state.siteCenterInScene}
                  subBuilding={this.state.subBuilding}
                  siteLine={this.siteLine}
                  demData={this.demData}
                  resultJson={this.resultJson}
                  getPos={this.getScenePos}
                  summary={this.resultJson.summary}
                  districtUnitPlanInfo={this.resultJson.districtUnitPlanInfo}
                  lightIndex={this.state.lightIndex}
                  buildingYHeight={this.state.buildingYValue}
                />
              </div>

              {/* <div className='circleSlider'>
                <div className='circle'>
                  <div className='slider' onTouchStart={() => this.setState({ slider: true })} onMouseDown={() => this.setState({ slider: true })} />
                  <div className='timeText'> {this.lightTimeText}</div>
                </div>
              </div> */}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div className="loading" style={{ background: ' url("/img/login_background.jpg")', backgroundSize: "cover", backgroundPosition: "center center" }}>
          <div className="information">
            <div className="progress">
              <img src={"/img/loading.png"} style={{ width: "200px", height: "200px" }}></img>
            </div>
            {/* <div className='centerWord'>BUILDIT 3D VIEWER</div>
            <div className='loadingMessage'>
              <span>{this.state.loadingText}</span>
            </div> */}
          </div>
        </div>
      );
    }
  };
}
