import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../modules/Index";
import { numberWithCommas } from "../../utils/Format";

interface ResultOverviewTableProps {
  title: string;
  unitState: string;
}

const ResultOverviewTable = (props: ResultOverviewTableProps) => {
  const { priceReport, supportPay, 평당공사비, 점수 } = useSelector(({ selectedProject, project }: RootState) => ({
    priceReport: selectedProject.result.priceReport,
    supportPay: Number(project.data.customer.supportPay * 10000),
    // rentSellReport: selectedProject.result.rentSellReport,
    평당공사비: selectedProject.result.평당공사비,
    점수: selectedProject.result.점수,
  }));

  const { result } = useSelector(({ selectedProject }: RootState) => ({
    result: selectedProject.result,
  }));

  const getCalcConstructionPay = (unit: string, pay: number, area: number) => {
    // console.log('getCalcConstructionPay', Math.round(Number(area * pay / (1 / 0.3025))))
    // return Math.round(Number(area * pay / (1 / 0.3025)));
    return Math.round(Number(area * pay));
  };

  const [constPay, setConstPay] = useState(평당공사비 || 200); // 평당 공사비
  const [totalConstPay, setTotalConstPay] = useState(getCalcConstructionPay(props.unitState, constPay, result.realTotalArea)); // 예상 공사비용
  const [totalAllPay, setTotalAllPay] = useState(Math.round(Number(totalConstPay) + Number(priceReport.예상_토지매매가 * 0.0001)) || 0);

  useEffect(() => {
    setConstPay(평당공사비);
  }, [평당공사비]);

  useEffect(() => {
    setTotalConstPay(getCalcConstructionPay(props.unitState, constPay, result.realTotalArea));
  }, [props.unitState, constPay, result.realTotalArea]);

  useEffect(() => {
    setTotalAllPay(Math.round(Number(totalConstPay) + Number(priceReport.예상_토지매매가 * 0.0001)));
  }, [totalConstPay, priceReport.예상_토지매매가]);

  // const calSum = (key: string) => {
  //   if (!key) {
  //     return '-'
  //   }

  //   const numberList = Object.keys(rentSellReport.result).map((item) => {
  //     return Number(rentSellReport.result[item][key]).toFixed(2);
  //   });

  //   const result = numberList.map((data: any) => {
  //     return Number(data)
  //   }).reduce((a: number, b: number) => a + b, 0);

  //   return result === 0.0 ? '-' : numberWithCommas(Math.round(result));
  // }

  // const calSumNonFormat = (key: string) => {
  //   if (!key) {
  //     return '-'
  //   }

  //   const numberList = Object.keys(rentSellReport.result).map((item) => {
  //     return Number(rentSellReport.result[item][key]).toFixed(2);
  //   });

  //   const result = numberList.map((data: any) => {
  //     return Number(data)
  //   }).reduce((a: number, b: number) => a + b, 0);

  //   return result === 0.0 ? '-' : Math.round(result);
  // }

  switch (props.title) {
    case "사업성 검토":
      return (
        <TableContainer>
          <Table size="small">
            <TableHead className="table-head">
              <TableCell className="th tc-125">소요 비용</TableCell>
              <TableCell className="td no-padding">{`${numberWithCommas(Math.round(totalAllPay!))} 만원`}</TableCell>
            </TableHead>
            <TableBody>
              <TableRow className="tr">
                <TableCell className="th sub-th">토지매입 예측가</TableCell>
                <TableCell className="td sub-td no-padding">{`${numberWithCommas(Math.round(priceReport.예상_토지매매가 / 10000))} 만원`}</TableCell>
              </TableRow>
              <TableRow className="tr">
                <TableCell className="th sub-th">공사 비용</TableCell>
                <TableCell className="td sub-td no-padding">{`${numberWithCommas(Math.round(totalConstPay))} 만원`}</TableCell>
              </TableRow>
              <TableRow className="tr">
                <TableCell className="th">지원금액</TableCell>
                <TableCell className="td no-padding">{numberWithCommas(Number(supportPay * result.loc_total_household))} 만원</TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow className="tr table-foot">
                <TableCell className="th highlight">비용 합계</TableCell>
                <TableCell className="th highlight no-padding">{`${numberWithCommas(Number(supportPay * result.loc_total_household) - Number(Math.round(totalAllPay!)))} 만원`}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      );
    case "매입 평가 점수":
      return (
        <TableContainer>
          <Table size="small" >
            <TableHead className="table-head" >
              <TableCell className="th tc-125">임대관리용이성</TableCell>
              <TableCell className="td no-padding">{
                [result.임대관리용이성]
                  .filter(v => typeof (v) === "number")
                  .reduce((a, b) => a + b, 0)
              }점</TableCell>
            </TableHead>
            <TableBody>
              <TableRow className="tr">
                <TableCell className="th">공사 시행 여건</TableCell>
                <TableCell className="td no-padding">
                  {result.lhScore &&
                    [result.접면도로, result.lhScore.landShapeAndInclineScore, result.lhScore.surroundingBuildingDistanceScore].filter((v) => typeof v === "number").reduce((a, b) => a + b, 0)}
                  점
                </TableCell>
              </TableRow>
              <TableRow className="tr">
                <TableCell className="th">생활 편의성</TableCell>
                <TableCell className="td no-padding">{[result.대중교통접근성, result.대학교접근성, result.생활편익시설].filter((v) => typeof v === "number").reduce((a, b) => a + b, 0)}점</TableCell>
              </TableRow>
              <TableRow className="tr">
                <TableCell className="th">향후 개발 가치</TableCell>
                <TableCell className="td no-padding">{result.용도지역}점</TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow className="tr">
                <TableCell className="th highlight">합계</TableCell>
                <TableCell className={`th no-padding ${점수 >= 60 ? "highlight" : "lightgrey"}`}>{`${점수}점 ${점수 >= 60 ? "(합격)" : "(불합격)"}`}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      );
    default:
      return <div></div>;
  }
};

export default ResultOverviewTable;
