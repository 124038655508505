import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { runProject } from '../modules/Project';
import { RootState } from "../../modules/Index";
import { FlexContainer, HorizontalEmptyDivide, VerticalEmptyDivide } from "../../styledComponents/layout";
import { SectionTitleText } from "../../styledComponents/text";
import { TextButton } from "../../styledComponents/buttons";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { modifyProject, setSupportPay } from "../../modules/Project";
import { project_use_district, building_type } from "../../model/data";
import { modifyProjectConfig, updateUseDistrict } from "../../modules/Project";
import { updateBuildingType } from "../../modules/ProjectDefault";
import { RowsKeyInputSelect } from "../common/input/RowsKeyInputSelect";
import { RowsKeyNumInputValue } from "../common/input/RowsKeyInputValue";

export const FeasibilityConfigSection = () => {
  const [detailConfig, setDetailConfig] = useState(false);
  const { projectData, loading, solverloading, errors, districtData } = useSelector(({ project, projectDefault, projectErrors, district }: RootState) => ({
    projectData: project.data,
    districtData: district.data,
    loading: projectDefault.loading,
    solverloading: project.solverloading,
    errors: projectErrors.defaultErrors,
  }));

  // const [lhInputErrors, setLHInputErrors] = useState({
  //   supportPay: undefined,
  //   msg: '호당 지원단가는 0.95억에서 2.5억입니다',
  // })
  const dispatch = useDispatch();
  const onChangeBuildingType = (event: any) => {
    dispatch(
      updateBuildingType({
        building_type: event.target.value,
      })
    );
  };
  const onChangeUseStrictType = (event: any) => {
    dispatch(
      updateUseDistrict({
        project_use_district: event.target.value,
      })
    );
  };

  // useEffect(() => {
  //   if(projectDefaultResult) {
  //     let result: any = [];
  //     Object.keys(projectDefaultResult).forEach(key => {
  //       let value = projectDefaultResult[key];
  //       if(value.가능여부 && key !== "아파트") result.push({value: key, label: key});
  //     });
  //     if(result.length === 0) result.push({ value: "다세대주택", label: "다세대주택"})
  //     setBuildingOptions(result);
  //   }
  // }, [projectDefaultResult]);

  const onChange = (event: any) => {
    // console.log(event);
    const dispatchData: any = {};
    // console.log('target Value',event.target.value )
    if (event.target.name === "housing_plan_type") {
      let temp = projectData.housing_plan_type;
      if (temp![0]!) temp![0]["area"] = Number(event.target.value);
      dispatchData[event.target.name] = temp;
    } else dispatchData[event.target.name] = Number(event.target.value);
    dispatch(modifyProjectConfig(dispatchData));
  };

  const onConstructPayChange = (event: any) => {
    const dispatchData: any = {};
    dispatchData[event.target.name] = Number(event.target.value);
    dispatch(modifyProject(dispatchData));
  };

  const onSupportPay = (event: any) => {
    dispatch(setSupportPay(Number(event.target.value)));
  };

  return (
    <FlexContainer direction="column" width="100%" height="auto">
      <HorizontalEmptyDivide />
      <FlexContainer width="90%" height="auto" justifyContent="space-between" alignItems="center">
        <SectionTitleText width="34%" align="left" padding="0px">
          사업성 검토 설정
        </SectionTitleText>
      </FlexContainer>

      <HorizontalEmptyDivide />
      <HorizontalEmptyDivide />
      <FlexContainer width="90%" height="60px" justifyContent="flex-start" alignItems="flex-start">
        {/* <RowsKeyInputSelect
          keyName="건물타입"
          value={projectData.building_type}
          width="48%"
          onChange={onChangeBuildingType}
          disabled={loading || solverloading}
          options={building_type}
          help="건물타입은 AI 수행시 우선 고려되는 건물 형태입니다"
        /> */}
        {/* 건물타입 선택 x 크기에 따라 자동 설정. */}
        <RowsKeyNumInputValue keyName="건물타입" value={projectData.building_type} inputName="building_type" width="48%" readonly help="건물타입은 AI 수행시 우선 고려되는 건물 형태입니다." />
        <VerticalEmptyDivide />
        <RowsKeyInputSelect
          keyName="용도지역"
          value={projectData.project_use_district}
          width="48%"
          onChange={onChangeUseStrictType}
          disabled={loading || solverloading}
          options={project_use_district}
          help="용도지역 선택에 따라 상세설정의 기본 적용값이 달라집니다."
        />
      </FlexContainer>
      <HorizontalEmptyDivide />
      <FlexContainer width="90%" height="60px" justifyContent="space-between" alignItems="flex-start">
        <RowsKeyNumInputValue
          keyName="추천 세대 면적"
          value={districtData ? Math.round(districtData!.full!.추천_연립다세대_월세! || 19) : 19}
          inputName="recommend_area"
          width="48%"
          disabled
          inputProps={{ min: 15 }}
          unit="m²"
          readonly
          help="계산 수행시 고려할 추천 세대면적입니다"
        />
        <VerticalEmptyDivide />
        <RowsKeyNumInputValue
          keyName="세대 면적"
          value={projectData.housing_plan_type![0].area}
          inputName="housing_plan_type"
          width="48%"
          onChange={onChange}
          disabled={loading || solverloading}
          helperText={`${(errors!.housing_plan_type && errors!.housing_plan_type!.msg!) || ""}`}
          error={errors!.housing_plan_type!}
          inputProps={{ min: 15 }}
          disableUnderline
          unit="m²"
          help="계산 수행시 고려할 세대면적입니다"
        />
      </FlexContainer>
      <HorizontalEmptyDivide />
      <FlexContainer width="90%" height="60px" justifyContent="space-between" alignItems="flex-start">
        <RowsKeyNumInputValue
          keyName="m² 당 공사비"
          // keyName="평당 공사비(만원)"
          value={projectData.평당공사비}
          inputName="평당공사비"
          width="48%"
          onChange={onConstructPayChange}
          disableUnderline
          unit="만원"
          help="사업성 검토 결과를 확인하실 때 입력 비용에 따라 검토 하실 수 있습니다"
        />
        <VerticalEmptyDivide />
        <RowsKeyNumInputValue
          keyName="호당 지원 단가"
          value={projectData.customer.supportPay}
          inputName="supportPay"
          width="48%"
          onChange={onSupportPay}
          disabled={loading || solverloading}
          // helperText={`${(lhInputErrors!.supportPay && lhInputErrors!.msg!) || ''}`}
          // error={lhInputErrors!.supportPay!}
          disableUnderline
          unit="억"
          inputProps={{ min: 0.95, step: 0.05, max: 2.5 }}
          help="사업성 검토 결과페이지에서 해당 지원단가를 활용할 수 있습니다."
        />
      </FlexContainer>
      <FlexContainer width="90%" height="auto" justifyContent="flex-end" alignItems="center">
        <TextButton onClick={() => setDetailConfig(!detailConfig)}>
          <span style={{ fontSize: "12px", border: "none", verticalAlign: "top" }}>{detailConfig ? "사업성 검토 상세설정 닫기" : "사업성 검토 상세설정 보기"} </span>
        </TextButton>
        <div onClick={() => setDetailConfig(!detailConfig)} style={{ width: "5%", padding: "0px" }}>
          {detailConfig ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </div>
      </FlexContainer>
      <HorizontalEmptyDivide />
      {detailConfig && (
        <FlexContainer direction="column" width="100%" height="auto">
          <HorizontalEmptyDivide />
          <FlexContainer width="90%" height="60px" justifyContent="space-between" alignItems="flex-start">
            <RowsKeyNumInputValue
              keyName="용적률"
              value={projectData.floor_area_ratio}
              inputName="floor_area_ratio"
              width="48%"
              // onChange={onChange}
              disabled
              helperText={`${(errors!.floor_area_ratio && errors!.floor_area_ratio!.msg!) || ""}`}
              error={errors!.floor_area_ratio!}
              inputProps={{ min: 0 }}
              readonly
              unit="%"
              help="AI 수행시 기준 용적률을 최대한 적용합니다."
            />
            <VerticalEmptyDivide />
            <RowsKeyNumInputValue
              keyName="건폐율"
              value={projectData.building_land_ratio}
              inputName="building_land_ratio"
              width="48%"
              // onChange={onChange}
              disabled
              helperText={`${(errors!.building_land_ratio && errors!.building_land_ratio!.msg!) || ""}`}
              error={errors!.building_land_ratio!}
              inputProps={{ min: 0 }}
              readonly
              unit="%"
              help="AI 수행시 기준 건폐율을 최대한 적용합니다"
            />
          </FlexContainer>
          <FlexContainer width="90%" height="60px" justifyContent="flex-start" alignItems="flex-start">
            <RowsKeyNumInputValue
              keyName="층고"
              value={projectData.floor_height}
              inputName="floor_height"
              width="48%"
              // onChange={onChange}
              disabled
              helperText={`${(errors!.floor_height && errors!.floor_height!.msg!) || ""}`}
              error={errors!.floor_height!}
              inputProps={{ min: 0, step: 0.1 }}
              readonly
              unit="m"
              help="세대의 바닥부터 천장까지의 높이를 말합니다"
            />
            <VerticalEmptyDivide />
            <RowsKeyNumInputValue
              keyName="최고층수"
              value={projectData.building_stories_max}
              inputName="building_stories_max"
              width="48%"
              // onChange={onChange}
              disabled
              helperText={`${(errors!.building_stories_max && errors!.building_stories_max!.msg!) || ""}`}
              error={errors!.building_stories_max!}
              inputProps={{ min: 0 }}
              readonly
              unit="층"
              help="최고층수를 나타냅니다"
            />
          </FlexContainer>
          <FlexContainer width="90%" height="60px" justifyContent="flex-start" alignItems="flex-start">
            <RowsKeyNumInputValue
              keyName="정북일조 9m초과 (h = 계수)"
              value={projectData.setback_regulation_from_north_more_9m}
              inputName="setback_regulation_from_north_more_9m"
              width="48%"
              // onChange={onChange}
              disabled
              helperText={`${(errors!.setback_regulation_from_north_more_9m && errors!.setback_regulation_from_north_more_9m!.msg!) || ""}`}
              error={errors!.setback_regulation_from_north_more_9m!}
              inputProps={{ min: 0, step: 0.1, max: 1 }}
              readonly
              unit="h"
              help="정북일조 9m 라인 초과에 대한 입력 계수입니다."
            />
            <VerticalEmptyDivide />
            <RowsKeyNumInputValue
              keyName="정북일조 9m이하"
              value={projectData.setback_regulation_from_north_less_9m}
              inputName="setback_regulation_from_north_less_9m"
              width="48%"
              // onChange={onChange}
              disabled
              helperText={`${(errors!.setback_regulation_from_north_less_9m && errors!.setback_regulation_from_north_less_9m!.msg!) || ""}`}
              error={errors!.setback_regulation_from_north_less_9m!}
              inputProps={{ min: 0, step: 0.1 }}
              readonly
              unit="m"
              help="정북일조 9m 라인 이하의 선을 말합니다"
            />
          </FlexContainer>
          <FlexContainer width="90%" height="60px" justifyContent="flex-start" alignItems="flex-start">
            <RowsKeyNumInputValue
              keyName="대지안의 공지-인접대지경계선"
              value={projectData.setback_site_boundary_multi_house}
              inputName="setback_site_boundary_multi_house"
              width="48%"
              // onChange={onChange}
              disabled
              helperText={`${(errors!.setback_site_boundary_multi_house && errors!.setback_site_boundary_multi_house!.msg!) || ""}`}
              error={errors!.setback_site_boundary_multi_house!}
              inputProps={{ min: 0 }}
              unit="m"
              // boxWidth="50%"
              readonly
              help="건축선으로부터 후퇴되는 인접대지 경계선의 거리"
            />
            <VerticalEmptyDivide />
            <RowsKeyNumInputValue
              keyName="대지안의 공지 - 건축선"
              value={projectData.setback_building_line_multi_house}
              inputName="setback_building_line_multi_house"
              width="48%"
              // onChange={onChange}
              disabled
              helperText={`${(errors!.setback_building_line_multi_house && errors!.setback_building_line_multi_house!.msg!) || ""}`}
              error={errors!.setback_building_line_multi_house!}
              inputProps={{ min: 0 }}
              readonly
              unit="m"
              help="대지로부터 후퇴되는 건축선의 이격거리"
            />
          </FlexContainer>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default FeasibilityConfigSection;
