/* eslint-disable */
import { Checkbox, createMuiTheme, IconButton, MenuItem, Modal, MuiThemeProvider, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "../css/ProjectList.scss";
import { Pagination } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import ProjectResult from "./result/ProjectResult";

import SearchForm from "./SearchForm";
// import { getProjectListAllinOneAPI } from '../api/SearchESApi';
import { Project } from "../model/Project";
import { ceil } from "lodash";
import { list_view_count } from "../model/data";
import { default as _ } from "lodash";
import { ArrowDownward, Delete, Done, ErrorOutline, HourglassEmpty } from "@material-ui/icons";
import { default as debounce } from "lodash";
import { useDispatch, useSelector, useStore } from "react-redux";
import { RootState } from "../modules/Index";
import projectList, { deleteProject, getProjectList } from "../modules/ProjectList";
import moment from "moment";

interface ResultType {
  project_id: number;
  사원번호?: number;
  사원타입?: number;
  기준면적당공사비?: number;
  주소목록?: string[];
  검토날짜?: string;
  접수날짜?: string;
  장애인여부?: boolean;
  건물타입?: string;
  deleted?: boolean;
  discarded?: boolean;
  status?: string;
  created_at?: string;
  loc_total_household?: number;
  user_project_id?: number;
  대지면적?: number;
  점수?: any;
  project_name: string;
}

interface ProjectListProps {
  onClose: () => void;
}

const ProjectStatusLabel = {
  CONFIG: "설정",
  WAITING: "분석중",
  RUNNING: "분석중",
  FINISHED: "완료",
  FAILED: "확인요",
};

const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#26A69A",
    },
  },
});

export default function ProjectList(props: ProjectListProps) {
  const dispatch = useDispatch();
  const [exceptionMsg, setExceptionMsg] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [pageConfig, setPageConfig] = useState({
    size: 10, // 몇개보기
    curPageNum: 1, // 현재 있는 페이지번호
  });

  const [sortType, setSortType] = useState("created_at");
  const [sortValue, setSortValue] = useState("DESC");

  const [curProject, SetCurProject] = useState({});
  const [isChecked, setIsChecked] = useState(Array.from({ length: pageConfig.size }, (v, i) => false)); // checkbox
  const [userInput, setUserInput] = useState("");
  const [searchOption, setSearchOption] = useState("project_name"); // 검색옵션
  const [didMount, setDidMount] = useState(false);

  const { loading, projectsList, projectTotal } = useSelector(({ projectList, selectedProject }: RootState) => ({
    loading: projectList.loading,
    projectsList: projectList.data,
    projectTotal: projectList.total,
  }));

  const onClickProject = (projectId: number) => {
    setShowResult(true);
    const selectedProject = projectsList.filter((project: any) => project.project_id === projectId);

    SetCurProject(selectedProject[0]);
  };

  // useEffect(() => {
  //   if (didMount){
  //     dispatch(getProjectList({query: "", size: pageConfig.size, from: (pageConfig.curPageNum - 1) * pageConfig.size, sort: [{ created_at: 'desc' }]}));
  //   }
  // }, [pageConfig]);

  /* Search */
  useEffect(() => {
    const type = searchOption;
    if (userInput) {
      if (searchOption === "project_name") {
        //const searchText = userInput;
        const searchText = userInput.toString().replace(/[()\*\[\]{}:=\'\"%\&\?\+]/g, "\\$&");
        dispatch(
          getProjectList({
            query: {
              project_name: `${searchText}`,
            },

            // query: `${type}.keyword: ${searchText} `,
            size: pageConfig.size,
            from: (pageConfig.curPageNum - 1) * pageConfig.size,
            sort: { [sortType]: sortValue },
          })
        );
      } else {
        //const searchText = `${userInput.toString().replace(/[()\*\[\]{}:=\'\"%\&\?\+]/g, '\\$&')}`;
        const searchText = userInput;
        const query: any = {};
        query[type] = type === "user_project_id" ? Number(searchText) : searchText;

        dispatch(
          getProjectList({
            query,
            size: pageConfig.size,
            from: (pageConfig.curPageNum - 1) * pageConfig.size,
            sort: { [sortType]: sortValue },
          })
        );
      }
      // console.log(type);
      // console.log(projectsList);
    } else {
      dispatch(getProjectList({ query: {}, size: pageConfig.size, from: (pageConfig.curPageNum - 1) * pageConfig.size, sort: { [sortType]: sortValue } }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInput, pageConfig]);

  useEffect(() => {
    setUserInput("");
  }, [searchOption]);

  const handleInput = (inputVal: string) => {
    setUserInput(inputVal);
  };

  const handleSearchOption = (option: string) => {
    setSearchOption(option);
  };

  const renderStatus = (status: string) => {
    switch (status) {
      // case "CONFIG":
      //   return ;
      case "WAITING":
        return <HourglassEmpty />;
      case "RUNNING":
        return <HourglassEmpty />;
      case "FINISHED":
        return <Done />;
      case "FAILED":
        return <ErrorOutline />;
    }
  };

  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (loading) setExceptionMsg("로딩중...");
    else if (!loading && projectsList.length === 0) {
      setExceptionMsg("현재 저장된 프로젝트가 없습니다.");
    }
  }, [loading]);

  /* Checkbox */
  useEffect(() => {
    setIsChecked(Array.from({ length: pageConfig.size }, (v, i) => false));
  }, [pageConfig.size]);

  const handleAllChecked = (e: any) => {
    setIsChecked(Array.from({ length: pageConfig.size }, (v, i) => e.target.checked));
  };

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = Number(e.target.value);
    const tmpRows = _.cloneDeep(isChecked);
    tmpRows[index] = e.target.checked;
    setIsChecked(tmpRows);
  };

  /* 프로젝트 삭제 */
  const handleDelete = () => {
    let projectIds: number[] = [];
    isChecked.forEach((v, i) => {
      if (v) {
        projectIds.push(projectsList[i].project_id);
      }
    });
    setIsChecked(Array.from({ length: pageConfig.size }, () => false));
    dispatch(
      deleteProject({
        projectIds,
        queryStr: {
          query: "",
          size: pageConfig.size,
          from: (pageConfig.curPageNum - 1) * pageConfig.size,
          sort: { created_at: "desc" },
        },
      })
    );
  };

  /* 프로젝트 정렬 */
  const handleSorting = (type: string) => {
    // 오름차순 <-> 내림차순
    if (sortValue === "ASC") {
      dispatch(getProjectList({ query: {}, size: pageConfig.size, from: (pageConfig.curPageNum - 1) * pageConfig.size, sort: { [type]: "DESC" } }));
      setSortValue("DESC");
      setSortType(type);
    } else {
      dispatch(getProjectList({ query: {}, size: pageConfig.size, from: (pageConfig.curPageNum - 1) * pageConfig.size, sort: { [type]: "ASC" } }));
      setSortValue("ASC");
      setSortType(type);
    }
  };

  if (showResult) {
    return (
      <Modal BackdropProps={{ invisible: true }} open={showResult} onClose={() => setShowResult(false)}>
        <ProjectResult onClose={props.onClose} onBack={() => setShowResult(false)} curProject={curProject} />
      </Modal>
    );
  } else {
    return (
      <div className="ProjectList">
        <header>
          <div className="title-wrap">
            <div className="title">사업성 검토 목록</div>
            <IconButton className="btn" onClick={() => props.onClose()}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className="head-wrap">
            <MuiThemeProvider theme={mainTheme}>
              <SearchForm handleChange={handleInput} searchOption={searchOption} handleSearchOption={handleSearchOption}></SearchForm>
              <TextField
                className="use-district select config-field view-num"
                select
                value={pageConfig.size}
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                onChange={(e) => setPageConfig({ ...pageConfig, size: Number(e.target.value), curPageNum: 1 })}
              >
                {list_view_count.map((option, i) => {
                  return (
                    <option value={option.value} key={i}>
                      {option.label}
                    </option>
                  );
                })}
              </TextField>
            </MuiThemeProvider>
          </div>
        </header>
        <div className="project-table">
          <TableContainer>
            <Table stickyHeader>
              <TableHead className="table-head .MuiTableCell-head">
                <TableRow className="tr">
                  <TableCell className="th" padding="checkbox" align="center" width="42">
                    <Checkbox onChange={handleAllChecked} value={3} color="primary" />
                  </TableCell>
                  <TableCell className="th" align="center" width="62" onClick={() => handleSorting("project_id")}>
                    <span className="table-head-text">검토번호</span>
                    <ArrowDownward className="arrow-downward-icon" />
                  </TableCell>
                  <TableCell className="th" align="center" width="70">
                    <span className="table-head-text">검토상태</span>
                  </TableCell>
                  {/* 450 */}
                  <TableCell className="th td-address" width="450" align="left">
                    <span className="table-head-text project-name">프로젝트 명</span>
                  </TableCell>
                  <TableCell className="th" align="center" width="100">
                    <span className="table-head-text">대지면적</span>
                  </TableCell>
                  <TableCell className="th" align="center" width="80">
                    <span className="table-head-text">달성세대수</span>
                  </TableCell>
                  <TableCell className="th" align="center" width="99" onClick={() => handleSorting("접수날짜")}>
                    <span className="table-head-text">접수 날짜</span>
                    <ArrowDownward className="arrow-downward-icon" />
                  </TableCell>
                  <TableCell className="th" align="center" width="99" onClick={() => handleSorting("검토날짜")}>
                    <span className="table-head-text">검토 날짜</span>
                    <ArrowDownward className="arrow-downward-icon" />
                  </TableCell>
                  <TableCell className="th" align="center" width="101" onClick={() => handleSorting("점수")}>
                    <span className="table-head-text">심사 결과</span>
                    <ArrowDownward className="arrow-downward-icon" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {projectsList.length > 0 &&
                  projectsList.map((r: ResultType, index: number) => {
                    return (
                      <TableRow className="tr" key={r.project_id}>
                        <TableCell className="td" padding="checkbox" align="center">
                          <Checkbox color="primary" checked={isChecked[index]} value={index} onChange={handleChecked} />
                        </TableCell>
                        <TableCell className="td" align="center" onClick={() => onClickProject(r.project_id!)}>
                          {r.user_project_id!}
                        </TableCell>
                        <TableCell className="td status" align="center" onClick={() => onClickProject(r.project_id!)}>
                          <div className="status-wrapper">
                            <span>{r.status && renderStatus(r.status)}</span>
                            <span className={`span ${r.status}`}> {r.status && _.get(ProjectStatusLabel, r.status)}</span>
                          </div>
                        </TableCell>
                        <TableCell className="td td-address" align="left" onClick={() => onClickProject(r.project_id!)}>
                          {r.project_name}
                        </TableCell>
                        <TableCell className="td td-number" align="center" onClick={() => onClickProject(r.project_id!)}>
                          {r.대지면적! && Number(r.대지면적).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}㎡
                        </TableCell>
                        <TableCell className="td td-number" align="center" onClick={() => onClickProject(r.project_id!)}>
                          {r.loc_total_household ? `${r.loc_total_household}세대` : "-"}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {r.접수날짜 ? moment(r.접수날짜).format("YYYY-MM-DD") : "-"}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {r.status === "FINISHED" || r.status === "FAILED" ? moment(r.검토날짜).format("YYYY-MM-DD") : "-"}
                        </TableCell>
                        <TableCell className={`td td-number ${r.점수 >= 60 ? "hightlight" : "lightgrey"}`} align="center" onClick={() => onClickProject(r.project_id!)}>
                          {(r.점수 && `${r.점수}점 (${r.점수 >= 60 ? "합격" : "불합격"})`) || "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {projectsList.length === 0 && <div className="projectListMsg">{exceptionMsg}</div>}
        </div>
        <MuiThemeProvider theme={mainTheme}>
          <div className="pagination">
            <Pagination
              color="primary"
              count={projectTotal && pageConfig.size ? ceil(Number(projectTotal) / pageConfig.size) : 1}
              page={pageConfig.curPageNum ? pageConfig.curPageNum : 1}
              defaultPage={1}
              siblingCount={4}
              boundaryCount={1}
              size="small"
              showFirstButton
              showLastButton
              onChange={(e: object, page: number) => {
                page && setPageConfig({ ...pageConfig, curPageNum: page });
              }}
            />
          </div>
        </MuiThemeProvider>
        <IconButton className="btn-delete" onClick={handleDelete}>
          <Delete />
        </IconButton>
      </div>
    );
  }
}
