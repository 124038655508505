import { createAction, handleActions } from "redux-actions";
import { takeLatest, call, put } from "redux-saga/effects";
import { NCoordinate } from "../MapManager";
import { findAddressAPI } from "../api/SearchAddressApi";

const SELECTED_ADDRESS_INFO = "SELECTED_ADDRESS_INFO";
const INITIALIZE_ADDRESS_INFO = "INITIALIZE_ADDRESS_INFO";
const SEARCH_ADDRESS = "SEARCH_ADDRESS";
const SEARCH_ADDRESS_RESULT = "SEARCH_ADDRESS_RESULT";
const SET_MAP_CENTER = "SET_MAP_CENTER";

export const selectedAddressInfo = createAction(SELECTED_ADDRESS_INFO);
export const searchAddress = createAction(SEARCH_ADDRESS);
export const searchAddressResult = createAction(SEARCH_ADDRESS_RESULT);
export const initializeAddressInfo = createAction(INITIALIZE_ADDRESS_INFO);
export const setMapCenter = createAction(SET_MAP_CENTER);

function* searchAddressSaga(action: any) {
  const find_address = action.payload.address;
  try {
    // console.log("action", action);
    const response = yield call(findAddressAPI, find_address);
    const data = response.data;
    const length = data.placeResult!.length + data.addressResult!.length || 0;
    if (data.success) {
      yield put(
        searchAddressResult({
          addressResult: data.addressResult,
          placeResult: data.placeResult,
          length: length,
        })
      );
    } else {
    }

    // success: true,
    // addressResult: rr.data.addresses,
    // placeResult: rr.data.places,
  } catch (e) {
    console.log('address error',e.msg);
  }
}

export function* watchAddressInfo() {
  yield takeLatest(searchAddress, searchAddressSaga);
}
interface AddressInfoData {
  roadAddress?: string;
  jibunAddress?: String;
  coord?: NCoordinate;
  isLand?: boolean;
}
export interface AddressResult {
  geom: string;
  pnu: string;
  address: string;
}
export interface PlaceResult {
  name: string;
  road_address: string;
  jibun_address: string;
  phone_number: string;
  x: string;
  y: string;
  address_type?: string;
  category_name?: string;
  category_group_name?: string;
}
export interface SearchResult {
  placeResults?: Array<PlaceResult>;
  addressResults?: Array<AddressResult>;
  datalength?: number;
}
type AddressInfoState = {
  data?: AddressInfoData;
  result: SearchResult;
  loading: boolean;
  mapCenter?: [number, number];
  error?: string;
};

const initialState: AddressInfoState = {
  data: {},
  result: {
    addressResults: [],
    placeResults: []
  },
  loading: false,
  mapCenter: undefined,
};

const addressInfo = handleActions<AddressInfoState, any>(
  {
    [SELECTED_ADDRESS_INFO]: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
    [SEARCH_ADDRESS]: (state, { payload }) => ({
      ...state,
      loading: true,
    }),
    [SEARCH_ADDRESS_RESULT]: (state, { payload }) => ({
      ...state,
      result: {
        addressResults: payload.addressResult,
        placeResults: payload.placeResult,
        datalength: payload.length,
      },
      // loading: false,
    }),
    [SET_MAP_CENTER]: (state, { payload }) => ({
      ...state,
      mapCenter: [payload.lng, payload.lat],
    }),
    [INITIALIZE_ADDRESS_INFO]: () => initialState,
  },
  initialState
);

export default addressInfo;
