import axios from 'axios';
import { DefaultState } from '../modules/PriceInfoConfig'
const APIURL = '/api';

export const getPriceInfoAPI = (pnu: string, params: DefaultState) => {
  
  return axios.post(`${APIURL}/GetPriceInfo`, {
    pnu,
    params,
    othersLimit: 100,       // default 100, TODO: 해당 parameter 편집 UI 추가해야함.
  });
}

export const getHouseHoldSalesInfoAPI = (pnu: string, params: DefaultState, exclusiveArea: number = 20) => {
  
  return axios.post(`${APIURL}/GetSellInfo`, {
    pnu,
    params,
    exclusiveArea,
    othersLimit: 100,       // default 100, TODO: 해당 parameter 편집 UI 추가해야함.
  });
}
export const getHouseHoldRentInfoAPI = (pnu: string, params: DefaultState, exclusiveArea: number = 20) => {

  return axios.post(`${APIURL}/GetRentInfo`, {
    pnu,
    params,
    exclusiveArea,
    othersLimit: 100,       // default 100, TODO: 해당 parameter 편집 UI 추가해야함.
  });
}