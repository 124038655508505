import * as THREE from "three";
import { DEMData } from "./DataManager";
import * as turf from "@turf/turf";
import { Line3, DoubleSide } from "three";
import * as jsts from "jsts";

const earcut = require("earcut");

export interface BuilidngBottomData {
  buildingZValue: number;
  bottomHeight: number;
}

interface BuildingMesh {
  wall: THREE.Geometry;
  window: THREE.Geometry;
  core: THREE.Geometry;
  piloti: THREE.Geometry;
  parkingLine: THREE.Vector2[];
  bottomLine: THREE.Geometry;
  roof: THREE.Geometry;
}

function makeRoofWithVertices(verts: THREE.Vector3[], inverse: boolean = false, makePlane: boolean = true, handrailHeight: number = 0.4, handrailWidth: number = -0.3) {
  const geometry = new THREE.Geometry();
  verts.push(verts[0]);

  geometry.vertices = verts;
  const triVerts: number[] = [];
  const geoVerts: number[][] = [];

  verts.forEach((v) => {
    triVerts.push(v.x);
    triVerts.push(v.z);

    geoVerts.push([v.x, v.z]);
  });
  geoVerts.push([verts[0].x, verts[0].z]);

  if (makePlane) {
    const triangles = earcut(triVerts);

    for (let i = 0; i < triangles.length; i += 3) {
      if (inverse) {
        geometry.faces.push(new THREE.Face3(triangles[i + 1], triangles[i + 2], triangles[i]));
        geometry.faceVertexUvs[0].push([
          new THREE.Vector2(verts[triangles[i + 1]].x, verts[triangles[i + 1]].z),
          new THREE.Vector2(verts[triangles[i + 2]].x, verts[triangles[i + 2]].z),
          new THREE.Vector2(verts[triangles[i + 0]].x, verts[triangles[i + 0]].z),
        ]);
      } else {
        geometry.faces.push(new THREE.Face3(triangles[i + 2], triangles[i + 1], triangles[i]));
        geometry.faceVertexUvs[0].push([
          new THREE.Vector2(verts[triangles[i + 2]].x, verts[triangles[i + 2]].z),
          new THREE.Vector2(verts[triangles[i + 1]].x, verts[triangles[i + 1]].z),
          new THREE.Vector2(verts[triangles[i + 0]].x, verts[triangles[i + 0]].z),
        ]);
      }
    }
  }

  //roof upper
  let geo = new THREE.Geometry();
  if (!inverse) {
    let geoJson = {
      type: "Polygon",
      coordinates: [geoVerts],
    };

    var reader = new jsts.io.GeoJSONReader();
    let jstsGeo = reader.read(geoJson);
    //@ts-ignore
    let bufferGeo = jstsGeo.buffer(handrailWidth);

    let coords = bufferGeo.getCoordinates();

    for (let i = 0; i < coords.length - 1; i++) {
      let v1 = new THREE.Vector3(coords[i].x, verts[0].y, coords[i].y);
      let v2 = new THREE.Vector3(coords[i + 1].x, verts[0].y, coords[i + 1].y);
      let v3 = new THREE.Vector3(coords[i].x, verts[0].y, coords[i].y).add(new THREE.Vector3(0, handrailHeight, 0));
      let v4 = new THREE.Vector3(coords[i + 1].x, verts[0].y, coords[i + 1].y).add(new THREE.Vector3(0, handrailHeight, 0));
      geo.merge(MakePlane(v3, v4, v1, v2));
    }

    for (let i = 0; i < verts.length - 1; i++) {
      let v1 = verts[i].clone();
      let v2 = verts[i + 1].clone();
      let v3 = verts[i].clone().add(new THREE.Vector3(0, handrailHeight, 0));
      let v4 = verts[i + 1].clone().add(new THREE.Vector3(0, handrailHeight, 0));

      geo.merge(MakePlane(v3, v4, v1, v2));
    }

    if (coords.length > 0) {
      let hollVerts: [number[][]] = [geoVerts];
      for (let i = 0; i < bufferGeo.getNumGeometries(); i++) {
        var writer = new jsts.io.GeoJSONWriter();
        let bufferGeoJson = writer.write(bufferGeo.getGeometryN(i));
        //@ts-ignore
        hollVerts.push(bufferGeoJson.coordinates[0]);
      }
      var data = earcut.flatten(hollVerts);
      var hollTriangles = earcut(data.vertices, data.holes, data.dimensions);
      let hollGeo = new THREE.Geometry();

      for (let i = 0; i < data.vertices.length; i += 2) {
        hollGeo.vertices.push(new THREE.Vector3(data.vertices[i], verts[0].y + handrailHeight, data.vertices[i + 1]));
      }

      for (let i = 0; i < hollTriangles.length; i += 3) {
        hollGeo.faces.push(new THREE.Face3(hollTriangles[i + 2], hollTriangles[i + 1], hollTriangles[i]));
        hollGeo.faceVertexUvs[0].push([
          new THREE.Vector2(hollGeo.vertices[hollTriangles[i + 2]].x + 0.1, hollGeo.vertices[hollTriangles[i + 2]].z + 0.1),
          new THREE.Vector2(hollGeo.vertices[hollTriangles[i + 1]].x + 0.1, hollGeo.vertices[hollTriangles[i + 1]].z + 0.1),
          new THREE.Vector2(hollGeo.vertices[hollTriangles[i + 0]].x + 0.1, hollGeo.vertices[hollTriangles[i + 0]].z + 0.1),
        ]);
      }
      geometry.merge(hollGeo);
    }
  }

  geometry.merge(geo);
  geometry.computeFaceNormals();
  return geometry;
}

function MakeWall(start: THREE.Vector2, end: THREE.Vector2, roomHeight: number, wallHeight: number, resetUV: boolean) {
  let verts = [];
  verts.push(new THREE.Vector3(start.x, roomHeight, start.y));
  verts.push(new THREE.Vector3(start.x, roomHeight + wallHeight, start.y));

  verts.push(new THREE.Vector3(end.x, roomHeight, end.y));
  verts.push(new THREE.Vector3(end.x, roomHeight + wallHeight, end.y));
  let widthUV = resetUV ? verts[0].distanceTo(verts[2]) * 1.5 : 1;
  let heightUV = resetUV ? wallHeight * 1.5 : 1;

  const geometry = new THREE.Geometry();
  geometry.vertices = verts;

  for (let verNum = 0, faceNum = 0; verNum < geometry.vertices.length - 3; faceNum++, verNum += 2) {
    geometry.faces.push(new THREE.Face3(verNum, verNum + 1, verNum + 2));
    geometry.faces.push(new THREE.Face3(verNum + 1, verNum + 3, verNum + 2));

    geometry.faceVertexUvs[0].push([new THREE.Vector2(0, 0), new THREE.Vector2(0, heightUV), new THREE.Vector2(widthUV, 0)]);
    geometry.faceVertexUvs[0].push([new THREE.Vector2(0, heightUV), new THREE.Vector2(widthUV, heightUV), new THREE.Vector2(widthUV, 0)]);
  }

  geometry.computeFaceNormals();
  return geometry;
}

function MakePlane(v1: THREE.Vector3, v2: THREE.Vector3, v3: THREE.Vector3, v4: THREE.Vector3) {
  //v2--v4
  // |   |
  //v1--v3

  let geo = new THREE.Geometry();
  geo.vertices = [v1, v2, v3, v4];
  geo.faces.push(new THREE.Face3(0, 1, 2));
  geo.faces.push(new THREE.Face3(1, 3, 2));

  let widthUV = v1.distanceTo(v3) * 1.5;
  let heightUV = v1.distanceTo(v2) * 1.5;

  geo.faceVertexUvs[0].push([new THREE.Vector2(0, 0), new THREE.Vector2(0, heightUV), new THREE.Vector2(widthUV, 0)]);
  geo.faceVertexUvs[0].push([new THREE.Vector2(0, heightUV), new THREE.Vector2(widthUV, heightUV), new THREE.Vector2(widthUV, 0)]);

  geo.computeFaceNormals();
  return geo;
}

function MakeWindow(windows: any[], nodes: any, winGeo: THREE.Geometry, wallGeo: THREE.Geometry, roomHeight: number, wallHeight: number) {
  if (windows.length > 0) {
    let windowLength = 0;
    windows.forEach((w) => {
      windowLength += new THREE.Vector2(nodes[w.start].x, nodes[w.start].y).distanceTo(nodes[w.end] as THREE.Vector2);
    });

    let windowOffset = windowLength > 2.6 ? 0.2 : 0.6;
    windows.forEach((w) => {
      let v1 = new THREE.Vector3(nodes[w.start].x, roomHeight + windowOffset, nodes[w.start].y);
      let v2 = new THREE.Vector3(nodes[w.end].x, roomHeight + windowOffset, nodes[w.end].y);

      let windowDir = v1.clone().sub(v2);
      let verWD = windowDir
        .clone()
        .applyAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2)
        .normalize()
        .multiplyScalar(0.05);

      let v3 = v1.clone().add(verWD);
      let v4 = v2.clone().add(verWD);

      wallGeo.merge(MakeWall(nodes[w.start], nodes[w.end], roomHeight, windowOffset, true));
      wallGeo.merge(MakeWall(nodes[w.start], nodes[w.end], roomHeight + wallHeight - windowOffset, windowOffset, true));

      //bottom
      wallGeo.merge(
        MakePlane(
          new THREE.Vector3(v1.x, roomHeight + windowOffset, v1.z),
          new THREE.Vector3(v3.x, roomHeight + windowOffset, v3.z),
          new THREE.Vector3(v2.x, roomHeight + windowOffset, v2.z),
          new THREE.Vector3(v4.x, roomHeight + windowOffset, v4.z)
        )
      );
      //top
      wallGeo.merge(
        MakePlane(
          new THREE.Vector3(v2.x, roomHeight + wallHeight - windowOffset, v2.z),
          new THREE.Vector3(v4.x, roomHeight + wallHeight - windowOffset, v4.z),
          new THREE.Vector3(v1.x, roomHeight + wallHeight - windowOffset, v1.z),
          new THREE.Vector3(v3.x, roomHeight + wallHeight - windowOffset, v3.z)
        )
      );
      //left
      wallGeo.merge(
        MakePlane(
          new THREE.Vector3(v1.x, roomHeight + wallHeight - windowOffset, v1.z),
          new THREE.Vector3(v3.x, roomHeight + wallHeight - windowOffset, v3.z),
          new THREE.Vector3(v1.x, roomHeight + windowOffset, v1.z),
          new THREE.Vector3(v3.x, roomHeight + windowOffset, v3.z)
        )
      );
      //right
      wallGeo.merge(
        MakePlane(
          new THREE.Vector3(v2.x, roomHeight + windowOffset, v2.z),
          new THREE.Vector3(v4.x, roomHeight + windowOffset, v4.z),
          new THREE.Vector3(v2.x, roomHeight + wallHeight - windowOffset, v2.z),
          new THREE.Vector3(v4.x, roomHeight + wallHeight - windowOffset, v4.z)
        )
      );

      winGeo.merge(MakeWall(new THREE.Vector2(v3.x, v3.z), new THREE.Vector2(v4.x, v4.z), roomHeight + windowOffset, wallHeight - windowOffset * 2, false));
    });
  }
}

function MakeLevelLine(start: THREE.Vector2, end: THREE.Vector2, height: number) {
  let lineGeo = new THREE.Geometry();

  lineGeo.vertices.push(new THREE.Vector3(start.x, height, start.y));
  lineGeo.vertices.push(new THREE.Vector3(end.x, height, end.y));

  return lineGeo;
}

function makeHouse(buildingMesh: BuildingMesh, houseType: string, type: string, lineInfo: any, nodes: any, roomHeight: number, wallHeight: number, makeRoof: boolean, makeBottom: boolean) {
  let verts: THREE.Vector3[] = [];
  let groundVerts: THREE.Vector3[] = [];
  let windows: any[] = [];
  if (houseType === "PCT_BORDER") {
    if (type === "PT_OUTLINE")
      lineInfo.forEach((l: any) => {
        verts.push(new THREE.Vector3(nodes[l.start].x, roomHeight + wallHeight, nodes[l.start].y));
        groundVerts.push(new THREE.Vector3(nodes[l.start].x, roomHeight - 10, nodes[l.start].y));

        if (makeBottom) {
          buildingMesh.roof.merge(MakeWall(nodes[l.start], nodes[l.end], roomHeight - 10, 10, true));
        }
      });

    if (makeRoof && verts.length > 0) {
      buildingMesh.roof.merge(makeRoofWithVertices(verts, false, true));
    }
  } else if (houseType === "PCT_PARKING") {
    lineInfo.forEach((l: any) => {
      verts.push(new THREE.Vector3(nodes[l.start].x, roomHeight, nodes[l.start].y));
      groundVerts.push(new THREE.Vector3(nodes[l.start].x, roomHeight, nodes[l.start].y));
    });

    if (verts.length > 0) {
      buildingMesh.roof.merge(makeRoofWithVertices(verts, false, false, 0.01, -0.2));
    }
  } else {
    lineInfo.forEach((l: any) => {
      switch (l.lineType) {
        case "LT_COREOUTERWALL":
          buildingMesh.core.merge(MakeWall(nodes[l.start], nodes[l.end], roomHeight, wallHeight, true));
          buildingMesh.bottomLine.merge(MakeLevelLine(nodes[l.start], nodes[l.end], roomHeight));
          MakeWindow(windows, nodes, buildingMesh.window, buildingMesh.core, roomHeight, wallHeight);
          windows = [];
          break;

        case "LT_OUTERWALL":
        case "LT_INNERWALL":
        case "LT_ENTRANCE":
        case "LT_SIDEWALL":
        case "LT_DOOR":
        case "LT_SLIDE":
        case "LT_GATE":
        case "LT_COREINNERWALL":
          if (houseType === "FC_CORE") {
            buildingMesh.core.merge(MakeWall(nodes[l.start], nodes[l.end], roomHeight, wallHeight, true));
          } else {
            buildingMesh.wall.merge(MakeWall(nodes[l.start], nodes[l.end], roomHeight, wallHeight, true));
          }

          buildingMesh.bottomLine.merge(MakeLevelLine(nodes[l.start], nodes[l.end], roomHeight));
          MakeWindow(windows, nodes, buildingMesh.window, buildingMesh.wall, roomHeight, wallHeight);
          windows = [];
          break;

        case "LT_OUTERWINDOW":
        case "LT_LIGHTWINDOW":
        case "LT_INNERWINDOW":
        case "LT_WINDOW":
        case "LT_VIEWWINDOW":
        case "LT_LVWINDOW":
          windows.push(l);
          buildingMesh.bottomLine.merge(MakeLevelLine(nodes[l.start], nodes[l.end], roomHeight));
          break;

        default:
          console.log(l.lineType);
          break;
      }
      if (l.lineType !== "LT_BORDER") {
        verts.push(new THREE.Vector3(nodes[l.start].x, roomHeight + wallHeight, nodes[l.start].y));
        groundVerts.push(new THREE.Vector3(nodes[l.start].x, roomHeight, nodes[l.start].y));
      }
    });

    // if (withRoof && verts.length > 0)
    //   buildingMesh.roof.merge(makeRoofWithVertices(verts));
    MakeWindow(windows, nodes, buildingMesh.window, buildingMesh.wall, roomHeight, wallHeight);
    windows = [];
  }
}

function makeParkingLineLevel(buildingMesh: BuildingMesh, category: string, type: string, lineInfo: any, nodes: any, wallHeight: number) {
  lineInfo.forEach((l: any) => {
    if (category === "PCT_PILOTI" || category === "PCT_CORE") {
      buildingMesh.wall.merge(MakeWall(nodes[l.start], nodes[l.end], 0, wallHeight, false));
    }
  });
}

function makeHouseBottom(buildingMesh: BuildingMesh, lineInfo: any, nodes: any, roomHeight: number, wallHeight: number) {
  lineInfo.forEach((l: any) => {
    if (l.lineType !== "LT_BORDER") {
      buildingMesh.wall.merge(MakeWall(nodes[l.start], nodes[l.end], roomHeight, wallHeight, true));
    }
  });
}

export function MakeBuildingMesh(buildingData: any, makeBottom: boolean) {
  console.log(buildingData);

  let buildingMesh: BuildingMesh = {
    core: new THREE.Geometry(),
    piloti: new THREE.Geometry(),
    wall: new THREE.Geometry(),
    window: new THREE.Geometry(),
    parkingLine: [],
    bottomLine: new THREE.Geometry(),
    roof: new THREE.Geometry(),
  };

  let heights = buildingData.floorHeight;
  buildingData.outlines.forEach((outline: any) => {
    let nodes = outline.node.data;
    let category = outline.category;
    let floor: string[] = [];

    for (let i = 0; i < buildingData.stories; i++) {
      let withRoof = false;
      if (i === buildingData.stories - 1) {
        withRoof = true;
      }
      makeBottom = makeBottom && i == 0;

      makeHouse(buildingMesh, outline.category, outline.type, outline.lineInfo, nodes, heights * i, heights, withRoof, makeBottom);
    }

    // if (buildingData.type === '지상1층') {
    //   makeParkingLineLevel(buildingMesh, outline.category, outline.type, outline.lineInfo, nodes, heights);
    // }
    // // else if (buildingData.type === 'Piloti') {
    // //   makeHouse(buildingMesh, 'FC_CORE', outline.lineInfo, nodes, -2.8 * 2, 2.8 * 3, false);
    // // }
    // else
    // {
    //   let roomHeight = 0;
    //   if (buildingData.name.search("ParkingLot") > 0) {
    //     roomHeight = -2.8;
    //     heights = 5.6
    //   }

    //   for (let i = 0; i < floor.length; i++)
    //   {
    //     switch (floor[i]) {
    //       case 'FC_CORE':
    //       case 'FC_HOUSE':
    //         let withRoof = false;
    //         if (i === floor.length - 1) {
    //           withRoof = true;
    //         }
    //         else if (i !== floor.length - 1 && floor[i + 1] === 'FC_VOID') {
    //           withRoof = true;
    //         }

    //         makeHouse(buildingMesh, outline.category, outline.lineInfo, nodes, roomHeight, heights, withRoof);
    //         break;

    //       case 'FC_VOID':
    //         break;
    //       case 'FC_PILOTI':
    //         console.log('piloti');
    //         break;
    //       default:
    //         break;
    //     }
    //     roomHeight += heights;
    //   }
    // }
  });

  let wallTexture = loadTexture("./img/Plaster_002_COLOR-white.jpg");
  let wallNormalTex = loadTexture("./img/Plaster_002_NORM.jpg");
  let coreTexture = loadTexture("./img/Plaster_002_COLOR-gray.jpg");

  let bumpScale = 0.2;
  let coreMaterial = new THREE.MeshStandardMaterial({ metalness: 0.1, roughness: 0.1, side: DoubleSide }); //999999
  coreMaterial.map = coreTexture;
  coreMaterial.normalMap = wallNormalTex;

  let wallMaterial = new THREE.MeshStandardMaterial({ metalness: 0.1, roughness: 0.1, side: DoubleSide }); //#eeeeee
  wallMaterial.map = wallTexture;
  wallMaterial.normalMap = wallNormalTex;

  let windowMaterial = new THREE.MeshStandardMaterial({ color: "#ffffff", side: DoubleSide }); //00FFFF
  windowMaterial.map = new THREE.TextureLoader().load("./img/window_edit.jpg");

  let roofMaterial = new THREE.MeshStandardMaterial({ color: "#999999" }); //999999

  let bottomLineMaterial = new THREE.LineBasicMaterial({ color: 0x999999 });
  let building = new THREE.Group();

  building.add(new THREE.Mesh(buildingMesh.core, coreMaterial));
  building.add(new THREE.Mesh(buildingMesh.wall, wallMaterial));
  building.add(new THREE.Mesh(buildingMesh.window, windowMaterial));
  building.add(new THREE.Mesh(buildingMesh.roof, wallMaterial));

  building.scale.set(0.1, 0.1, -0.1);
  building.children.forEach((c) => {
    c.castShadow = true;
    c.receiveShadow = true;
  });

  return building;
}

export function MakeBuildingMesh_old(buildingData: any, makeBottom: boolean) {
  console.log(buildingData);

  let buildingMesh: BuildingMesh = {
    core: new THREE.Geometry(),
    piloti: new THREE.Geometry(),
    wall: new THREE.Geometry(),
    window: new THREE.Geometry(),
    parkingLine: [],
    bottomLine: new THREE.Geometry(),
    roof: new THREE.Geometry(),
  };

  let heights = buildingData.floorHeight;
  buildingData.outlines.forEach((outline: any) => {
    let nodes = outline.node.data;
    let category = outline.category;
    let floor: string[] = [];

    for (let i = 0; i < buildingData.stories; i++) {
      floor.push("FC_HOUSE");
    }

    if (makeBottom) {
      makeHouseBottom(buildingMesh, outline.lineInfo, nodes, -2.8, 2.8);
    }

    if (buildingData.type === "지상1층") {
      makeParkingLineLevel(buildingMesh, outline.category, outline.type, outline.lineInfo, nodes, heights);
    }
    // else if (buildingData.type === 'Piloti') {
    //   makeHouse(buildingMesh, 'FC_CORE', outline.lineInfo, nodes, -2.8 * 2, 2.8 * 3, false);
    // }
    else {
      let roomHeight = 0;
      if (buildingData.name.search("ParkingLot") > 0) {
        roomHeight = -2.8;
        heights = 5.6;
      }

      for (let i = 0; i < floor.length; i++) {
        switch (floor[i]) {
          case "FC_CORE":
          case "FC_HOUSE":
            let withRoof = false;
            if (i === floor.length - 1) {
              withRoof = true;
            } else if (i !== floor.length - 1 && floor[i + 1] === "FC_VOID") {
              withRoof = true;
            }

            makeHouse(buildingMesh, outline.category, outline.type, outline.lineInfo, nodes, roomHeight, heights, withRoof, false);
            break;

          case "FC_VOID":
            break;
          case "FC_PILOTI":
            console.log("piloti");
            break;
          default:
            break;
        }
        roomHeight += heights;
      }
    }
  });

  let wallTexture = loadTexture("./img/Plaster_002_COLOR-white.jpg");
  let wallNormalTex = loadTexture("./img/Plaster_002_NORM.jpg");
  let coreTexture = loadTexture("./img/Plaster_002_COLOR-gray.jpg");

  let bumpScale = 0.2;
  let coreMaterial = new THREE.MeshStandardMaterial({ metalness: 0.1, roughness: 0.1, side: DoubleSide }); //999999
  coreMaterial.map = coreTexture;
  coreMaterial.normalMap = wallNormalTex;

  let wallMaterial = new THREE.MeshStandardMaterial({ metalness: 0.1, roughness: 0.1, side: DoubleSide }); //#eeeeee
  wallMaterial.map = wallTexture;
  wallMaterial.normalMap = wallNormalTex;

  let windowMaterial = new THREE.MeshStandardMaterial({ color: "#ffffff", side: DoubleSide }); //00FFFF
  windowMaterial.map = new THREE.TextureLoader().load("./img/window_edit.jpg");

  let roofMaterial = new THREE.MeshStandardMaterial({ color: "#999999" }); //999999

  let bottomLineMaterial = new THREE.LineBasicMaterial({ color: 0x999999 });
  let building = new THREE.Group();

  building.add(new THREE.Mesh(buildingMesh.core, coreMaterial));
  building.add(new THREE.Mesh(buildingMesh.wall, wallMaterial));
  building.add(new THREE.Mesh(buildingMesh.window, windowMaterial));
  building.add(new THREE.Mesh(buildingMesh.roof, wallMaterial));

  building.scale.set(0.1, 0.1, -0.1);
  building.children.forEach((c) => {
    c.castShadow = true;
    c.receiveShadow = true;
  });

  return building;
}

export function MakeSubBuildingGeometry(buildingGeo: number[][], offset: THREE.Vector2, height: number, demHeight: number) {
  let verts: THREE.Vector2[] = [];
  let roofVerts: THREE.Vector3[] = [];
  let groundVerts: THREE.Vector3[] = [];
  let wallGeometry = new THREE.Geometry();
  let buildingHeight = demHeight;

  buildingGeo.forEach((ver) => {
    verts.push(new THREE.Vector2(ver[0] - offset.x, ver[1] - offset.y));
    roofVerts.push(new THREE.Vector3(ver[0] - offset.x, height + buildingHeight, ver[1] - offset.y));
    groundVerts.push(new THREE.Vector3(ver[0] - offset.x, buildingHeight, ver[1] - offset.y));
  });

  if (!turf.booleanClockwise(turf.lineString(buildingGeo))) {
    verts = verts.reverse();
  }

  for (let i = 0; i < verts.length - 1; i++) {
    wallGeometry.merge(MakeWall(verts[i + 1], verts[i], buildingHeight, height, true));
  }
  wallGeometry.merge(makeRoofWithVertices(roofVerts));
  wallGeometry.merge(makeRoofWithVertices(groundVerts, true));
  return wallGeometry;
}

export function MakeSubBuildingMesh(buildingGeo: number[][], material: THREE.Material[], offset: THREE.Vector2, height: number, demHeight: number, isIn1M: boolean) {
  let builidng = new THREE.Group();
  let verts: THREE.Vector2[] = [];
  let roofVerts: THREE.Vector3[] = [];
  let groundVerts: THREE.Vector3[] = [];
  let wallGeometry = new THREE.Geometry();
  let roofGeometry = new THREE.Geometry();
  let buildingHeight = demHeight;

  buildingGeo.forEach((ver) => {
    verts.push(new THREE.Vector2(ver[0] - offset.x, ver[1] - offset.y));
    roofVerts.push(new THREE.Vector3(ver[0] - offset.x, height + buildingHeight, ver[1] - offset.y));
    groundVerts.push(new THREE.Vector3(ver[0] - offset.x, buildingHeight, ver[1] - offset.y));
  });

  if (!turf.booleanClockwise(turf.lineString(buildingGeo))) {
    verts = verts.reverse();
  }

  for (let i = 0; i < verts.length - 1; i++) {
    wallGeometry.merge(MakeWall(verts[i + 1], verts[i], buildingHeight, height, true));
  }
  roofGeometry.merge(makeRoofWithVertices(roofVerts));
  roofGeometry.merge(makeRoofWithVertices(groundVerts, true));
  wallGeometry.merge(roofGeometry);

  let mesh = new THREE.Mesh(wallGeometry, material[0]);
  mesh.castShadow = isIn1M ? false : true;
  mesh.receiveShadow = isIn1M ? false : true;

  let backMesh = new THREE.Mesh(wallGeometry, material[1]);
  backMesh.castShadow = isIn1M ? false : true;
  backMesh.receiveShadow = isIn1M ? false : true;

  builidng.add(mesh);
  builidng.add(backMesh);

  return builidng;
}

export function loadTexture(url: string) {
  let texture = new THREE.TextureLoader().load(url);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  return texture;
}
