import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { floor_summary_office } from "../../model/result";
// import FloorSummary from './FloorSummary';
// import HouseholdsSummary from './HouseholdsSummary';
import OfficeHouseholdsSummaryTable from "./OfficeHouseholdsSummaryTable";
import ProjectResultTitle from "./ProjectResultTitle";
// import { ResultPageProps } from './ResultInfo';
// import { default as _ } from 'lodash';
import { useSelector } from "react-redux";
import { RootState } from "../../modules/Index";

interface TableDataType {
  areaInfoSum: areaInfoSumType;
  householdAreaTables: null | Array<object>;
  households: number;
  name: string;
  [index: string]: any;
}
interface areaInfoSumType {
  commonArea: number;
  comstructionArea: number;
  exclusiveArea: number;
  salesArea: number;
  serviceArea: number;
  storeArea: number;
}

const OfficeFloorHouseholdSummaryWrapper = (props: any) => {
  const { resultInfoData } = useSelector(({ selectedProject }: RootState) => ({
    resultInfoData: selectedProject.result,
  }));
  // const resultInfoData = useContext(ProjectResultContext).result;
  const tableData = [...resultInfoData.householdAreaTable] || [];

  const calSum = (item: any) => {
    let result = 0;
    if (props.unitState === "㎡") {
      result = tableData
        .map((data: any) => {
          return item.subKey !== undefined ? Number(data[item.key][item.subKey].toFixed(2)) : data[item.key];
        })
        .reduce((a: number, b: number) => a + b, 0);
    } else {
      result = tableData
        .map((data: any) => {
          return item.subKey !== undefined ? Number((Number(data[item.key][item.subKey].toFixed(2)) / (1 / 0.3025)).toFixed(2)) : data[item.key];
        })
        .reduce((a: number, b: number) => Number(a + b));
    }
    if (item.key === "areaInfoSum") {
      return Number(result) > 0 ? `${result.toFixed(2)}${props.unitState}` : "-";
    }

    return result + "세대";
  };
  const householdData = (resultInfoData.householdAreaTable || []).filter((item: any) => item.households !== 0) || [];
  const houseTypeTable = (resultInfoData.houseTypeTable || []).filter((item: any) => item.세대수 !== 0) || [];

  // console.log("resultInfoData.householdAreaTable", resultInfoData.householdAreaTable);
  // console.log("householdData", householdData);
  // console.log("houseTypeTable", houseTypeTable);

  const convertArea = (v: number): string => {
    // console.log(v, typeof(v));
    if (v.toString() === "-") {
      return "-";
    }

    if (props.unitState === "㎡") {
      return Number(v).toFixed(2);
    }

    return Number(Number(v) / (1 / 0.3025)).toFixed(2);
  };

  const [pageIndex, setPageIndex] = useState([] as Array<Array<number>>);
  //const pageNumber = useRef([]);
  useEffect(() => {
    let pageNumber = [];

    pageNumber = houseTypeTable.map((v: any) => v.세대수 + 2);

    //  console.log(householdData, pageNumber);
    // pageNumber.current = houseTypeTable.map((v: any) => v.세대수 + 2);

    let page_index: Array<Array<number>> = [...pageIndex]; // _.cloneDeep(pageIndex);

    let tempNumber = 0;
    let startIndex = 0;
    let endIndex = 0;

    if (tableData.length! + 2 + pageNumber[0] < 14) {
      // 5번, 6번 결과 연결되는 부분
      tempNumber = 1;
      startIndex = 1;
      endIndex = 1;
    }

    for (let i = startIndex === 0 ? 0 : 1; i < pageNumber.length; i++) {
      tempNumber += pageNumber[i];
      if (tempNumber > 13) {
        // 6번 결과 페이지 분할
        tempNumber = pageNumber[i];
        endIndex = i - 1;
        page_index.push([startIndex, i - 1]);
        startIndex = endIndex + 1;
        //    setPageIndex(page_index);
      }
    }
    page_index.push([startIndex, pageNumber.length]);
    setPageIndex(page_index);
    console.log("---pageIndex---", page_index);
  }, []);

  return (
    <>
      <section className="FloorSummary">
        <ProjectResultTitle></ProjectResultTitle>
        <div className="sub-title">5. 층별개요</div>
        <div>
          <TableContainer>
            <Table size="small">
              <TableHead className="table-head">
                <TableRow className="tr">
                  {floor_summary_office.map((data) => (
                    <TableCell key={`5-${data.label}`} className="th" align="left" width="125">
                      {data.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {tableData.reverse().map((data: TableDataType) => (
                  <TableRow className="tr" key={`5-${data.name}_${data.households * Math.random()}`}>
                    {floor_summary_office.map((item, index) => (
                      <TableCell key={item.key + index} align="left" className="td" width="125">
                        {item.subKey ? item.value(data[item.key][item.subKey]) && convertArea(item.value(data[item.key][item.subKey])) : item.value(data[item.key])}
                        {item.subKey ? item.value(data[item.key][item.subKey]) && item.value(data[item.key][item.subKey]) > 0 && props.unitState : item.value(data[item.key]) > 0 && item.unit}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow className="tr">
                  <TableCell className="th highlight" align="left">
                    합계
                  </TableCell>
                  {floor_summary_office.map(
                    (item: object, index: number) =>
                      (index > 1 && (
                        <TableCell width="125" key={`${index}합계`} className="th highlight" align="left">
                          {calSum(item)}
                        </TableCell>
                      )) ||
                      (index === 1 && <TableCell></TableCell>)
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        {pageIndex && pageIndex.length > 0 && pageIndex[0][0] === 1 && (
          <div>
            <div className="sub-title connected-page-sub-title">{`6. 단위 세대 면적표`}</div>
            <OfficeHouseholdsSummaryTable props={houseTypeTable} unitState={props.unitState} key={houseTypeTable.세대수 + Math.random()} />
          </div>
        )}
      </section>

      <section className="HouseholdSummary">
        <ProjectResultTitle></ProjectResultTitle>
        <div>
          <div className={`sub-title margin-40`}>{`6. 단위 세대 면적표`}</div>
          <OfficeHouseholdsSummaryTable props={houseTypeTable} unitState={props.unitState} key={houseTypeTable.세대타입 + Math.random()} />
        </div>
      </section>
    </>
  );
};

export default OfficeFloorHouseholdSummaryWrapper;
