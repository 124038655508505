import { Button } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../modules/Index';
import moment from 'moment';
import '../../css/FailedResult.scss';
import ProjectResultTitle from './ProjectResultTitle';

const FailedResult = () => {

  const { projectInfoData, failReason } = useSelector(
    ({ selectedProject }: RootState) => ({
      projectInfoData: selectedProject.project,
      failReason: selectedProject.failreason,
    }),
  );

  const componentRef = useRef(null);
  const [unitState, setUnitState] = useState('㎡');

  const convertUnit = () => { // 단위 변환
    if (unitState === "㎡") {
      setUnitState('평');
    } else {
      setUnitState('㎡');
    }
  }

  const handleArea = (key: any) => { // 대지면적 평 <-> ㎡ 계산
    if (unitState === "평") {
      return projectInfoData[key] && Number((projectInfoData[key] / (1 / 0.3025))).toFixed(2) + unitState;
    }
    return projectInfoData[key] ? `${Number(projectInfoData[key]).toFixed(2)}${unitState}` : '';
  }

  return (
    <>
      <section ref={componentRef} className="FailedResult">
        <ProjectResultTitle></ProjectResultTitle>
        <ReactToPrint
          trigger={() => <Button className="btn btn-primary btn-outline print-btn not-print">인쇄</Button>}
          content={() => componentRef.current
          }
          documentTitle={projectInfoData.주소목록 && projectInfoData.주소목록.length === 1 ? `${projectInfoData.주소목록[0]}` : `${projectInfoData.주소목록[0]}외 ${projectInfoData.주소목록.length - 1}필지`}
        />
        <div className="unit-btns not-print">
          <button className={`btn btn-primary btn-outline btn-unit ${unitState === "㎡" ? 'active' : ''} not-print`} onClick={convertUnit}>㎡</button>
          <button className={`btn btn-primary btn-outline btn-unit ${unitState === "평" ? 'active' : ''} not-print`} onClick={convertUnit}>평</button>
        </div>
        <h3 className="title">사업성 검토에 문제가 발생했습니다.</h3>
        <p className="causing">{(failReason && failReason.message) || '솔버가 예상하지 못한 이유로 실패하였습니다.'}</p>

        <article>
          <p className="table-title">궁금한 사항이 있으시면 아래 연락처로 연락주세요. 빠르게 처리해드리겠습니다.</p>
          <TableContainer>
            <Table size="small">
              <TableRow className="tr table-head">
                <TableCell className="th table-key tc-125">Email</TableCell>
                <TableCell className="td no-padding">1011@1011.co.kr</TableCell>
              </TableRow>
              <TableRow className="tr">
                <TableCell className="th">Tel</TableCell>
                <TableCell className="td no-padding">02-375-1011</TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </article>

        <article>
          <p className="table-title">검토한 내용</p>
          <TableContainer>
            <Table size="small">
              <TableRow className="tr table-head">
                <TableCell className="th table-key tc-125">주소</TableCell>
                <TableCell className="td no-padding">{projectInfoData.주소목록 && projectInfoData.주소목록.length >= 2 ? `${projectInfoData.주소목록[0]} 외 ${projectInfoData.주소목록.length - 1}필지` : projectInfoData.주소목록}
                </TableCell>
              </TableRow>
              <TableRow className="tr">
                <TableCell className="th">대지 면적</TableCell>
                <TableCell className="td no-padding">{handleArea("대지면적")}</TableCell>
              </TableRow>
              <TableRow className="tr">
                <TableCell className="th">건축 용도</TableCell>
                <TableCell className="td no-padding">{projectInfoData.건물타입}</TableCell>
              </TableRow>
              <TableRow className="tr">
                <TableCell className="th">접수 일자</TableCell>
                <TableCell className="td no-padding">{projectInfoData.접수날짜 ? moment(projectInfoData.접수날짜).format("YYYY-MM-DD") : '-'}</TableCell>
              </TableRow>
              <TableRow className="tr">
                <TableCell className="th">검토 일자</TableCell>
                <TableCell className="td no-padding">{projectInfoData.검토날짜 ? moment(projectInfoData.검토날짜).format("YYYY-MM-DD") : '-'}</TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </article>
      </section>
    </>
  );
};

export default FailedResult;