import Events from 'events';

// @ts-ignore
const { naver } = window;
export const nProj: string = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
export const bProj: string = '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs';

export interface MapOptions {
  center?: any,
  zoom?: number,
  wheel?: boolean,
  zoomControl?: boolean,
  scaleControl?: boolean,
  logoControl?: boolean,
  mapDataControl?: boolean,
  transitionOptions?: {
    duration: number,
    easing: string,
  },
  cadastral?: boolean, 
  maxZoom?: number,
  draggable?: boolean,
  pinchZoom?: boolean,
  scrollWheel?: boolean,
  keyboardShortcuts?: boolean,
  disableDoubleTapZoom?: boolean,
  disableDoubleClickZoom?: boolean,
  disableTwoFingerTapZoom?: boolean,
  tileSpare?: number,
}

export interface NCoordinate {
  lat: number,
  lng: number,
}

export interface TMCoordinate {
  x: number;
  y: number;
}


export default class MapManager extends Events {
  map?: Map;
  cadastralLayer: any;

  createMap = (container: HTMLElement, options: MapOptions) => {
    this.map = new Map();
    this.map.setNaverMap(container, options);
    this.map!.on('click', (e) => this.emit('click', e))
  }

  onCadastralLayer = (on: boolean) => { this.map!.onCadastralLayer(on); }
  relayout = () => { this.map!.relayout() }
  setCenter = (coord: NCoordinate) => { this.map!.setCenter(coord)}
  getMap = () => {
    return this.map;
  }
}

export class Map extends Events {
  naverMap: any;
  cadastralLayer: any;
  setNaverMap = (container: HTMLElement, options: MapOptions) => {
    this.naverMap = new naver.maps.Map(container, {
      useStyleMap: true,
      ...options
    });
    naver.maps.Event.once(this.naverMap, 'init_stylemap', () => {
      this.cadastralLayer = new naver.maps.CadastralLayer();
      this.cadastralLayer.setMap(this.naverMap);
      this.naverMap.addListener('click', (e: any) => {
        this.emit('click', e)
      })
    })
  }
  getNaverMap = () => {
    return this.naverMap;
  }
  setCenter = (coord: NCoordinate) => {
    this.naverMap.setCenter(coord);
  }
  getCenter = () => {
    return this.naverMap.getCenter();
  }
  setZoom = (zoom: number, effect: boolean = false) => {
    this.naverMap.setZoom(zoom, effect);
  }
  getZoom = () => {
    return this.naverMap.getZoom();
  }

  getBoundary = () => {
    return this.naverMap.getBounds();
  }
  
  fitBounds = (min: [number, number], max: [number, number]) => {
    this.naverMap.fitBounds(new naver.maps.LatLngBounds(
      new naver.maps.LatLng(min[1], min[0]),
      new naver.maps.LatLng(max[1], max[0]),
    ), {
      top: 10, right: 10, left: 10, bottom: 10,
    })
  }
  onCadastralLayer = (on: boolean) => {
    if (on) {
      this.cadastralLayer.setMap(this.naverMap);
    } else {
      if (this.cadastralLayer.getMap()) {
        this.cadastralLayer.setMap(null);
      }
    }
  } 

  relayout = () => {
    this.naverMap.setSize(new naver.maps.Size(window.innerWidth - 440, window.innerHeight))
  }
}