import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch  } from 'react-redux';

import { getFieldInfoAtPoint, initializeFieldInfo } from '../modules/FieldInfo';
import { showWarningMessage } from '../modules/InformMessage';
// import { default as _ } from 'lodash';
import { logout } from '../modules/Auth';
import { updateProject } from '../modules/Project';
import MapManager, { NCoordinate } from '../MapManager';
import { RootState } from '../modules/Index';
import { Button } from '@material-ui/core';
import '../css/MapContent.scss';
import DrawingManager from '../DrawingManager';
import proj4 from 'proj4';
import { 
  Project, ShapeType, 
} from '../model/Project';
import * as turf from '@turf/turf';
import { Polygon } from '../DrawingManager';
import graphlib, { Graph } from 'graphlib';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { setShowProjectList } from '../modules/ProjectList';
import ListIcon from '@material-ui/icons/List';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

// @ts-ignore
const { naver } = window;

const jsts = require('jsts');

export type reset_type = "All" | "Value";
// const { naver } = window;
export const MapContent = () => {
  const [ onCadastral, setOnCadastral ] = useState(true);
  const [ mapManager ] = useState(new MapManager());
  const pnuList = useRef([] as string[]);
  const projectSite = useRef([] as Polygon[]);
  const selectedProjectSiteGraph = useRef(new Graph());
  const [priceMarkers, setPriceMarkers] = useState([]);
  const [priceOtherMarkers, setPriceOtherMarkers] = useState([]);
  const dm = new DrawingManager(mapManager.getMap()!);

  const { fieldData, solverloading, priceData, showList, session, mapCenter } = useSelector(
    ({ fieldInfo, addressInfo, project, priceInfo, projectList, auth }: RootState) => ({
      fieldData: fieldInfo.data,
      // addressData: addressInfo.data, 
      // projectData: project.data,    
      solverloading: project.solverloading,
      priceData: priceInfo.data,
      showList: projectList.showList,
      session: auth.session,
      mapCenter: addressInfo.mapCenter
    }),
  );
  const dispatch = useDispatch();

  
  useEffect(() => {
    const mapContainer = document.getElementById('map');
    mapManager.createMap(mapContainer!, { 
      center: undefined, //  {lat: 35.1780033, lng: 128.1455271},
      zoom: 18
    })
    window.onresize = () => { resizeMap(); }
    mapManager.on('click', onClickMap);
    resizeMap();    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const resizeMap = () => { mapManager.relayout(); }
  const setCadastral = (on: boolean) => { 
    setOnCadastral(on);
    mapManager.onCadastralLayer(on); 
  }
  /*
   * title: 지도 클릭 event 동작
   * var: coord(위경도 좌표)
   */
  const onClickMap = (e: any) => {
    const coord: NCoordinate = { lat: e.coord._lat, lng: e.coord._lng };
    const coordr = proj4(DrawingManager.nProj, DrawingManager.bProj, [coord.lng, coord.lat]);    

    dispatch(getFieldInfoAtPoint( {
      x: coordr[0] ,
      y: coordr[1] ,
    }));
  }

  
  
  const setGrpahNode = (polygon?: Polygon) => {
    let differ = false;
    let isOverlap = false; 
    let type = "";
    
    const nodes = selectedProjectSiteGraph.current.nodes();

    if (polygon) {
      for (let i = 0; i < nodes.length; i++) {
        const reader = new jsts.io.WKTReader();
        let p1 = reader.read(DrawingManager.toWKT(polygon));
        const p2 = reader.read(DrawingManager.toWKT(selectedProjectSiteGraph.current.node(nodes[i])));
  
        isOverlap = p1.intersects(p2);
        try {
          type = p1.union(p2).getGeometryType();
        } catch (e) {
          selectedProjectSiteGraph.current.nodes().map((p) => selectedProjectSiteGraph.current.node(p).remove(true));
          selectedProjectSiteGraph.current = new Graph();
          projectSite.current.map((p) => p.remove());
          projectSite.current = [] as Polygon[];
          pnuList.current = [pnuList.current[pnuList.current.length - 1]];

          isOverlap = false;

          dispatch(
            showWarningMessage({
              msg: "지적도 데이터에 오차가 있어 합필할 수 없습니다.",
              width: 370,
              autoClose: 3000,
            })
          );
        }
        // @ts-ignore
        if ((type === "MultiPolygon" && isOverlap) || !isOverlap) {
          differ = true;
        }
        //
        if (isOverlap && type === "Polygon") {
          selectedProjectSiteGraph.current.setNode(polygon.getId()!, polygon);
          selectedProjectSiteGraph.current.setEdge(nodes[i], polygon.getId()!);
          differ = false;
          return;
        }
      };
        // 1개 제한
        if (differ) {
          selectedProjectSiteGraph.current.nodes().map(p => selectedProjectSiteGraph.current.node(p).remove(true));
          selectedProjectSiteGraph.current = new Graph();
          projectSite.current.map(p => p.remove());
          projectSite.current = [] as Polygon[];
          pnuList.current = [pnuList.current[pnuList.current.length - 1]];
         
          isOverlap = false;
        }
  
        if (isOverlap === false) {
          selectedProjectSiteGraph.current.setNode(polygon.getId()!, polygon);
        }
    } else {
    }
  };

  const toTurfPolygon = (polygon: Polygon) => {
    const path = polygon.getPath();
    for (let i = 0; i < path.length; i++) {
      // @ts-ignore
      path[i].push(path[i][0]);  
    }
    return turf.polygon(path);
  }
  
  const unionProjectSite = (callback?: Function) => {
    // projectSite.map(p => clearRoads(p));
    projectSite.current = [];
    // let project_site = [];
    let union: Array<any> = [];
    graphlib.alg.components(selectedProjectSiteGraph.current).forEach((g, i) => {
      g.forEach((p, j) => {
        if (union[i]) {
          union[i] = turf.union(union[i], toTurfPolygon(selectedProjectSiteGraph.current.node(p)));
        } else {
          union[i] = toTurfPolygon(selectedProjectSiteGraph.current.node(p));
        }
      });
    });
    // console.log('union', union);
    const bigSiteAreas = union.map(r => turf.area(r));
    const bigSiteIndex = bigSiteAreas.indexOf(Math.max(...bigSiteAreas));
    let bigUnion;
    
    if (bigSiteIndex > -1) {
      bigUnion = union[bigSiteIndex];
      union[bigSiteIndex].geometry.coordinates[0].splice(union[bigSiteIndex].geometry.coordinates[0].length - 1, 1);
      const polygon = dm.addPolygon(bigUnion.geometry.coordinates, { ...DrawingManager.DrawingOption.PROJECT_SITE, simplify: true }, true);
      polygon.setOptions({ visible: false });
      projectSite.current.push(polygon as Polygon);
      
      graphlib.alg.components(selectedProjectSiteGraph.current).forEach((r, i) => {
        if (i !== bigSiteIndex) {
          r.forEach(rr => {
            let p = selectedProjectSiteGraph.current.node(rr);
            p.remove();
            selectedProjectSiteGraph.current.removeNode(rr);
            let index = pnuList.current!.findIndex(e => e === p.getPnu())
            if (index > -1) {
              pnuList.current!.splice(index, 1);
            }
          })
        }
      })
    }
    if (callback) {
      callback();
    }
  }
  
  const onUpdateProject = (update: Project) => {
    dispatch(updateProject(update));  
  }  

  const onUpdateShape = (type?: ShapeType) => {   // polygon 다시 설정할때: 삭제?
    const update: Project = {};
    update.project_site = projectSite.current.map(p => DrawingManager.toWKT(p));
    update.project_site_center = DrawingManager.getProjectSiteCenter(projectSite.current).centerWKT;
    update.field_info_pnu = pnuList.current;
    onUpdateProject(update);
  };
  
  // 프로젝트 run => solverloading === true, 폴리곤 초기화
  useEffect(() => {
    if(solverloading) {
      pnuList.current = [];
      selectedProjectSiteGraph.current.nodes().map(p => selectedProjectSiteGraph.current.node(p).remove(true));
      selectedProjectSiteGraph.current = new Graph();
      projectSite.current.map(p => p.remove());
      projectSite.current = [] as Polygon[];
    }
  },[solverloading])

  useEffect(() => {
    if(fieldData!.pnu && (!["도", "공", "천", undefined].includes(fieldData!.jimog))) {
      const pnu = fieldData!.pnu; 
      if (pnuList.current!.findIndex(e => e === pnu) < 0) {
        pnuList.current.push(pnu);
     //   console.log(fieldData!.geom!, typeof(fieldData!.geom!));// fieldData.geom
        
        const polygon = dm.addPolygon(DrawingManager.toGeom(fieldData!.geom!, "Field").coordinates, {...DrawingManager.DrawingOption.PROJECT_SITE, simplify: false}, true);
        polygon.setRightClick(false);
        polygon.setPnu(fieldData!.pnu);
        polygon.on('click', () => {
          const index = pnuList.current!.findIndex(e => e === (polygon as Polygon).getPnu());
          pnuList.current!.splice(index, 1);
          selectedProjectSiteGraph.current.removeNode(polygon.getId()!);
          polygon.remove();
          dispatch(initializeFieldInfo());
          unionProjectSite();
          onUpdateShape("PROJECT_SITE");
        });   
        setGrpahNode(polygon);
        unionProjectSite();
        onUpdateShape("PROJECT_SITE");
      }           
    } else if(fieldData!.pnu && (["도", "공", "천", undefined])) {
      dispatch(showWarningMessage({
        msg: "도로, 공지, 하천 등은 선택하실 수 없습니다.",
        width: 370,
        autoClose: 3000,   
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldData!.pnu]);

  // useEffect(() => {
  //   if(addressData!.coord)
  //     mapManager.setCenter(addressData!.coord!); 
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[addressData])


  useEffect(() => {
    if(mapCenter) {
      const coord = { lat: mapCenter[1], lng: mapCenter[0] }
      mapManager.setCenter(coord); 
    }
  }, [mapCenter, mapManager])


  const makePriceInfoWindows = () => {

    priceMarkers.forEach((marker: any) => {
      //@ts-ignore
      marker.setMap(null);
    });
    priceOtherMarkers.forEach((marker: any) => {
      //@ts-ignore
      marker.setMap(null);
    })
    setPriceMarkers([]);    
    setPriceOtherMarkers([]);    
    const map = mapManager.getMap()!.getNaverMap()!;

    
    if(priceData!.parcelInfo! && priceData!.parcelInfo!.pnu!) {
      const currentOtherMarkers = priceData.others!.slice(0, priceData.others.length > 100 ? 100 : priceData.others.length ).map((item: any) => {        
        const pointCenter = DrawingManager.toGeom(item.필지중심위치정보, 'Point').coordinates; 
        const realPrice = Number(item.평당실거래가).toFixed(1);
        const dateString = item.priceData!.계약년월!.substring(0, 4).concat('.').concat(item.priceData!.계약년월!.substring(4, 6));
        const contentString = [
          `<div class="info_dark_container">
             <div class="contents_price">${realPrice}만</div>
             <div class="content_date">[${dateString}]</div>
          </div>`
        ].join('');
        const marker = new naver.maps.Marker({
          map: map,
          position: pointCenter,
          // visible: false,
          icon: {
            content: contentString,
            size: new naver.maps.Size(60, 45),
            // anchor: new naver.maps.Point(0, 0)
          }
        });
        return marker;
      });
      const currentMarkers = priceData.scores!.map((item: any) => {
        const pointCenter = DrawingManager.toGeom(item.필지중심위치정보, 'Point').coordinates; 
        const realPrice = Number(item.평당실거래가).toFixed(1);
        const dateString = item.priceData!.계약년월!.substring(0, 4).concat('.').concat(item.priceData!.계약년월!.substring(4, 6));
        const contentString = [
          `<div class="info_container">
             <div class="contents_price">${realPrice}만</div>
             <div class="content_date">[${dateString}]</div>
          </div>`
        ].join('');
        const marker = new naver.maps.Marker({
          map: map,
          position: pointCenter,
          // visible: false,
          icon: {
            content: contentString,
            size: new naver.maps.Size(60, 45),
            // anchor: new naver.maps.Point(0, 0)
          }
        });
        return marker;
      });
      if(currentMarkers!.length > 0) 
        setPriceMarkers(currentMarkers);
      if(currentMarkers!.length > 0) 
        setPriceOtherMarkers(currentOtherMarkers);
    }
  }


  /** 토지 매매가 정보  */
  useEffect(()=> {
    makePriceInfoWindows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceData])


  return (
    <div className="MapContent">      
      <div id="map" style={{ width: "100px", height: "100px"}} ></div>
      <div className="btn-groups-left">
        
        <button 
          onClick={() => setCadastral(!onCadastral)}
          style={{
            fontFamily: 'Roboto',
            fontSize: '12px',
            width: '80px',
            height: '30px',
            cursor: 'pointer',            
            background: `${onCadastral? '#fff': '#26A69A'}`,
            border: `1px solid ${onCadastral? '#26A69A': '#cccccc'}`,
            color:  `${(onCadastral)? '#26A69A': '#fff'}`,
            borderRadius: '6px',
            margin: '2px',
            outline: 'none'
          }}
        >
          지적편집도
        </button>
        <Button 
          onClick={() =>  dispatch(setShowProjectList({
            showList: !showList
          }))}
          startIcon={<ListIcon />}
          style={{
            fontFamily: 'Roboto',
            fontSize: '12px',
            width: '130px',
            height: '30px',            
            background: `${showList? '#fff': '#26A69A'}`,
            border: `1px solid ${showList? '#26A69A': '#cccccc'}`,
            color:  `${showList? '#000': '#fff'}`,
            borderRadius: '6px',
            margin: '2px',
            outline: 'none'
          }}
        >
          사업성검토목록
        </Button>
      </div>
      <div className="btn-groups-right">
        <Button        
          startIcon={<PermIdentityIcon />}
          style={{
            fontFamily: 'Roboto',
            fontSize: '12px',
            width: '100px',
            height: '30px',            
            background: '#ffffff',
            border: '1px solid #26A69A',
            color:  '#26A69A',
            borderRadius: '6px',
            margin: '2px',
            outline: 'none'
          }}
        >
          {session && session.id}
        </Button>
        <Button 
          onClick={() => dispatch(logout())}
          startIcon={<ExitToAppIcon />}
          style={{
            fontFamily: 'Roboto',
            fontSize: '12px',
            width: '100px',
            height: '30px',            
            background: '#26A69A',
            border: '1px solid #cccccc',
            color:  '#fff',
            borderRadius: '6px',
            margin: '2px',
            outline: 'none'
          }}
        >
          로그아웃
        </Button>
        
      </div>
      <div className="main-right-logo">        
        <img src={'/img/mainlogo.png'} style={{ width: '179px', height: '40px' }} alt=""></img>
      </div>
    </div>
  )

}

export default MapContent;