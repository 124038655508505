import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { architecture_overview_office } from "../../model/result";

import { Visualizer } from "../../viewer/Visualizer";
import { ResultPageProps } from "./ResultInfo";
import ProjectResultTitle from "./ProjectResultTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../modules/Index";

const OfficeArchitectureOverview = (props: ResultPageProps) => {
  const { resultInfoData } = useSelector(({ selectedProject }: RootState) => ({
    resultInfoData: selectedProject.result,
  }));

  const renderTableData = (data: any) => {
    if (data.unit === "㎡" || data.unit === "평") return handleArea(data);
    else if (resultInfoData[data.key]) {
      if (data.keys) {
        return `${data.keys.map((key: string) => resultInfoData[key])}${data.unit}`;
      } else {
        return `${data.value(resultInfoData[data.key])}${data.unit}`;
      }
    } else {
      return data.key ? `${data.value(resultInfoData[data.key])}${data.unit}` : "-";
    }
  };

  const handleArea = (data: any) => {
    if (data.keys) {
      if (data.keys) {
        // 건축면적, 연면적
        let val = data.keys.map((key: string) => resultInfoData[key] && resultInfoData[key]);
        val = data.value(val);
        if (props.unitState === "평") {
          val = (val / (1 / 0.3025)).toFixed(2);
        }
        return val && `${val}${props.unitState}`;
      } else {
        // 공사용 연면적
        if (props.unitState === "평") {
          return (Number(resultInfoData[data.key]) / 3.306).toFixed(2) + props.unitState;
        }
        return resultInfoData[data.key] && data.value(resultInfoData[data.key]) + props.unitState;
      }
    } else {
      // 대지면적, 조경면적, 공사용연면적
      if (props.unitState === "평") {
        return resultInfoData[data.key] ? `${Number(resultInfoData[data.key] / (1 / 0.3025)).toFixed(2)}${props.unitState}` : "-";
      }
      return resultInfoData[data.key] ? `${Number(resultInfoData[data.key]).toFixed(2)}${props.unitState}` : "-";
    }
  };

  return (
    <section className="ArchitectureOverview">
      <ProjectResultTitle></ProjectResultTitle>
      <div className="secion-inner">
        <div className="sub-title">3. 건축개요</div>
        <div className="content-wraper">
          <TableContainer>
            <Table size="small">
              <TableHead className="table-head">
                <TableRow className="tr ">
                  <TableCell className="th tc-125" rowSpan={resultInfoData.주소목록.length}>
                    주소
                  </TableCell>
                  <TableCell className="td no-padding">{resultInfoData.주소목록 && resultInfoData.주소목록[0]}</TableCell>
                </TableRow>
                {resultInfoData.주소목록 &&
                  resultInfoData.주소목록.map(
                    (address: string, index: number) =>
                      index > 0 && (
                        <TableRow>
                          <TableCell className="td no-padding">{address}</TableCell>
                        </TableRow>
                      )
                  )}
              </TableHead>
              <TableBody className="table-body">
                {architecture_overview_office.map((data) => (
                  <TableRow className="tr" key={data.label + "tablebody"}>
                    <TableCell className="th">{data.label === "대지 면적" && resultInfoData.주소목록 && resultInfoData.주소목록.length >= 2 ? "총 대지 면적" : data.label}</TableCell>
                    <TableCell className="td no-padding">{renderTableData(data)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <Visualizer projID={resultInfoData.base_project_id} reportsNumber={resultInfoData.reports_number} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OfficeArchitectureOverview;
