import { createAction, handleActions } from 'redux-actions';
import { createRequestActionTypes } from '../lib/createRequestSaga';
import { getPriceInfoAPI, getHouseHoldRentInfoAPI, getHouseHoldSalesInfoAPI } from '../api/PriceInfoApi';
import { put, select, call, takeEvery } from 'redux-saga/effects';
import { RootState } from './Index';
import { default as _ } from 'lodash';
import { showErrorMessage } from './InformMessage';

const [
  GET_PRICE_INFO,
  GET_PRICE_INFO_SUCCESS,
  GET_PRICE_INFO_FAILURE,
] = createRequestActionTypes('GET_PRICE_INFO');

const INITIALIZE_PRICE_INFO = 'INITIALIZE_PRICE_INFO';

export const getPriceInfo = createAction(
  GET_PRICE_INFO,
  (pnu: string) => pnu
);
export const initializePriceInfo = createAction(INITIALIZE_PRICE_INFO);
// const getPriceInfoSaga = createRequestSaga(GET_PRICE_INFO, getPriceInfoAPI);

function* getPriceInfoSaga(action: any) {  
  const selected = yield select((state: RootState) => state.priceInfoConfig.selected);
  const params = yield select((state: RootState) => _.get(state.priceInfoConfig, selected));
  const priceConfigOnOff = yield select((state: RootState) => state.priceInfoConfig.ONOFF);
  if(!priceConfigOnOff) return;     // 상세 설정이 켜 있지 않은 경우 api call 하지 않음
  try {
    switch (selected) {
      case "토지매매":
        const responsePrice = yield call(getPriceInfoAPI, action.payload, params);
        yield put({
          type: GET_PRICE_INFO_SUCCESS,
          payload: responsePrice.data,
          meta: responsePrice,
        });
        break;
      case "세대매매":
        const responseSales = yield call(getHouseHoldSalesInfoAPI, action.payload, params);
        yield put({
          type: GET_PRICE_INFO_SUCCESS,
          payload: responseSales.data,
          meta: responseSales,
        });
        break;
      case "세대임대":
        const responseRent = yield call(getHouseHoldRentInfoAPI, action.payload, params);
        yield put({
          type: GET_PRICE_INFO_SUCCESS,
          payload: responseRent.data,
          meta: responseRent,
        });
        break;
      default:
        break;
    }
  } catch(e) {
    yield put(showErrorMessage({
      msg: "주변 토지매매가 추정 정보 수신 실패",
      errorMessage: e.msg,
      autoClose: 3000,   
    }));
    yield put({
      type: GET_PRICE_INFO_FAILURE,
      payload: e.msg,
    });
  }
}

//solverloading
export function* watchPriceInfo() {
  yield takeEvery(GET_PRICE_INFO, getPriceInfoSaga);
}

interface FieldInfoState {
  type?: string;
  data?: any;
  meta?: string;
  error?: string;
};

const initialState: FieldInfoState = {
  data: {},
};


  
const priceInfo = handleActions<FieldInfoState, any>(
  {
    [GET_PRICE_INFO_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
    [GET_PRICE_INFO_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [INITIALIZE_PRICE_INFO]: () => initialState,
  },
  initialState,
);

export default priceInfo;