import React from "react";
import { useSelector } from 'react-redux';
import { CircularLoading, CircularProgress, CircularMainText } from '../../styledComponents/loading'
import { RootState } from '../../modules/Index';

export const ProgressLoading = () => {

  const { loading, solverloading, roadLoading } = useSelector(
    ({ project, projectDefault, projectErrors }: RootState) => ({
      projectData: project.data,
      // projectDefaultData: projectDefault.data,
      loading: projectDefault.loading,
      solverloading: project.solverloading,
      roadLoading: projectErrors.roadloading,
      errors: projectErrors.defaultErrors,
    }),
  );
  return(
    <div>
      { 
      (loading || solverloading || roadLoading ) && (
        <CircularLoading>
          <CircularProgress />
          <CircularMainText>LH MAP PLATFORM</CircularMainText> 
        </CircularLoading>
      )
    }
    </div>    
  )
}

export default ProgressLoading;