/* eslint-disable */
import React, { useEffect, useState } from "react";
import { FlexContainer, KeyValueContainer } from "../../../styledComponents/layout";
import { KeyValueTitle } from "../../../styledComponents/text";
import { TextField } from "@material-ui/core";
import { HelpQuestion } from "../HelpQuestion";
interface RowsKeyInputSelectProps {
  keyName: string;
  value: string | number | undefined;
  width: string;
  onChange: (event: any) => void;
  disabled?: boolean;
  options: Array<any>;
  help?: string;
}

export const RowsKeyInputSelect = ({ onChange, options, ...props }: RowsKeyInputSelectProps) => {
  return (
    <KeyValueContainer width={props.width} height="auto">
      <FlexContainer justifyContent="space-between" width="98%" padding="0px 20px 0px 0px">
        <KeyValueTitle>{props.keyName}</KeyValueTitle>
        {props.help && <HelpQuestion helpString={props.help} id={props.keyName} />}
      </FlexContainer>
      <FlexContainer direction="row" justifyContent="flex-start" width="100%">
        <TextField
          select
          value={props.value}
          onChange={onChange}
          SelectProps={{
            native: true,
          }}
          disabled={props.disabled ? true : false}
          style={{ width: "98%" }}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </FlexContainer>
    </KeyValueContainer>
  );
};
