import React, { useEffect, useState } from 'react';
import {
  createMuiTheme,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules/Index';
import { modConstructPay } from '../../modules/SelectedProject';
import { numberWithCommas } from '../../utils/Format'
import ProjectResultTitle from './ProjectResultTitle';
import { HelpQuestion } from '../common/HelpQuestion';
// import { modRentPossibility } from '../../modules/SelectedProject';
import { FlexContainer } from '../../styledComponents/layout';

const getCalcConstructionPay = (unit: string, pay: number, area: number) => {
  // return Math.round(Number(area * pay * 0.3025));
  return Math.round(Number(area * pay));      // 평당공사비 -> 텍스트가 바뀌면서 m2당 공사비
}

// const calSum = (key: string, list: any) => {

//   if (!key) {
//     return '-'
//   }
//   const numberList = Object.keys(list).map((item) => {
//     return Number(list[item][key]).toFixed(2);
//   });

//   const result = numberList.map((data: any) => {
//     return Number(data)
//   }).reduce((a: number, b: number) => a + b, 0);

//   return result === 0.0 ? '-' : numberWithCommas(Math.round(result));
// }

// const calSumNonFormat = (key: string, list: any) => {
//   if (!key) {
//     return '-'
//   }

//   const numberList = Object.keys(list).map((item) => {
//     return Number(list[item][key]).toFixed(2);
//   });

//   const result = numberList.map((data: any) => {
//     return Number(data)
//   }).reduce((a: number, b: number) => a + b, 0);

//   return result === 0.0 ? '-' : Math.round(result);
// }

const BusinessFeasibility = ({ unitState, ...props }: any) => {

  const { result, supportPay, totalScore } = useSelector(
    ({ selectedProject, project }: RootState) => ({
      result: selectedProject.result,
      totalScore: selectedProject.result.점수,
      supportPay: Number(project.data.customer.supportPay * 10000) ,
    }),
  );

  const dispatch = useDispatch();

  const { priceReport, 평당공사비, scoreReport } = useSelector(({ selectedProject }: RootState) => ({
    // projectInfo: selectedProject.project,
    priceReport: selectedProject.result.priceReport,
    // rentSellReport: selectedProject.result.rentSellReport,
    평당공사비: selectedProject.result.평당공사비,
    scoreReport: selectedProject.result.scoreReport!,
  }));

  
  // const [score, setScore] = useState(result.점수 || 0); // 합계

  const [constPay, setConstPay] = useState(평당공사비 || 1);   // 평당 공사비
  const [totalConstPay, setTotalConstPay] = useState(getCalcConstructionPay(unitState, constPay, result.realTotalArea))    // 예상 공사비용

  const [totalAllPay, setTotalAllPay] = useState(Math.round(Number(totalConstPay) + Number(priceReport.예상_토지매매가 * 0.0001)) || 0);

  useEffect(() => {
    setConstPay(평당공사비);    
  }, [평당공사비])

  useEffect(() => {
    setTotalConstPay(getCalcConstructionPay(unitState, constPay, result.realTotalArea));
  }, [unitState, constPay, result.realTotalArea]);

  useEffect(() => {
    setTotalAllPay(Math.round(Number(totalConstPay) + Number(priceReport.예상_토지매매가 * 0.0001)));
  }, [totalConstPay, priceReport.예상_토지매매가])

  // const [rentPossibility, setRentPossibility] = useState(result.임대가능성 || 15); 
  // const saveRentPossibility = useCallback(() => { 
  //   if (rentPossibility !== "" && rentPossibility >= 0) { 
  //     dispatch(modRentPossibility({ project_id: projectInfo.project_id, value: Number(rentPossibility), })); } 

      
  //   }, [rentPossibility, dispatch, projectInfo])


    // useEffect(() => {
    //   calScore([result.확장성, result.접면도로, result.토지의형상, result.이격거리, result.대중교통접근성,
    //     result.대학교접근성, result.생활편익시설, result.용도지역])
    // });

  // const CssTextFiled = withStyles({
  //   root: {
  //     '& label.Mui-focused' : {
  //       color: '#00897B',
  //     }

  //   },

  // })(TextField);
  // const resHouseLength = Object.keys(rentSellReport.result).length + 2 // tmpHouseholdData.length; // 결과 data 길이
  // const pageRef = useRef(1);
  // const [pageIndex, setPageIndex] = useState([] as Array<Array<number>>);
  // const DIVISION_NUM = 14; // 페이지 분할 기준 column 수
  // const [key, setKey] = useState(['']); // 정렬된 호수


  // useEffect(() => {
  // pageRef.current = Math.ceil(resHouseLength / DIVISION_NUM);
  // let page_index: Array<Array<number>> = _.cloneDeep(pageIndex);
  // let tempNumber = 0;
  // let startIndex = 0;
  // let endIndex = DIVISION_NUM - 1;
  // for (let i = 0; i < pageRef.current; i++) {
  //   page_index.push([startIndex, endIndex]);
  //   tempNumber += DIVISION_NUM;
  //   startIndex = tempNumber;
  //   endIndex = startIndex + DIVISION_NUM - 1;
  // }
  // setPageIndex(page_index);
  //setKey(Object.keys(rentSellReport.result).sort(sorting)); // 호실 정렬
  //
  //}, [])

  // const sorting = (a: string, b: string) => {
  //   if (a[0] < b[0]) {
  //     return -1;
  //   } else if (a[0] === b[0]) {
  //     if (a.length < b.length) {
  //       return -1;
  //     } else if (a.length > b.length) {
  //       return 1;
  //     }
  //   }

  //   if (a < b) {
  //     return -1
  //   }
  //   return 1;
  // }
  let scoreReportString = ``;
  if (result.scoreReport["토지의_형상_및_레벨"]) {
    scoreReportString = `
      대중교통 접근성: ${result.scoreReport["대중교통_접근성"]!.nearest["이름"]}, ${result.scoreReport["대중교통_접근성"]!.nearest["거리"].toFixed(1)}m 
      대학교 접근성: ${result.scoreReport["대학교_접근성"]!.nearest["이름"]} ${result.scoreReport["대학교_접근성"]!.nearest["거리"].toFixed(1)}m,
      접면도로 최대 폭: ${result.scoreReport["접면_도로"]!.data["접면도로_최대폭"]}m 
      접면도로 개수: ${result.scoreReport["접면_도로"].data["접면도로_개수"]},
      접면도로 북향도로 유무: ${result.scoreReport["접면_도로"]!.data["북향도로_유뮤"]}
      주변건물 개수: ${result.scoreReport["주변_건물_이격거리"]!.data["주변건물_개수"]}
      주변건물 최소거리: ${result.scoreReport["주변_건물_이격거리"]!.data["주변건물_최소거리"].toFixed(1)}m
      토지 형상: ${result.scoreReport["토지의_형상_및_레벨"]!.data["토지형상"]["형상"]}
      토지 기울기: ${result.scoreReport["토지의_형상_및_레벨"]!.data["기울기"].factor}
    `;  
  } else if (result.scoreReport["대중교통_접근성"]) {
    scoreReportString = `
      대중교통 접근성: ${result.scoreReport["대중교통_접근성"]!.nearest["이름"]}, ${result.scoreReport["대중교통_접근성"]!.nearest["거리"].toFixed(1)}m 
      대학교 접근성: ${result.scoreReport["대학교_접근성"]!.nearest["이름"]} ${result.scoreReport["대학교_접근성"]!.nearest["거리"].toFixed(1)}m,
      접면도로 넓이: ${result.scoreReport["접면도로의_넓이"]!.score}m 
    `;
  } else {
    scoreReportString = ``;
  }

  const mainTheme = createMuiTheme({
    palette: {
      primary: {
        main: '#26A69A',
      },
    },
  });

  return (
    <>
      <section className="BusinessFeasibility" key={`6_page`}>
        <ProjectResultTitle></ProjectResultTitle>
        <div className={`sub-title`}>
          <FlexContainer justifyContent="flex-start">
            <span style={{ marginRight: "3px" }}>7. 사업성 검토</span> {scoreReportString !== '' && <HelpQuestion helpString={scoreReportString} id="result_scoreReport" />}
          </FlexContainer>
        </div>
        <div className="content-wraper">
          <article className="left-article">
            <h5 className={`sub-header`}>1) 매입 평가 점수</h5>
            <TableContainer>
              <Table size="small">
                <TableHead className="table-head">
                  <TableRow className="tr">
                    <TableCell className="th tc-125" align="left">
                      구분
                    </TableCell>
                    <TableCell className="th tc-240 no-padding" align="left">
                      평가 항목
                    </TableCell>
                    <TableCell className="th tc-125" align="left">
                      점수
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="tr">
                    <TableCell className="th" align="left">
                      임대관리용이성
                    </TableCell>
                    <TableCell className="td no-padding" align="left">
                      가구수
                    </TableCell>
                    <TableCell className="td" align="left">
                      {result.임대관리용이성 !== undefined ? `${result.임대관리용이성}점` : `-`}
                    </TableCell>
                  </TableRow>

                  <TableRow className="tr">
                    <TableCell className="th" align="left" rowSpan={3}>
                      공사 시행여건
                    </TableCell>
                    <TableCell className="td no-padding" align="left">
                      접면 도로
                    </TableCell>
                    <TableCell className="td" align="left">
                      {result.접면도로 !== undefined ? `${result.접면도로}점` : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="td no-padding" align="left">
                      토지의 형상 및 레벨
                    </TableCell>
                    <TableCell className="td" align="left">
                      {result.토지의형상 !== undefined ? `${result.토지의형상}점` : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="td no-padding" align="left">
                      <FlexContainer justifyContent="flex-start">
                        <span style={{ marginRight: "3px" }}>주변 건물 이격거리</span>
                        <HelpQuestion helpString="현장조사가 필요한 항목입니다" id="현장조사주변건물" />
                      </FlexContainer>
                    </TableCell>
                    <TableCell className="td" align="left">
                      {result.이격거리 !== undefined ? `${result.이격거리}점` : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow className="tr">
                    <TableCell className="th" align="left" rowSpan={3}>
                      생활 편의성
                    </TableCell>
                    <TableCell className="td no-padding" align="left">
                      대중교통 접근성
                    </TableCell>
                    <TableCell className="td" align="left">
                      {result.대중교통접근성 !== undefined ? `${result.대중교통접근성}점` : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow className="tr">
                    <TableCell className="td no-padding" align="left">
                      대학교 접근성
                    </TableCell>
                    <TableCell className="td" align="left">
                      {result.대중교통접근성 !== undefined ? `${result.대학교접근성}점` : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow className="tr">
                    <TableCell className="td no-padding" align="left">
                      생활 편의 시설
                    </TableCell>
                    <TableCell className="td" align="left">
                      {result.대중교통접근성 !== undefined ? `${result.생활편익시설}점` : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow className="tr">
                    <TableCell className="th" align="left">
                      향후 개발 가치
                    </TableCell>
                    <TableCell className="td no-padding" align="left">
                      용도 지역
                    </TableCell>
                    <TableCell className="td" align="left">
                      {result.대중교통접근성 !== undefined ? `${result.용도지역}점` : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow className="tr">
                    <TableCell className="th highlight" align="left">
                      합계
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell className={`th ${totalScore >= 60 ? "highlight" : "lightgrey"}`} align="left">
                      {`${totalScore}점 (${totalScore >= 60 ? "합격" : "불합격"})`}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </article>

          <article className="right-article">
            <div className="sub-title-second">
              <h5 className="sub-header ">{`2) 사업성 검토`}</h5>
            </div>
            <TableContainer>
              <Table size="small">
                <TableHead className="table-head">
                  <TableRow className="tr">
                    <TableCell className="th tc-125" align="left">
                      항목
                    </TableCell>
                    <TableCell className="th no-padding" align="left">
                      비용
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="tr">
                    <TableCell className="th" align="left">
                      소요 비용
                    </TableCell>
                    <TableCell className="td no-padding" align="left">
                      {numberWithCommas(Math.round(totalAllPay!)).concat(" 만원")}
                    </TableCell>
                  </TableRow>
                  <TableRow className="tr">
                    <TableCell className="th sub-th" align="left">
                      토지매입 예측가
                    </TableCell>
                    <TableCell className="td sub-td no-padding" align="left">
                      {numberWithCommas(Math.round(priceReport.예상_토지매매가 * 0.0001)).concat(" 만원")}
                    </TableCell>
                  </TableRow>
                  <TableRow className="tr">
                    <TableCell className="th sub-th" align="left">
                      공사 비용
                    </TableCell>
                    <TableCell className="td sub-td no-padding" align="left">
                      {numberWithCommas(Math.round(totalConstPay)).concat(" 만원")}
                    </TableCell>
                  </TableRow>
                  {/* <TableRow className="tr">
                    <TableCell className="th" align="left" >예상 수익</TableCell>
                    <TableCell className="td no-padding" align="left">{calSum("매매가_평균", rentSellReport.result).concat(' 만원')}</TableCell>
                  </TableRow> */}
                  <TableRow className="tr">
                    <TableCell className="th" align="left">
                      지원금액
                    </TableCell>
                    <TableCell className="td no-padding" align="left">
                      {numberWithCommas(Number(supportPay * result.loc_total_household))} 만원
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow className="tr table-foot">
                    <TableCell className="th highlight" align="left">
                      비용 합계
                    </TableCell>

                    <TableCell className="th highlight no-padding" align="left">{`${numberWithCommas(
                      Number(Number(supportPay * result.loc_total_household)) - Number(Math.round(totalAllPay!))
                    )} 만원`}</TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>

            <MuiThemeProvider theme={mainTheme}>
              <TextField
                value={constPay}
                type="number"
                id="standard-required"
                label="m² 당 공사비 예상 값(만원)"
                defaultValue="입력"
                inputProps={{ min: 10, style: { textAlign: "left" } }}
                InputLabelProps={{ shrink: true }}
                autoComplete="off"
                name="평당공사비"
                className="text-field"
                color="primary"
                onChange={(e) => {
                  setConstPay(e.target.value);
                  dispatch(modConstructPay(e.target.value));
                }}
              />
            </MuiThemeProvider>
            <div className="help-icon">
              <HelpQuestion helpString="사업성 검토시 예상결과를 비교할 수 있도록 입력값을 검토할 수 있습니다." id="result_평당공사비" />
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default BusinessFeasibility;