
import React from 'react';
import { useSelector, useDispatch  } from 'react-redux';

import { RootState } from '../modules/Index';
// import '../css/ProjectForm.scss';
import AddressSearchSection from './menusection/AddressSearchSection';

import FeasibilityConfigSection from './menusection/FeasibilityConfigSection';
import ExtraInfoSection  from './menusection/ExtraInfoSection';
import SelectedPnuInfoSection from './menusection/SelectedPnuInfoSection';
import PurchaseConditionSection from './menusection/PurchaseConditionSection';
import BasicDataSection from './menusection/BasicDataSection';
import { MainLeftPaneDiv, RunPaneDiv, FlexContainer, LogoPaneDiv, MainPaneDiv,  LogoDiv, HorizontalEmptyDivide } from '../styledComponents/layout'

import { Button } from '@material-ui/core';
import { SectionTitleText } from '../styledComponents/text';
// import ProjectResult from './ProjectResult';

import RunComponent from './menusection/RunComponent';
import { InfoTextDiv } from '../styledComponents/text'
import { setShowProjectList } from '../modules/ProjectList';
import { ProjectListModal } from './ProjectListModal';

export default function ProjectForm() {
  const { pnulist, scrollable } = useSelector(
    ({ project, inputcontrols }: RootState) => ({      
      projectdata: project.data,    
      pnulist: project.pnuList,
      scrollable: inputcontrols.main_scrollable
    }),
  );
  
  const dispatch = useDispatch();
  const onShowProjectList = () => {
    dispatch(setShowProjectList({
      showList: true
    }));
  }

  return (
    <MainLeftPaneDiv>
      <LogoPaneDiv>
        <FlexContainer direction="column" height="auto" background="#B2DFDB" style={{ marginBottom: "20px" }}>
          <LogoDiv>LH MAP PLATFORM</LogoDiv>
          <AddressSearchSection />
        </FlexContainer>
      </LogoPaneDiv>
      <MainPaneDiv overflow={scrollable}>
        {!pnulist.length && (
          <FlexContainer direction="column" justifyContent="flex-start" height="auto" padding="12px 0px">
            <Button
              style={{
                width: "90%",
                border: "1px solid #00897B",
                color: "#00897B",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "18px",
                height: "32px",
              }}
              className="btn btn-primary btn-outline project-list-btn"
              onClick={() => onShowProjectList()}
            >
              {/* <ListIcon className="icon" /> */}
              나의 검토 목록 열기
            </Button>
            <HorizontalEmptyDivide />
            <FlexContainer width="90%" height="auto" direction="column" padding="10px 0px" style={{ border: "1px solid #26A69A", borderRadius: "6px" }}>
              <InfoTextDiv type="primary">필지를 선택하시면 사업성 검토가 가능합니다.</InfoTextDiv>
              <HorizontalEmptyDivide />
              <InfoTextDiv>
                · 1, 2종 일반주거지역 : 70~600㎡ <br />
                · 3종 일반주거지역, 준주거지역, 상업지역, 준공업지역 : 70~5000㎡
              </InfoTextDiv>
            </FlexContainer>
          </FlexContainer>
        )}
        {pnulist.length > 0 && (
          <FlexContainer direction="column" justifyContent="flex-start" alignItems="flex-start" height="auto" padding="12px 0px">
            <HorizontalEmptyDivide />
            <SectionTitleText width="90%" align="left" padding="0px 0px 10px 20px">
              선택한 필지 {pnulist.length}
            </SectionTitleText>
            <SelectedPnuInfoSection />
            <ExtraInfoSection />
            <HorizontalEmptyDivide />
            <HorizontalEmptyDivide />
            <FeasibilityConfigSection />
            <PurchaseConditionSection />
            <HorizontalEmptyDivide />
            <HorizontalEmptyDivide />
            <BasicDataSection />
          </FlexContainer>
        )}
      </MainPaneDiv>
      {/* <PriceInfoConfig /> */}
      <ProjectListModal />
      <RunPaneDiv>
        <RunComponent />
      </RunPaneDiv>
    </MainLeftPaneDiv>
  );
}