import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import "../../css/ResultInfo.scss";
import { site_analysis } from "../../model/result";
import MapManager from "../../MapManager";
import DrawingManager from "../../DrawingManager";
// import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { ResultPageProps } from "./ResultInfo";
import ProjectResultTitle from "./ProjectResultTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../modules/Index";
import { default as _ } from "lodash";

const OfficeSiteAnalysis = (props: ResultPageProps) => {
  const [mapManager] = useState(new MapManager());
  const { resultInfoData, currentProject } = useSelector(({ selectedProject }: RootState) => ({
    resultInfoData: selectedProject.result,
    currentProject: selectedProject.project,
  }));

  useEffect(() => {
    // 지도 그리기
    const point = DrawingManager.toGeom(resultInfoData!.project_site_center!, "Point").coordinates;
    const mapContainer = document.getElementById("mini-map");

    mapManager.createMap(mapContainer!, {
      center: { lat: point[1], lng: point[0] },
      zoom: 19, // project site area에 따라 zoom 변경
      scrollWheel: false,
      draggable: false,
    });

    const dm = new DrawingManager(mapManager.getMap()!);
    dm.addPolygon(DrawingManager.toGeom(resultInfoData!.project_site[0]!, "Field").coordinates, { ...DrawingManager.DrawingOption.PROJECT_SITE, simplify: false }, true);
    // console.log(resultInfoData!.project_site);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTableData = (data: any) => {
    let unit = "";

    if (data.unit) {
      unit = data.unit === "평" || data.unit === "㎡" ? props.unitState : data.unit;
    }
    if (unit === "평") {
      const val = Number(Number(Number(resultInfoData[data.key]) / (1 / 0.3025)).toFixed(2)).toLocaleString();
      return `${val}${props.unitState}`;
    } else if (unit === "㎡") {
      const val = Number(resultInfoData[data.key]).toFixed(2).toLocaleString();
      return `${val}${props.unitState}`;
    } else if (resultInfoData["customer"] && resultInfoData.customer["purchaseCondition"] && data.key === "purchaseCondition") {
      // 매입조건
      const condition = resultInfoData.customer.purchaseCondition.filter((item: any) => item.active === true);
      const result = _.map(condition, "name").join(", ");
      return result;
    }
    if (currentProject[data.key]) {
      return data.value(currentProject[data.key]);
    }
    if (data.unit) {
      return `${data.value(resultInfoData[data.key])}${unit}`;
    } else {
      return data.value(resultInfoData[data.key]);
    }
  };

  return (
    <section className="SiteAnalysis">
      <ProjectResultTitle></ProjectResultTitle>
      <div className="sub-title">2. 대지현황</div>
      <div className="content-wraper">
        <TableContainer>
          <Table size="small">
            <TableHead className="table-head">
              <TableRow className="tr ">
                <TableCell className="th tc-125" rowSpan={resultInfoData.주소목록.length}>
                  주소
                </TableCell>
                <TableCell className="td no-padding">{resultInfoData.주소목록 && resultInfoData.주소목록[0]}</TableCell>
              </TableRow>
              {resultInfoData.주소목록 &&
                resultInfoData.주소목록.map(
                  (address: string, index: number) =>
                    index > 0 && (
                      <TableRow>
                        <TableCell className="td no-padding">{address}</TableCell>
                      </TableRow>
                    )
                )}
            </TableHead>
            <TableBody>
              {site_analysis.map((data) => (
                <TableRow className="tr">
                  <TableCell className="th">{data.label === "대지 면적" && resultInfoData.주소목록 && resultInfoData.주소목록.length >= 2 ? "총 대지 면적" : data.label}</TableCell>
                  <TableCell className="td no-padding">{renderTableData(data)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          <div id="mini-map"></div>
        </div>
      </div>
    </section>
  );
};

export default OfficeSiteAnalysis;
