/* eslint-disable */
import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@material-ui/core";

import { floor_summary_office, unit_houshold } from "../../model/result";

const OfficeHouseholdsSummaryTable = (props: any) => {
  const houseTypeTable = props;

  // 합계
  const calSum = (mapData: any, model: any) => {
    let result = 0;
    if (props.unitState === "㎡") {
      result = mapData
        .map((tableData: any, index: number) => {
          return model.subKey && Number(tableData[model.subKey].toFixed(2));
        })
        .reduce((a: number, b: number) => a + b, 0);
    } else {
      result = mapData
        .map((tableData: any, index: number) => {
          return model.subKey && Number(Number(Number(tableData[model.subKey].toFixed(2)) / (1 / 0.3025)).toFixed(2)); // index < 5
        })
        .reduce((a: number, b: number) => a + b, 0);
    }

    return result === 0 ? 0 : result;
  };

  const convertArea = (v: number): string => {
    if (v.toString() === "-") return "-";

    if (props.unitState === "㎡") return Number(v).toFixed(2);

    return Number(Number(v) / (1 / 0.3025)).toFixed(2);
  };

  const renderCell = (model: any) => {
    const result = calSum(houseTypeTable.props, model);
    if (props.unitState === "㎡") {
      switch (model.subKey) {
        case "세대수":
          return result > 0 ? result.toFixed(0) + model.unit : "-";
          break;
        case "비율":
          return result > 0 ? Math.round(result) * 100 + model.unit : "-";
          break;
        default:
          return result > 0 ? result.toFixed(2) + props.unitState : "-";
          break;
      }
    } else {
      return result > 0 ? result.toFixed(2) + props.unitState : "-";
    }
  };

  const renderTableBody = (tableData: any, model: any) => {
    // console.log("renderTableBody - tableData", tableData);
    // console.log("renderTableBody - model", model);
    if (tableData[model.subKey]) {
      switch (model.subKey) {
        case "세대수":
          return (
            <TableCell align="left" className="td" key={model.subKey}>
              {tableData["세대수"].toFixed(0)}
              {model.unit}
            </TableCell>
          );
          break;
        case "비율":
          return (
            <TableCell align="left" className="td" key={model.subKey}>
              {convertArea(tableData[model.subKey] * 100)}
              {model.unit}
            </TableCell>
          );
          break;
        default:
          return (
            <TableCell align="left" className="td" key={model.subKey}>
              {convertArea(tableData[model.subKey].toFixed(2))}
              {model.unit}
            </TableCell>
          );
          break;
      }
    } else if (tableData[model.subKey] === 0) {
      return (
        <TableCell align="left" className="td" key={model.subKey}>
          {" - "}
        </TableCell>
      );
    }
  };

  return (
    <div>
      {
        <TableContainer>
          <Table size="small" className={`HouseholdSummary-table`}>
            <TableHead className="table-head">
              <TableRow className="tr">
                <TableCell className="th tc-125" align="left">
                  세대타입
                </TableCell>
                {unit_houshold.map(
                  (data, index: number) =>
                    index > 0 &&
                    data.subKey && (
                      <TableCell className="th tc-125" align="left" key={data.label + data.value}>
                        {data.label}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {houseTypeTable.props.length > 0 &&
                houseTypeTable.props.map((tableData: any) => (
                  <TableRow className="tr" key={tableData.세대타입}>
                    <TableCell className="td" align="left" width="100px">
                      {tableData.세대타입}
                    </TableCell>
                    {unit_houshold.map((model: any, index: number) => index > 0 && renderTableBody(tableData, model))}
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow className="tr">
                <TableCell className="th highlight" align="left" width="125">
                  합계
                </TableCell>
                {unit_houshold.map(
                  (model: any, index: number) =>
                    model.subKey &&
                    index > 0 && (
                      <TableCell className="th highlight" align="left" width="100px" key={`${model.subKey}합계`}>
                        {renderCell(model)}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      }
    </div>
  );
};

export default OfficeHouseholdsSummaryTable;
