
import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import project, { watchProject } from './Project';
import addressInfo, { watchAddressInfo } from './AddressInfo';
import fieldInfo, { watchFieldInfo } from './FieldInfo';
import priceInfo, { watchPriceInfo } from './PriceInfo';
import parcelInfo, { watchParcelInfo } from './ParcelInfo';
import projectDefault, { watchProjectDefault } from './ProjectDefault';
import jiguByArea, { watchJiguByArea } from './JiguByArea';
import projectErrors, { watchProjectErrors } from './ProjectErrors';
import selectedProject, { watchResultInfo } from './SelectedProject';
import priceInfoConfig from './PriceInfoConfig';
import informMessage from './InformMessage';
import auth, { watchAuth } from './Auth';
import loading from './loading';
import projectList, {watchProjectList} from './ProjectList';
import confirm from './Confirm';
import district, { watchDistrictInfo } from './District';
import inputcontrols from './InputControls';

const rootReducer = combineReducers({
  project,
  loading,
  addressInfo,
  fieldInfo,
  parcelInfo,
  projectDefault,
  jiguByArea,
  projectErrors,
  selectedProject,  
  projectList,
  priceInfo,
  priceInfoConfig,
  informMessage,
  auth,
  confirm,
  district,
  inputcontrols,
});

export function* rootSaga() {
  yield all([
    watchProject(),
    watchFieldInfo(),
    watchParcelInfo(),
    watchProjectDefault(),
    watchJiguByArea(),
    watchResultInfo(),
    watchProjectList(),
    watchPriceInfo(),    
    watchProjectErrors(),
    watchAuth(),
    watchDistrictInfo(),
    watchAddressInfo(),
  ]);
}

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;