import { createAction, handleActions } from 'redux-actions';

// import { RootState } from './Index';

const MOD_PRICE_CONFIG_LAND = 'MOD_PRICE_CONFIG_LAND';
const MOD_PRICE_CONFIG_HOUSEHOLD_SALES = 'MOD_PRICE_CONFIG_HOUSEHOLD_SALES';
const MOD_PRICE_CONFIG_HOUSEHOLD_RENT = 'MOD_PRICE_CONFIG_HOUSEHOLD_RENT';
const MOD_PRICE_CONFIG_EXTENDS = 'MOD_PRICE_CONFIG_EXTENDS';
const INITIALIZE_PRICE_INFO_CONFIG = 'INITIALIZE_PRICE_INFO_CONFIG';
const MOD_PRICE_CONFIG_SELECTED = 'MOD_PRICE_CONFIG_SELECTED';
const MOD_PRICE_CONFIG_ON_OFF = 'MOD_PRICE_CONFIG_ON_OFF';

export const modPriceConfigOnOff = createAction(MOD_PRICE_CONFIG_ON_OFF);
export const modPriceConfigLand = createAction(MOD_PRICE_CONFIG_LAND);
export const modPriceConfigHouseholdSales = createAction(MOD_PRICE_CONFIG_HOUSEHOLD_SALES);
export const modPriceConfigHouseholdRent = createAction(MOD_PRICE_CONFIG_HOUSEHOLD_RENT);
export const modPriceConfigSelected = createAction(MOD_PRICE_CONFIG_SELECTED);
export const modPriceConfigExtends = createAction(MOD_PRICE_CONFIG_EXTENDS);
export const initializePriceInfoConfig = createAction(INITIALIZE_PRICE_INFO_CONFIG);

export interface DefaultState {
  용도지역: number;
  거리: number;
  면적: number;
  거래일자: number;
  사용승인: number;
  도로접면: number;
}

interface DataState {
  토지매매: DefaultState;
  세대매매: DefaultState;
  세대임대: DefaultState;
  selected: string;
  exclusiveArea?: number;
  ONOFF: boolean;
}
const initialState: DataState = {
  토지매매: {
    용도지역: 1,
    거리: 1,
    면적: 0.3,
    거래일자: 1,
    사용승인: 0.5,
    도로접면: 0.8
  },
  세대매매: {
    용도지역: 1,
    거리: 1,
    면적: 0.8,
    거래일자: 1,
    사용승인: 0.5,
    도로접면: 0.5
  },
  세대임대: {
    용도지역: 1,
    거리: 1,
    면적: 0.8,
    거래일자: 1,
    사용승인: 0.5,
    도로접면: 0.5
  },
  selected: '토지매매',
  exclusiveArea: 20,
  ONOFF: false,
};
  
const priceInfoConfig = handleActions<DataState, any>(
  {
    [MOD_PRICE_CONFIG_LAND]: (state, { payload }) => ({
      ...state,
      토지매매: { 
        ...state.토지매매,
        ...payload,
      },
    }),
    [MOD_PRICE_CONFIG_HOUSEHOLD_SALES]: (state, { payload }) => ({
      ...state,
      세대매매: { 
        ...state.세대매매,
        ...payload,
      },
    }),
    [MOD_PRICE_CONFIG_HOUSEHOLD_RENT]: (state, { payload }) => ({
      ...state,
      세대임대: { 
        ...state.세대임대,
        ...payload,
      },
    }),
    [MOD_PRICE_CONFIG_EXTENDS]: (state, { payload }) => ({
      ...state,
      exclusiveArea: payload
    }),
    [MOD_PRICE_CONFIG_SELECTED]: (state, { payload }) => ({
      ...state,
      selected: payload
    }),
    [MOD_PRICE_CONFIG_ON_OFF]: (state, { payload }) => ({
      ...state,
      ONOFF: payload
    }),
    [INITIALIZE_PRICE_INFO_CONFIG]: () => initialState,
  },
  initialState,
);

export default priceInfoConfig;