import React from 'react';
import { useSelector, useDispatch  } from 'react-redux';
import { runProject } from '../../modules/Project';
import { RootState } from '../../modules/Index';
import { FlexContainer } from '../../styledComponents/layout'
import { RunButton } from '../../styledComponents/buttons';

export const RunComponent = () =>{
  
  const { canRunProject, pnuList, defaultloading, solverloading } = useSelector(
    ({ project, projectErrors, projectDefault }: RootState) => ({       
      pnuList: project.pnuList,
      canRunProject: projectErrors.canRunProject,    
      defaultloading: projectDefault.loading,  
      solverloading: project.solverloading,   
    }),
  );
  const runCurrentProject = () => {
    if(canRunProject && pnuList!.length > 0) dispatch(runProject());
  }
  const dispatch = useDispatch();

  return (
    <FlexContainer
      direction="column"
      width="100%"
      justifyContent="center"     
      height="auto"      
      style={{ borderTop: '1px solid #e9e9e9', paddingTop: '10px'}}     
    >    
      <RunButton runnable={canRunProject && !defaultloading && !solverloading} onClick={runCurrentProject}>사업성검토하기</RunButton>   
    </FlexContainer>
  )

}

export default RunComponent;