import React from "react";
import { FlexContainer } from "../../styledComponents/layout";

interface SelectedDistrictButtonTabProps {
  first: any;
  second: any;
  onClickBtn: (select: string) => void;
  active: string;
}

export const SelectedDistrictButtonTab = ({
  onClickBtn,
  ...props
}: SelectedDistrictButtonTabProps) => {
  return (
    <FlexContainer
      direction="row"
      width="auto"
      justifyContent="flex-end"
      height="40"
      style={{ margin: "10px 10px" }}
    >
      <button
        onClick={() => onClickBtn("first")}
        style={{
          fontSize: "12px",
          width: "auto",
          height: "24px",
          background: `${"first" === props.active ? "#00897B" : "#fff"}`,
          border: `1px solid #00897B`,
          color: `${"first" === props.active ? "#fff" : "#00897B"}`,
          borderRadius: "6px 0px 0px 6px",
          outline: "none",
        }}
      >
        {props.first}
      </button>
      <button
        onClick={() => onClickBtn("second")}
        style={{
          fontSize: "12px",
          width: "auto",
          height: "24px",
          background: `${"second" === props.active ? "#00897B" : "#fff"}`,
          border: `1px solid #00897B`,
          color: `${"second" === props.active ? "#fff" : "#00897B"}`,
          borderRadius: "0px 6px 6px 0px",
          outline: "none",
        }}
      >
        {props.second}
      </button>
    </FlexContainer>
  );
};
