import { Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React from 'react';
import { architecture_overview_overview, site_analysis_overview } from '../../model/result';
import { ResultPageProps } from './ResultInfo';
import ResultOverviewTable from './ResultOverviewTable';
// import { Undo } from '@material-ui/icons';
import { RootState } from '../../modules/Index';
import { useSelector } from 'react-redux';
import ProjectResultTitle from './ProjectResultTitle';
import { default as _ } from 'lodash';


const ResultOverview = (props: ResultPageProps) => {

  const { resultInfoData, currentProject } = useSelector(
    ({ selectedProject }: RootState) => ({
      resultInfoData: selectedProject.result,
      currentProject: selectedProject.project,
    }),
  );

  // 건축 개요
  const renderTableData = (data: any) => {
    if (data.unit === "㎡" || data.unit === "평") {
      return handleArea(data)
    }
    else if(resultInfoData['customer'] && resultInfoData.customer['purchaseCondition'] && data.key === "purchaseCondition") { // 매입조건
      const condition = resultInfoData.customer.purchaseCondition.filter((item: any) => item.active === true);     
      const result =  _.map(condition, 'name').join(', ');      
      return result
    }
    else if (resultInfoData[data.key]) {
      if (data.keys) {
        return data.keys.map((key: string) => resultInfoData[key]) + (data.unit ? data.unit : '');
      } else {
        return data.value(resultInfoData[data.key]) + (data.unit ? data.unit : '')
      }
    }    
    else {
      if (currentProject[data.key]) {
        return data.value(currentProject[data.key]);
      }
      return data.key ? data.value(resultInfoData[data.key]) + (data.unit ? data.unit : '') : '-';
    }
  }

  const handleArea = (data: any) => {
    if (data.keys) {
      if (data.keys) { // 연면적
        let val = data.keys.map((key: string) => resultInfoData[key] && resultInfoData[key])
        val = data.value(val)
        if (props.unitState === "평") {
          val = (val / (1 / 0.3025)).toFixed(2)
        }
        return val && `${val}${props.unitState}`;
      }
    }
    if (data.key === "housing_plan_type") { // 기준세대면적
      const value = resultInfoData.housing_plan_type[0].area
      if (props.unitState === "평") {
        return value ? `${Number(value / (1 / 0.3025)).toFixed(2)}${props.unitState}` : '-';
      }
      else {
        return value ? `${Number(value).toFixed(2)}${props.unitState}` : '-';
      }
    }   
    else { // 대지면적
      if (props.unitState === "평") {
        return resultInfoData[data.key] ? `${Number(data.value(resultInfoData[data.key] / (1 / 0.3025))).toFixed(2)}${props.unitState}` : '-';
      }
      // ㎡
      return resultInfoData[data.key] ? `${Number(data.value(resultInfoData[data.key])).toFixed(2)}${props.unitState}` : '-';
    }
  }

  return (
    <section className="ResultOverview">
      <ProjectResultTitle></ProjectResultTitle>
      <div className="sub-title" >1. 사업성 검토 요약</div>
      <div className="inner-section">
        <article>
          <div className="table-title">대지 현황</div>
          <TableContainer>
            <Table size="small">
              <TableRow className="tr table-head">
                <TableCell className="th table-key tc-125">주소</TableCell>
                <TableCell className="td no-padding">{resultInfoData.주소목록 && resultInfoData.주소목록.length >= 2 ? `${resultInfoData.project_address} 외 ${resultInfoData.주소목록.length - 1}필지` : resultInfoData.project_address}
                </TableCell>
              </TableRow>
              {
                site_analysis_overview.map((data) => (
                  <TableRow className="tr">
                    <TableCell className="th">{(data.label === "대지 면적" && resultInfoData.주소목록 && resultInfoData.주소목록.length >= 2) ? "총 대지 면적" : data.label}</TableCell>
                    <TableCell className="td no-padding">{
                      renderTableData(data)
                    }</TableCell>
                  </TableRow>
                ))
              }
            </Table>
          </TableContainer>
        </article>
        <article>
          <div className="table-title">건축 개요</div>
          <TableContainer>
            <Table size="small">
              {
                architecture_overview_overview.map((data, index) => (
                  index === 0 ?
                    <TableRow className="tr table-head">
                      <TableCell className="th tc-125">{data.label}</TableCell>
                      <TableCell className="td no-padding">{
                        renderTableData(data)
                      }</TableCell>
                    </TableRow>
                    :
                    <TableRow className="tr">
                      <TableCell className="th">{data.label}</TableCell>
                      <TableCell className="td no-padding">{
                        renderTableData(data)
                      }</TableCell>
                    </TableRow>
                ))
              }
            </Table>
          </TableContainer>
        </article>
      </div>
      <div className="inner-section">
        <article>
          <div className="table-title table-title-margin-top">사업성 검토</div>
          <ResultOverviewTable unitState={props.unitState!} title={'사업성 검토'}></ResultOverviewTable>
        </article>
        <article>
          <div className="table-title table-title-margin-top" >매입 평가 점수</div>
          <ResultOverviewTable unitState={props.unitState!} title={'매입 평가 점수'}></ResultOverviewTable>
        </article>
      </div>
    </section>
  );
};

export default ResultOverview;