import { createAction, handleActions } from 'redux-actions';
import  { createRequestActionTypes } from '../lib/createRequestSaga';
// import { default as _ } from 'lodash';

import { getJiguByAreaAPI } from '../api/JiguByAreaApi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { Project } from '../model/Project';
import { showErrorMessage } from './InformMessage';
import { sessionExpired } from './Auth';
export interface JiguByInfoData {
  url?: string;
  pnu?: string;
  wkt?: string;
  address?: string;
  jimog?: string;
  info?: any;  
}

type JiguByAreaState = {
  data: JiguByInfoData[];
};

const [
  GET_JIGU_BY_AREA,
  GET_JIGU_BY_AREA_SUCCESS,
  GET_JIGU_BY_AREA_FAILURE,
] = createRequestActionTypes('GET_JIGU_BY_AREA');

const INITIALIZE_JIGU_BY_AREA = 'INITIALIZE_JIGU_BY_AREA';
export const initializeJiguByArea = createAction(INITIALIZE_JIGU_BY_AREA);
export const getJiguByArea = createAction(GET_JIGU_BY_AREA);

function* getJiguByAreaSaga(action: any) {  
  try {
    const response = yield call(getJiguByAreaAPI, action.payload);
    
    const result = response.data.result;
    
    const recal = result.map((item: any) => { 
      return {
        url: item.url,
        pnu: item.pnu,
        wkt: item.geom,
        address: item['주소'],
        jimog: item['지목'],
        info: {
          '국토법': item['국토법'],
          '기타법': item['기타법'],
        }
      }
    });
    yield put({
      type: GET_JIGU_BY_AREA_SUCCESS,
      payload: recal,
      meta: response,
    });   
  } catch (e) {        
    if(e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    yield put(showErrorMessage({
      msg: "토지이용계획 정보 수신 실패",
      errorMessage: e.msg,
      autoClose: 3000,   
    }));
    yield put({
      type: GET_JIGU_BY_AREA_FAILURE,
      payload: e.msg,
    });
  }
}

// const getJiguByAreaSaga = createRequestSaga(GET_JIGU_BY_AREA, getJiguByAreaAPI);

export function* watchJiguByArea() {
  yield takeLatest(GET_JIGU_BY_AREA, getJiguByAreaSaga);
}

export interface ProjectData extends Project {  
}
  
const initialState: JiguByAreaState = {
  data: [],
};

const jiguByArea = handleActions<JiguByAreaState, any>(
  {    
    [GET_JIGU_BY_AREA_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
    [GET_JIGU_BY_AREA_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [INITIALIZE_JIGU_BY_AREA]: () => initialState,
  },
  initialState,
);

export default jiguByArea;