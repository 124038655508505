import React, { useEffect, useState } from 'react';

import { FlexContainer, HorizontalEmptyDivide } from '../../styledComponents/layout';
import { RowsKeyValue } from '../common/RowsKeyValue'
import { Button } from '@material-ui/core';
import { InfoTitleText } from '../../styledComponents/text'

export const InfoSection = ({...props}) => {
 
  const [open, setOpen] = useState(false);
  const [landUse, setLandUSe] = useState("");
  const [etcUse, setEtcUse] = useState("");
  // console.log('parcelData', props.parceldata)
  const showLandUse = () => {
    setOpen(!open);
  }

  useEffect(() => {
    if(open && props.jigudata) {
      const data1 = props.jigudata.info!['국토법']['포함'].length > 0 ? "[포함] ".concat(props.jigudata.info!['국토법']['포함'].join(", ")) : ""; 
      const data2 = props.jigudata.info!['국토법']['저촉'].length > 0 ? "[저촉] ".concat(props.jigudata.info!['국토법']['저촉'].join(", ")) : ""; 
      const data3 = props.jigudata.info!['국토법']['접함'].length > 0 ? "[접함] ".concat(props.jigudata.info!['국토법']['접함'].join(", ")) : ""; 
      setLandUSe(data1.concat(' ', data2, ' ', data3 ));
      const etc1 = props.jigudata.info!['기타법']['포함'].length > 0 ?  "[포함] ".concat(props.jigudata.info!['기타법']['포함'].join(", ")) : ""; 
      const etc2 = props.jigudata.info!['기타법']['저촉'].length > 0 ?  "[저촉] ".concat(props.jigudata.info!['기타법']['저촉'].join(", ")) : ""; 
      const etc3 = props.jigudata.info!['기타법']['접함'].length > 0 ?  "[접함] ".concat(props.jigudata.info!['기타법']['접함'].join(", ")) : ""; 
      setEtcUse(etc1.concat(' ', etc2, ' ', etc3 ));
    }
    
  }, [props.jigudata, open]);

  return (
    <div style={{ display: 'flex', width: '100%', height: 'auto' }}>
      {
      props.jigudata && props.parceldata  && (
        <FlexContainer
          direction="column"
          height="auto"
          width="98%"
          justifyContent="flex-start"          
        >
          <FlexContainer width="96%" height="60px" justifyContent="flex-start" alignItems="flex-start">
            <RowsKeyValue width="96%" keyName="주소" value={props.parceldata.주소}  />  
          </FlexContainer>
          
          <HorizontalEmptyDivide />
          <FlexContainer width="96%" height="60px" justifyContent="flex-start" alignItems="flex-start">
            <RowsKeyValue keyName="대지면적" value={Number(props.parceldata.토지면적).toFixed(2)} width="28%" unit="m2" />
            <RowsKeyValue keyName="지목" value={props.parceldata.지목} width="30%" />
            <div style={{ height: '28px', textAlign:'right', width:"42%"}}>
              <a href={props.jigudata.url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                <Button 
                  style={{              
                  border: '1px solid #00897B',
                  color: '#00897B', height: '28px'
                }}>
                  <span style={{ fontFamily: 'Roboto',fontSize: '12px' }}>토지이음 바로가기 &gt; </span>              
                </Button>
              </a>
            </div>
          </FlexContainer>          
          <FlexContainer  width="96%"  height="auto" alignItems="center">
            <RowsKeyValue keyName="용도지역" value={props.parceldata.용도지역} width="45%" />
            <Button
              style={{
                width: '45%',
                border: '1px solid #00897B',
                color: '#00897B',
              }}
              className="btn btn-primary btn-outline project-list-btn"
              onClick={() => showLandUse()}
            > 
            토지이용계획
            </Button>
          </FlexContainer>
          <HorizontalEmptyDivide />
          {
            open && (   
                     
              <FlexContainer width="96%" height="auto">
                <InfoTitleText width="98%">토지이용계획</InfoTitleText>
                <HorizontalEmptyDivide />
                <RowsKeyValue keyName="국토의 계획 및 이용에 관한 법률」에 따른 지역ㆍ지구등" value={landUse} width="100%" />
                <HorizontalEmptyDivide />
                <RowsKeyValue keyName="다른 법령 등에 따른 지역ㆍ지구등" value={etcUse} width="100%" />
                <HorizontalEmptyDivide />
              </FlexContainer>
            )
          }
        </FlexContainer>
      )
    }
    </div>
    
    
  )
}

export default InfoSection;