import { createAction, handleActions } from 'redux-actions';
import { getDistrictInfoAPI } from '../api/DistrictApi';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import { RootState } from './Index';
import { sessionExpired } from './Auth';
import { showWarningMessage } from './InformMessage';

const INITIALIZE_DISTRICT = 'INITIALIZE_DISTRICT';

const GET_DISTRICT_INFO = 'GET_DISTRICT_INFO';
const GET_DISTRICT_INFO_SUCCECSS = 'GET_DISTRICT_INFO_SUCCECSS';
const GET_DISTRICT_INFO_FAILED = 'GET_DISTRICT_INFO_FAILED';

export const getDistrictInfo = createAction(GET_DISTRICT_INFO);
export const initializeDistrict = createAction(INITIALIZE_DISTRICT);

function* getDistrictInfoSaga(action: any) {
  
  const address = yield select((state: RootState) => state.project.data.project_address);
  
  const address_split = address.toString().split(/(\s+)/).filter((e: any) => { return e.trim().length > 0; } );
  const first = address_split[0];
  const full = first.concat(' ', address_split[1]);
  try {
    const response = yield call(getDistrictInfoAPI, first, full);       
    yield put({
      type: GET_DISTRICT_INFO_SUCCECSS,
      payload: response.data,
      meta: response,    
    });    
  } catch (e) {
    console.log('e',e)
    if(e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    // console.log(e.msg)
    yield put(showWarningMessage({
      msg: "기초 매입 자료 불러오기에 실패했습니다.",
      errorMessage: e.msg,
      autoClose: 3000,   
    }));
    yield put({
      type: GET_DISTRICT_INFO_FAILED,
      payload: e.msg,
    });
  }
    
  
  
}
//solverloading
export function* watchDistrictInfo() {
  yield takeEvery(GET_DISTRICT_INFO, getDistrictInfoSaga);
}

interface DistrictInfoData {
  first: any;
  full: any;
}


interface DistrictInfoState { 
  data?: DistrictInfoData; 
  error?: string;

};

const initialState: DistrictInfoState = {
  data: undefined,
};

const district = handleActions<DistrictInfoState, any>(
  {
    [GET_DISTRICT_INFO_SUCCECSS]: (state, { payload }) => ({
      ...state,
      data: {
        first: payload.first,
        full: payload.full,
      }, 
    }),
    [GET_DISTRICT_INFO_FAILED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [INITIALIZE_DISTRICT]: () => initialState,
  },
  initialState,
);

export default district;