/*eslint-disable */
import { Project, BuildingType, ProjectUseDistrict, 프로젝트_기본값, SolverTypes, ProjectBuildingShapeType, BuildingShapeType, ProjectHousingPlanType } from '../../model/Project'
import { ProjectData } from '../Project';
// import { RunState } from '../ProjectDefault';
import { stage } from '../../App';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/InfoOutlined';

// import { takeLatest, put, select, call, takeEvery } from 'redux-saga/effects';
export type reset_type = "All" | "Value";

export const parseProjectDefaultParser = (currentProject: ProjectData, defaultData: any, set_buildingType?: BuildingType, set_use_distirct?: ProjectUseDistrict, reset?: reset_type) => {
  
  let buildingType: BuildingType | undefined;
  let resultState: any;
  switch (currentProject.project_type) {
    case "AI":
      if (set_buildingType) {
        buildingType = set_buildingType;
      } else {
        buildingType = currentProject.building_type;
      }
      
      break;
    case "DESIGNER":
    case "SITE_PLAN":
      buildingType = "아파트";
      break;
  }

  

  // 정북일조<9: 1.5 9미터 이하
  // 정북일조>9: 0.5 9미터 초과
  let 기본값_건물종류 = ["아파트", "다세대", "오피스텔"];
  let return_default = new Map<string, 프로젝트_기본값>();
  let canRun = false;

  const rr = defaultData;      // 프로젝트 기본값

  stage !=="prod" &&  console.log(rr);
  
  const d = rr;
  const dd = rr.result;
  for (let key of 기본값_건물종류) {
    if (dd[key]) {
      if(dd[key]["가능여부"] === null) dd[key]["가능여부"] = false;       // null 값이 들어오는 경우가 있어서 추가함. by hypark
      return_default.set(key, dd[key]);
      canRun = canRun || dd[key]["가능여부"];
    }
  }
  if (return_default.size < 1) {
    canRun = false;
  }
  // building type 결정 현재 솔버는 없어서 임시로 처리함
  let default_building_type: BuildingType = "아파트";
  let dr = dd['아파트'];
  
  if (currentProject.project_type === "AI") {
    if (set_buildingType) {
      default_building_type = set_buildingType;
      if (set_buildingType === "다세대주택") {
        dr = dd['다세대'];
      } else {
        dr = dd[set_buildingType];
      }
    } else {
      // 기본 값 자동 완성으로 건물 종류 결정
      if (!dd['아파트']['가능여부']) {
        if (dd['다세대']['가능여부']) {
          default_building_type = "다세대주택";
          dr = dd['다세대'];
        } else if (dd['오피스텔']['가능여부']) {
          default_building_type = "오피스텔"
          dr = dd['오피스텔'];
        } else {
          if (!dd['아파트']['허용여부']) {
            if (dd['다세대']['허용여부']) {
              default_building_type = "다세대주택";
              dr = dd['다세대'];
            } else if (dd['오피스텔']['허용여부']) {
              default_building_type = "오피스텔"
              dr = dd['오피스텔'];
            }
          }
        }
      }
    }
  } else if (currentProject.project_type === "DESIGNER" || currentProject.project_type === "SITE_PLAN") {
    default_building_type = "아파트";
    dr = dd['아파트'];
  } else {
    default_building_type = "아파트";
    dr = dd['아파트'];
  }

  switch (reset) {        // TODO : initDefault 에 관한 질문.
    // case "All":
    //   this.setState({initDefault: {용도지역: d['용도지역'], 건물종류: default_building_type, 층고: 2.8, ...dr}});
    //   break;
    // case "Value":
    //   this.setState({initDefault: {용도지역: this.state.initDefault!.용도지역, 건물종류: this.state.initDefault!.건물종류, 층고: 2.8, ...dr}});
    //   break;
    // case undefined:
    //   // do nothing
    //   break;
    // default:
    //   // do nothing
    //   break;
  }
  
  const buildingStoriesValue = [
    dr['최고층수'],
    dr['최저층수'],
    dr['평균층수'],
  ];
  buildingStoriesValue.sort((a: number, b: number) => a - b);

  let defaults: Project = {};

  if (!canRun) {
    defaults = {
      building_type: undefined,
      project_use_district: undefined,
      building_shape_type: [],
      housing_plan_type: [],
      my_building_shape_type: [],
      //solver_type: dr['솔버']
    }
    // doNothing
  } else if (currentProject.project_type! === "AI") {
    defaults = {
      distance_between_side_opaque_walls: dr['인동간격_벽면_측벽'],
      distance_between_side_walls: dr['인동간격_측벽_측벽'],
      distance_between_window_opaque_walls: dr['인동간격_채광창_다른건물'],
      setback_regulation_from_site_boundary: dr['채광사선_채광창_인접대지경계선'],
      setback_regulation_from_north_less_9m: dr['정북일조_9m이하'],
      setback_regulation_from_north_less_9m_type: "METER",
      setback_regulation_from_north_more_9m: dr['정북일조_9m초과'],
      setback_regulation_from_north_more_9m_type: "HEIGHT",

      building_land_ratio: dr['건폐율'],
      floor_area_ratio: dr['용적률'],
      building_type: default_building_type,
      project_use_district: d['용도지역'],
      building_stories_max: buildingStoriesValue[2],
      building_stories_min: buildingStoriesValue[0],
      building_stories_avg: buildingStoriesValue[1],
      building_stories_avg_type: "NUMERICAL",

      setback_building_line_apartment: dr['대지안의_공지_건축선후퇴'],
      setback_building_line_multi_house: dr['대지안의_공지_건축선후퇴'],
      setback_building_line_row_house: dr['대지안의_공지_건축선후퇴'],
      setback_building_line_city_apartment: dr['대지안의_공지_건축선후퇴'],
      setback_building_line_city_row_house: dr['대지안의_공지_건축선후퇴'],
      setback_building_line_city_multi_house: dr['대지안의_공지_건축선후퇴'],
      setback_building_line_officetel: dr['대지안의_공지_건축선후퇴'],

      setback_site_boundary_apartment: dr['대지안의_공지_인접대지경계선후퇴'],
      setback_site_boundary_multi_house: dr['대지안의_공지_인접대지경계선후퇴'],
      setback_site_boundary_row_house: dr['대지안의_공지_인접대지경계선후퇴'],
      setback_site_boundary_city_apartment: dr['대지안의_공지_인접대지경계선후퇴'],
      setback_site_boundary_city_row_house: dr['대지안의_공지_인접대지경계선후퇴'],
      setback_site_boundary_city_multi_house: dr['대지안의_공지_인접대지경계선후퇴'],
      setback_site_boundary_officetel: dr['대지안의_공지_인접대지경계선후퇴'],
      housing_plan_type: housingPlanConverter(['19']),
      building_shape_type: buildingShapeConverter(dr['동타입_기본값']),
      my_building_shape_type: [],
      //solver_type: dr['솔버'],
      housing_rate: housingPlanConverter(dr['세대타입_기본값']).map(r => r.proportion!).reduce((a, b) => a + b, 0),
      floor_height: 2.8,
    }
  } else {
    //if (buildingTypeConverter(dr['건물종류'], dr['솔버']) === "아파트") {
      if (dd['아파트']) {
      defaults = {
        distance_between_side_opaque_walls: dr['인동간격_벽면_측벽'],
        distance_between_side_walls: dr['인동간격_측벽_측벽'],
        distance_between_window_opaque_walls: dr['인동간격_채광창_다른건물'],
        setback_regulation_from_site_boundary: dr['채광사선_채광창_인접대지경계선'],
        setback_regulation_from_north_less_9m: dr['정북일조_9m이하'],
        setback_regulation_from_north_less_9m_type: "METER",
        setback_regulation_from_north_more_9m: dr['정북일조_9m초과'],
        setback_regulation_from_north_more_9m_type: "HEIGHT",

        building_land_ratio: dr['건폐율'],
        floor_area_ratio: dr['용적률'],
        building_type: default_building_type,
        project_use_district: d['용도지역'],
        building_stories_max: buildingStoriesValue[2],
        building_stories_min: buildingStoriesValue[0],
        building_stories_avg: buildingStoriesValue[1],
        building_stories_avg_type: "NUMERICAL",

        setback_building_line_apartment: dr['대지안의_공지_건축선후퇴'],
        setback_building_line_multi_house: dr['대지안의_공지_건축선후퇴'],
        setback_building_line_row_house: dr['대지안의_공지_건축선후퇴'],
        setback_building_line_city_apartment: dr['대지안의_공지_건축선후퇴'],
        setback_building_line_city_row_house: dr['대지안의_공지_건축선후퇴'],
        setback_building_line_city_multi_house: dr['대지안의_공지_건축선후퇴'],
        setback_building_line_officetel: dr['대지안의_공지_건축선후퇴'],

        setback_site_boundary_apartment: dr['대지안의_공지_인접대지경계선후퇴'],
        setback_site_boundary_multi_house: dr['대지안의_공지_인접대지경계선후퇴'],
        setback_site_boundary_row_house: dr['대지안의_공지_인접대지경계선후퇴'],
        setback_site_boundary_city_apartment: dr['대지안의_공지_인접대지경계선후퇴'],
        setback_site_boundary_city_row_house: dr['대지안의_공지_인접대지경계선후퇴'],
        setback_site_boundary_city_multi_house: dr['대지안의_공지_인접대지경계선후퇴'],
        setback_site_boundary_officetel: dr['대지안의_공지_인접대지경계선후퇴'],
        building_shape_type: buildingShapeConverter(dr['동타입_기본값']),
        housing_plan_type: housingPlanConverter(dr['세대타입_기본값']),
        my_building_shape_type: [],
        //solver_type: dr['솔버'],
        housing_rate: housingPlanConverter(dr['세대타입_기본값']).map(r => r.proportion!).reduce((a, b) => a + b, 0),
        floor_height: 2.8,
      }
    } else {
      defaults = {
        building_type: "아파트",
        project_use_district: d['용도지역'],
        building_stories_avg_type: "NUMERICAL",
        building_shape_type: [],
        housing_plan_type: [],
        my_building_shape_type: [],
        //solver_type: dr['솔버']
      }
    }
  }

  // console.log('기본값', defaults);
  
  if (canRun) {
    resultState = {
      projectDefault: defaults,
      buildingDefault: return_default,
      canRunProject: canRun,
      openSnack: currentProject.auto_setting!,
      snackMsg: <div><CheckIcon className="icon m-r-sm" style={{color: "#95e4b3"}} />현재 대지영역에 적합한 값으로 설정 되었습니다.</div>
    };
    // , () => {
    //   if (this.state.currentProject.auto_setting) {
    //     this.onUpdateProject({ ...defaults })
    //   }
    // });
  } else {
    resultState = { 
      projectDefault: {} as Project,
      buildingDefault: return_default,
      canRunProject: canRun,
      openSnack: currentProject.auto_setting!,
      snackMsg: <div><InfoIcon className="icon m-r-sm" style={{color: "#E81C1C"}} />"상세 설정"의 직접 설정이 필요합니다.</div>
    }
    // , () => {
    //   if (this.state.currentProject.auto_setting) {
    //     this.onUpdateProject({ ...defaults })
    //   }
    // });
    // console.log(rr.reason);
  }
    
  return {    
    resultState: resultState,
  }

  function officetelHeight (blr: number, far: number) {
    return Math.floor(far / blr);
  }

  // 현재는 솔버타입으로 역연산 (아파트 - 중규모)
  function buildingTypeConverter (type: string, solverType: SolverTypes) {
    
    let buildingType: BuildingType | undefined;
    switch (type) {
      case "아파트": 
        switch (solverType) {
          case "대규모": buildingType = "아파트"; break;
          // case "중규모": buildingType = "연립주택"; break;
          case "중규모": buildingType = "미정"; break;
        }
        break;
      // case "연립": buildingType = "연립주택"; break;
      case "다세대": buildingType = "다세대주택"; break;
      // case "도시형원룸": buildingType = "도시형생활주택 - 원룸"; break;
      // case "도시형연립": buildingType = "도시형생활주택 - 연립주택"; break;
      case "도시형다세대": buildingType = "도시형생활주택 - 다세대주택"; break;
      case "오피스텔":  buildingType = "오피스텔"; break;
      default: buildingType = "미정";
    }
    return buildingType;
  }
  function buildingShapeConverter (type: string[]) {
    const r = type.filter((b: any) => {
      return !(b === '선택불가' || b === '-') ;
    })
    const bs = r.map(rr => {
      return new ProjectBuildingShapeType(typeConverter(rr))
    })

    return bs;

    function typeConverter (type: string) {
      let figures: BuildingShapeType = "LINEAR_CORRIDOR_TYPE";

      const house_number = type.slice(2) ? Number(type.slice(2)) : 0;
      switch (type.slice(0, 2)) {
        case "LC":
          figures = "LINEAR_NON_CORRIDOR_TYPE";
          break;
        case "RC":
          figures = "FOLDED_NON_CORRIDOR_TYPE";
          break;
        case "TT":
          figures = "T_TOWER_TYPE";
          break;
        case "TY":
          figures = "Y_TOWER_TYPE";
          break;
        case "LH":
          figures = "LINEAR_CORRIDOR_TYPE";
          break;
        case "RH":
          figures = "FOLDED_CORRIDOR_TYPE";
          break;
      }

      return {
        "figures": figures,
        "houses_number": house_number,
      }
      
      // if (type === "복도식") {
      //   return {
      //     figures: "LINEAR_CORRIDOR_TYPE",
      //     houses_number: 4, 
      //   }
      // } else {
      //   const houses = Number(type.split('호')[0]);
      //   const rType = type.split('호')[1];
      //   let buildingShapeType: BuildingShapeType = "FOLDED_CORRIDOR_TYPE";
      //   switch (rType) {
      //     case "판상": buildingShapeType = "LINEAR_NON_CORRIDOR_TYPE"; break;
      //     case "ㄱ판상": buildingShapeType = "FOLDED_NON_CORRIDOR_TYPE"; break;
      //     case "T탑상": buildingShapeType = "T_TOWER_TYPE"; break;
      //     case "Y탑상": buildingShapeType = "Y_TOWER_TYPE"; break;
      //   }

      //   return {
      //     figures: buildingShapeType,
      //     houses_number: houses
      //   }
      // }
      return {
            figures: "LINEAR_CORRIDOR_TYPE",
            houses_number: 4, 
          }
    }
  }

  function housingPlanConverter (type: string[]) {
    // console.log('housingPlanConverter type', type)
    const r = type.filter((h: any) => {
      return h!== "-";
    })
    const length = r.length;
    const hp = r.map(rr => {
      return new ProjectHousingPlanType({
        area: Number(rr),
        proportion: Number((100 / length).toFixed(0)),
      })
    })

    if (hp.length > 0 && (100 % length) > 0) {
      let remain = 100 % 3;
      for (let i = 0; remain; remain--) {
        hp[i].proportion!++;
      }
    }
    // console.log('housingPlanConverter hp', hp)
    return hp;
  }
}