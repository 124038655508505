import axios from 'axios';

const APIURL =  '/api';

export const generateRoadLineAPI = (project_site?: string[]) => {
  return axios.post(`${APIURL}/roadline/generator`, {
    project_site: project_site,
  });
}

