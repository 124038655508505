import moment from "moment";

// import { mapValues } from "lodash"
// import { Project } from "./Project"

import { numberWithCommas } from "../utils/Format";

// 1. 사업성 검토 요약

/* 1-1) 대지 현황 */
interface resultInfo {
  value: Function;
  key: string;
  keys?: Array<any>;
  label: string;
  unit?: string;
  isPlural?: boolean; // 합필 여부
}

export const site_analysis_overview: Array<resultInfo> = [
  {
    value: (v: Number) => v && Number(v).toFixed(2).toLocaleString(),
    key: "project_site_area",
    label: "대지 면적",
    unit: "㎡",
  },
  {
    value: (v: string) => v,
    key: "building_type",
    label: "건축 용도",
  },
  {
    value: (v: string) => v,
    key: "project_use_district",
    label: "용도 지역",
  },
  {
    value: (v: string) => (v ? v : "-"),
    key: "purchaseCondition",
    label: "매입 조건",
  },
  {
    value: (v: string) => (v ? moment(v).format("YYYY-MM-DD") : "-"),
    key: "접수날짜",
    label: "접수 일자",
  },
  {
    value: (v: string) => (v ? moment(v).format("YYYY-MM-DD") : "-"),
    key: "검토날짜",
    label: "검토 일자",
    //    unit: 'm',
  },
];

/* 1-2) 건축 개요 - 다세대 */
export const architecture_overview_overview: Array<resultInfo> = [
  {
    value: (value: number) => value,
    key: "loc_total_household",
    label: "세대수",
    unit: "세대",
  },
  {
    value: (value: string) => value,
    key: "building_type",
    label: "건축 용도",
    unit: "",
  },
  {
    value: (value: Array<number>) => Number((value[0]! * value[1]) / 100).toFixed(2),
    keys: ["project_site_area", "loc_floor_area_ratio"],
    key: "total_area",
    label: "연면적",
    unit: "㎡",
  },
  {
    value: (value: number) => value.toFixed(2),
    key: "loc_floor_area_ratio",
    label: "용적률",
    unit: "%",
  },
  {
    value: (v: number) => (v ? v : "-"),
    key: "housing_plan_type",
    label: "기준 세대면적",
    unit: "㎡",
  },
  {
    value: (value: Array<any>) => value.length,
    key: "householdAreaTable",
    label: "층수",
    unit: "층",
  },
  {
    value: (value: number) => value,
    key: "parkingNumber",
    label: "주차 대수",
    unit: "대",
  },
];

/* 1-2) 건축 개요 - 오피스텔 솔버 전용 */
export const architecture_overview_overview_office: Array<resultInfo> = [
  {
    value: (value: number) => value,
    key: "loc_total_household",
    label: "세대수",
    unit: "세대",
  },
  {
    value: (value: string) => value,
    key: "building_type",
    label: "건축 용도",
    unit: "",
  },
  {
    value: (value: Array<number>) => Number((value[0]! * value[1]) / 100).toFixed(2),
    keys: ["project_site_area", "loc_floor_area_ratio"],
    key: "total_area",
    label: "연면적",
    unit: "㎡",
  },
  {
    value: (value: number) => value.toFixed(2),
    key: "loc_floor_area_ratio",
    label: "용적률",
    unit: "%",
  },
  {
    value: (v: number) => (v ? v : "-"),
    key: "housing_plan_type",
    label: "기준 세대면적",
    unit: "㎡",
  },
  {
    value: (value: number) => value,
    key: "parkingNumber",
    label: "주차 대수",
    unit: "대",
  },
];

//  2. 대지 현황
interface SiteAnalysis {
  value: Function;
  key: string;
  label: string;
  unit?: string;
}

export const site_analysis: Array<SiteAnalysis> = [
  {
    value: (v: Number) => v && Number(v).toFixed(2).toLocaleString(),
    key: "project_site_area",
    label: "대지 면적", // 합필: 총 대지 면적
    unit: "㎡",
  },
  {
    value: (v: string) => v,
    key: "project_use_district",
    label: "용도지역",
  },
  {
    value: (v: string) => (v ? v : "-"),
    key: "jiguInfo",
    label: "토지이용계획 정보",
  },
  {
    value: (v: string) => v,
    key: "project_jimog",
    label: "지목",
  },
  {
    value: (v: Number) => v && Number(v.toFixed(2)).toLocaleString(),
    key: "building_land_ratio",
    label: "건폐율",
    unit: "%",
  },
  {
    value: (v: Number) => v && Number(v.toFixed(2)).toLocaleString(),
    key: "floor_area_ratio",
    label: "용적률",
    unit: "%",
  },
  {
    value: (v: string) => (v ? v : "-"),
    key: "purchaseCondition",
    label: "매입 조건",
  },
  {
    value: (v: string) => (v ? moment(v).format("YYYY-MM-DD") : "-"),
    key: "접수날짜",
    label: "접수 일자",
  },
  {
    value: (v: string) => (v ? moment(v).format("YYYY-MM-DD") : "-"),
    key: "검토날짜",
    label: "검토 일자",
  },
];

// 3. 건축개요
interface ArchitectureOverview {
  value: Function;
  key: any;
  keys?: Array<any>;
  label: string;
  unit: string;
}
/* 3-1) 건축 개요 - 다세대 (기존) */
export const architecture_overview: Array<ArchitectureOverview> = [
  {
    value: (value: Number) => Number(Number(value).toFixed(2)).toLocaleString(),
    key: "project_site_area",
    label: "대지 면적",
    unit: "㎡",
  },
  {
    value: (value: string) => value,
    key: "project_use_district",
    label: "용도 지역",
    unit: "",
  },
  {
    value: (value: string) => value,
    key: "building_type",
    label: "건축 용도",
    unit: "",
  },
  {
    value: (value: Array<number>) => Number((value[0] * value[1]!) / 100).toFixed(2),
    keys: ["loc_building_land_ratio", "project_site_area"],
    key: "building_area",
    label: "건축 면적",
    unit: "㎡",
  },
  {
    value: (value: Array<number>) => Number((value[0]! * value[1]) / 100).toFixed(2),
    keys: ["project_site_area", "loc_floor_area_ratio"],
    key: "total_area",
    label: "연면적",
    unit: "㎡",
  },
  {
    value: (value: number) => value && Number(value).toFixed(2),
    key: "realTotalArea",
    label: "공사용 연면적",
    unit: "㎡",
  },
  {
    value: (value: number) => value.toFixed(2),
    key: "loc_building_land_ratio",
    label: "건폐율",
    unit: "%",
  },
  {
    value: (value: number) => value.toFixed(2),
    key: "loc_floor_area_ratio",
    label: "용적률",
    unit: "%",
  },
  {
    value: (value: number) => value.toFixed(2),
    key: "realFloorAreaRatio",
    label: "실용적률",
    unit: "%",
  },
  {
    value: (value: number) => (value ? value : "-"),
    key: "landscapeArea",
    label: "조경 면적",
    unit: "㎡",
  },
  {
    value: (value: number) => value,
    key: "loc_total_household",
    label: "세대수",
    unit: "세대",
  },
  {
    value: (value: Array<any>) => value.length,
    key: "householdAreaTable",
    label: "층수",
    unit: "층",
  },
  {
    value: (value: number) => value,
    key: "parkingNumber",
    label: "주차대수",
    unit: "대",
  },
  {
    value: (value: number) => value,
    key: "elevatorNumber",
    label: "승강기",
    unit: "대",
  },
  // {
  //   value: (value: number) => Number(Number(value / 1000).toFixed(0)).toLocaleString(),
  //   key: 'loc_construction_cost',
  //   label: "추정 공사비",
  //   unit: '(천)원',
  // },
];
/* 3-2) 건축 개요 - 오피스텔 솔버 전용 */
export const architecture_overview_office: Array<ArchitectureOverview> = [
  {
    value: (value: Number) => Number(Number(value).toFixed(2)).toLocaleString(),
    key: "project_site_area",
    label: "대지 면적",
    unit: "㎡",
  },
  {
    value: (value: string) => value,
    key: "project_use_district",
    label: "용도 지역",
    unit: "",
  },
  {
    value: (value: string) => value,
    key: "building_type",
    label: "건축 용도",
    unit: "",
  },
  {
    value: (value: Array<number>) => Number((value[0] * value[1]!) / 100).toFixed(2),
    keys: ["loc_building_land_ratio", "project_site_area"],
    key: "building_area",
    label: "건축 면적",
    unit: "㎡",
  },
  {
    value: (value: Array<number>) => Number((value[0]! * value[1]) / 100).toFixed(2),
    keys: ["project_site_area", "loc_floor_area_ratio"],
    key: "total_area",
    label: "연면적",
    unit: "㎡",
  },
  {
    value: (value: number) => value.toFixed(2),
    key: "loc_building_land_ratio",
    label: "건폐율",
    unit: "%",
  },
  {
    value: (value: number) => value.toFixed(2),
    key: "loc_floor_area_ratio",
    label: "용적률",
    unit: "%",
  },
  {
    value: (value: number) => value,
    key: "loc_total_household",
    label: "세대수",
    unit: "세대",
  },
  {
    value: (value: number) => value,
    key: "parkingNumber",
    label: "주차대수",
    unit: "대",
  },
];

// 5. 층별 개요
interface FloorSummary {
  value: Function;
  key: string;
  label: string;
  subKey?: string;
  unit?: string;
}

/* 5-1. 층별 개요 다세대주택(기존) */
export const floor_summary: Array<FloorSummary> = [
  {
    value: (v: string) => v,
    key: "name",
    label: "층",
    unit: "층",
  },
  {
    value: (v: string) => (v ? v : "-"),
    key: "mainUsage",
    label: "주용도",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "areaInfoSum",
    label: "전용 면적",
    subKey: "exclusiveArea",
    unit: "㎡",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "areaInfoSum",
    label: "공용 면적",
    subKey: "commonArea",
    unit: "㎡",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "areaInfoSum",
    label: "분양 면적",
    subKey: "salesArea",
    unit: "㎡",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "areaInfoSum",
    label: "서비스 면적",
    subKey: "serviceArea",
    unit: "㎡",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "areaInfoSum",
    label: "공사용 면적",
    subKey: "constructionArea",
    unit: "㎡",
  },
  {
    value: (v: number) => (v > 0 ? v : "-"),
    key: "households",
    label: "세대수",
    unit: "세대",
  },
];
/* 5-2. 층별 개요 오피스텔 솔버 전용 */
export const floor_summary_office: Array<FloorSummary> = [
  {
    value: (v: string) => v,
    key: "name",
    label: "층",
    unit: "층",
  },
  {
    value: (v: string) => (v ? v : "-"),
    key: "mainUsage",
    label: "주용도",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "areaInfoSum",
    label: "전용 면적",
    subKey: "exclusiveArea",
    unit: "㎡",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "areaInfoSum",
    label: "공용 면적",
    subKey: "commonArea",
    unit: "㎡",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "areaInfoSum",
    label: "분양 면적",
    subKey: "salesArea",
    unit: "㎡",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "areaInfoSum",
    label: "공사용 면적",
    subKey: "constructionArea",
    unit: "㎡",
  },
  {
    value: (v: number) => (v > 0 ? v : "-"),
    key: "households",
    label: "세대수",
    unit: "세대",
  },
];

/* 5-3. 단위세대 면적표 개요 - 오피스텔 솔버 전용 */
export const unit_houshold: Array<FloorSummary> = [
  // 세대타입, 세대수, 비율, 전용면적, 공용면적, 분양면적
  {
    value: (v: string) => v,
    key: "세대타입",
    label: "세대타입",
    subKey: "세대타입",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "세대수",
    label: "세대수",
    unit: "세대",
    subKey: "세대수",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "비율",
    label: "비율",
    unit: "%",
    subKey: "비율",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "전용면적",
    label: "전용면적",
    subKey: "전용면적",
    unit: "㎡",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "공용면적",
    label: "공용면적",
    subKey: "공용면적",
    unit: "㎡",
  },
  {
    value: (v: number) => (v > 0 ? v.toFixed(2) : "-"),
    key: "분양면적",
    label: "분양면적",
    subKey: "분양면적",
    unit: "㎡",
  },
];

// 6. 사업성 검토
export interface BusinessFeasibilityType {
  value: Function;
  key: string;
  label: string;
  subKey?: string;
  unit?: string;
}

// 6-1) 호별 수익계산
export const business_feasibility: Array<BusinessFeasibilityType> = [
  {
    value: (v: number) => (v ? numberWithCommas(Math.round(v)) : "-"),
    key: "매매가_평균",
    label: "예상매매수익",
    unit: "만원",
  },
  {
    value: (v: number) => (v ? numberWithCommas(Math.round(v)) : "-"),
    key: "임대가_평균",
    label: "예상임대수익",
    unit: "만원",
  },
];

// 6-2) 사업성 평가
export const business_evaluation: Array<BusinessFeasibilityType> = [
  {
    value: (v: number) => (v ? v.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-"),
    key: "",
    label: "예상매입비용",
    unit: "원",
  },
  {
    value: (value: Array<number>) => value[0] * value[1] * value[2],
    key: "",
    label: "예상공사비용",
    unit: "원",
  },
  {
    value: (value: Array<number>) => value[0] + value[1],
    key: "",
    label: "소계",
    unit: "원",
  },
  {
    value: (v: number) => (v ? v.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-"),
    key: "",
    label: "예상매매수익",
    unit: "원",
  },
  {
    value: (v: Array<number>) => v[0] - (v[1] + v[2]),
    key: "",
    label: "예상 순수익",
    unit: "원",
  },
];

// 7. 매입평가점수
export interface PurchaseScoreType {
  value: Function;
  key: string;
  label: string;
  subKey?: string;
  unit?: string;
}

export const purchase_score: Array<PurchaseScoreType> = [
  {
    value: (v: number) => v,
    key: "",
    label: "임대관리용이성",
    unit: "",
  },
  {
    value: (v: number) => v,
    key: "",
    label: "임대가능성", // 사용자 입력 가능
    unit: "",
  },
  {
    value: (v: number) => v,
    key: "",
    label: "접면도로",
    unit: "",
  },
  {
    value: (v: number) => v,
    key: "",
    label: "토지의 형상 및 레벨",
    unit: "",
  },
  {
    value: (v: number) => v,
    key: "",
    label: "주변건물 이격거리",
    unit: "",
  },
  {
    value: (v: number) => v,
    key: "",
    label: "대중교통 접근성",
    unit: "",
  },
  {
    value: (v: number) => v,
    key: "",
    label: "대학교 접근성",
    unit: "",
  },
  {
    value: (v: number) => v,
    key: "",
    label: "생활편익시설",
    unit: "",
  },
  {
    value: (v: number) => v,
    key: "",
    label: "용도지역",
    unit: "",
  },
];
