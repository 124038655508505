import axios from 'axios';

const APIURL =  '/api';

export const getJiguByAreaAPI = (project_site?: string[]) => {
  console.log('getJiguByAreaAPI', project_site)
  return axios.post(`${APIURL}/JiguByArea`, {
    project_site: project_site!,
    ratio: 80,
  });
}

