import React from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { RootState } from '../../modules/Index';
import { FlexContainer, HorizontalEmptyDivide } from '../../styledComponents/layout';
import { SectionTitleText } from '../../styledComponents/text';
import { DoneIconButton } from '../common/DoneIconButton';
import { setPurchaseCondition } from '../../modules/Project';

export const PurchaseConditionSection = () => {

  const { purchaseCondition } = useSelector(
    ({ project }: RootState) => ({      
      purchaseCondition: project.data.customer.purchaseCondition,
    }),
  ); 
  const dispatch = useDispatch();
  const onClickBtn = (e: any) => {
    console.log(e);
    dispatch(setPurchaseCondition(purchaseCondition.map((item: any) => { 
      if(item.name === e.name) return { ...item, active: !item.active }
      return item;
    })));
  }

  return (
    <FlexContainer direction="column" height="auto"  width="100%">
    {    
        <FlexContainer
          direction="column"
          height="auto"
          width="100%"
          justifyContent="flex-start"          
        > 
          <HorizontalEmptyDivide />
          <FlexContainer width="90%" height="auto" justifyContent="space-between" alignItems="center">
            <SectionTitleText width="34%" align="left" padding="0px">매입 조건 선택</SectionTitleText>
          </FlexContainer>
          <HorizontalEmptyDivide height="15px"/>
          <FlexContainer            
            height="auto"
            width="90%"
            justifyContent="flex-start"          
          > 
            {purchaseCondition.map((item: any) => {
              return <DoneIconButton key={`iconbtn_${item.name}`} onClick={onClickBtn} active={item.active} name={item.name}></DoneIconButton>
            })}
          </FlexContainer>
        </FlexContainer>

    
    }
    </FlexContainer>
   
  )
}


export default PurchaseConditionSection;