import React, { useState } from 'react';
import { useSelector  } from 'react-redux';
import { RootState } from '../../modules/Index';

import { numberWithCommas } from '../../utils/Format'
import { FlexContainer, HorizontalEmptyDivide } from '../../styledComponents/layout';
import { RowsKeyHilightValue } from '../common/RowsKeyHilightValue';
import { SectionTitleText, SectionSubTitleText } from '../../styledComponents/text';
import { PopulationChart } from '../chart/PopulationChart';
import { SelectedDistrictButtonTab } from './SelectedDistrictButtonTab'
import { BuildingTypeChart } from '../chart/BuildingTypeChart'

export const BasicDataSection = () => {

  const { districtData } = useSelector(
    ({ district }: RootState) => ({      
      districtData: district.data,    
    }),
  );
  const [selectedDistrict, setSelectedDistrict] = useState("first");   
  
  const onClickBtn = (select: string) => {
    setSelectedDistrict(select);
  }

  return (
    <FlexContainer direction="column" height="auto"  width="100%">
    {
      districtData && (
        <FlexContainer
          direction="column"
          height="auto"
          width="100%"
          justifyContent="flex-start"          
        > 
          <HorizontalEmptyDivide />
          <FlexContainer width="90%" height="auto" justifyContent="space-between" alignItems="center">
            <SectionTitleText width="34%" align="left" padding="0px">매입 기초 자료</SectionTitleText>
          </FlexContainer>
          <HorizontalEmptyDivide height="15px"/>
          <SectionSubTitleText width="90%" align="left" padding="0px">총 인구 수</SectionSubTitleText>
          <HorizontalEmptyDivide />
          <FlexContainer width="90%" height="auto" justifyContent="flex-start" alignItems="center">
            <RowsKeyHilightValue width="50%" 
              keyName={districtData!.first.주소}
              value={numberWithCommas(Math.round(Number(districtData!.first.총인구수) * 0.0001))}
              unit="만" 
            /> 
            <RowsKeyHilightValue width="50%" 
              keyName={districtData.full.주소}
              value={numberWithCommas(Math.round(Number(districtData.full.총인구수) * 0.0001))}
              unit="만" 
            />
            
          </FlexContainer>
          <HorizontalEmptyDivide height="15px"/>
          <SectionSubTitleText width="90%" align="left" padding="0px">인구 비율</SectionSubTitleText>
          <HorizontalEmptyDivide />
          <FlexContainer width="100%" height="auto" justifyContent="flex-start" alignItems="center">
            <PopulationChart
              data={districtData!.first}
            />
            <PopulationChart
              data={districtData!.full}
            />
          </FlexContainer>
          <HorizontalEmptyDivide height="15px"/>
          <SectionSubTitleText width="90%" align="left" padding="0px">총 가구 수</SectionSubTitleText>
            
          <HorizontalEmptyDivide />
          <FlexContainer width="90%" height="auto" justifyContent="flex-start" alignItems="center">
            <RowsKeyHilightValue width="50%" 
              keyName={districtData!.first.주소}
              value={numberWithCommas(Math.round(Number(districtData.first.가구수) * 0.0001))}
              unit="만" 
            /> 
            <RowsKeyHilightValue width="50%" 
              keyName={districtData.full.주소}
              value={numberWithCommas(Math.round(Number(districtData.full.가구수) * 0.0001))}
              unit="만" 
            />
          </FlexContainer>
          <HorizontalEmptyDivide height="15px" />
          <FlexContainer width="90%" height="40px" justifyContent="space-between" alignItems="center">
            <SectionSubTitleText width="30%" align="left" padding="0px">공동 주택 유형</SectionSubTitleText>  
            <SelectedDistrictButtonTab
              first={districtData.first.주소}
              second={(districtData.full.주소 === districtData.first.주소)? districtData.full.주소: districtData.full.주소.replace(districtData.first.주소, '').trim()}
              active={selectedDistrict}
              onClickBtn={onClickBtn}
            />
          </FlexContainer>
          <HorizontalEmptyDivide />
          <FlexContainer width="90%" height="auto" justifyContent="flex-start" alignItems="center">
            <BuildingTypeChart 
              data={(selectedDistrict === 'first') ? districtData.first : districtData.full}
            />
          </FlexContainer>
        </FlexContainer>

      )
    }
    </FlexContainer>
   
  )
}


export default BasicDataSection;