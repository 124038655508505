
import { createAction, handleActions } from 'redux-actions';
import { takeLatest, call, put, take, race, select, getContext } from 'redux-saga/effects';
import { showInfoMessage, showErrorMessage } from './InformMessage';
import  { createRequestActionTypes } from '../lib/createRequestSaga';
import { loginAPI, logoutAPI, sessionCheckAPI } from '../api/AuthApi';
import { RootState } from './Index';
import { initializeAddressInfo } from './AddressInfo';
import { initializeInformMessage } from './InformMessage';
import { initializeJiguByArea } from './JiguByArea';
import { initializeParcelInfo } from './ParcelInfo';
import { initializePriceInfo } from './PriceInfo';
import { initializePriceInfoConfig } from './PriceInfoConfig';
import { initializeProject } from './Project';
import { initializeProjectDefault } from './ProjectDefault';
import { initializeFieldInfo } from './FieldInfo';
import { initializeProjectErrors } from './ProjectErrors';
import { initializeProjectList } from './ProjectList';
import { initializeResultInfo } from './SelectedProject';
import { setConfirmType, OK_CONFIRM, CANCEL_CONFIRM, initializeConfirm } from './Confirm'

const [
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
] = createRequestActionTypes('LOGIN');

const [
  LOGOUT,
  LOGOUT_SUCCESS,  
  LOGOUT_FAILURE,
] = createRequestActionTypes('LOGOUT');

const SESSION_CHECK = 'SESSION_CHECK';
const SESSION_EXPIRED = 'SESSION_EXPIRED';
const SET_SESSION_SUCCESS = 'SET_SESSION_SUCCESS';
export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';

export const login = createAction(LOGIN);
export const logout = createAction(LOGOUT);
export const sessionExpired = createAction(SESSION_EXPIRED);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const logoutSuccess = createAction(LOGOUT_SUCCESS);
export const setSessionSuccess = createAction(SET_SESSION_SUCCESS);

export const loginFailure = createAction(LOGIN_FAILURE);
export const sessionCheck = createAction(SESSION_CHECK);
export const initializeAuth = createAction(INITIALIZE_AUTH);

function* sessionCheckSaga(action: any) {
  try {
    console.log('action', action)
    const response = yield call(sessionCheckAPI);       
    const data = response.data;
  
    if(data.success && data.id) yield put(setSessionSuccess( { 
      id: data.id,
      success: true,
    }))
    
  } catch (e) {       
    if(e.response.status === 440) {
      yield put(sessionExpired({
        when: action.payload.when
      }));     
      
    }
    console.log('fail',e.msg);    
  }
}

function* sessionExpiredSaga(action: any) {
  const logged = yield select((state: RootState) => state.auth.logged); 
  yield put(initializeAddressInfo());
  yield put(initializeAuth());
  yield put(initializeFieldInfo());
  yield put(initializeInformMessage());
  yield put(initializeJiguByArea());
  yield put(initializeParcelInfo());
  yield put(initializePriceInfo());
  yield put(initializePriceInfoConfig());
  yield put(initializeProject());
  yield put(initializeProjectDefault());
  yield put(initializeProjectErrors ());
  yield put(initializeProjectList());
  yield put(initializeResultInfo());
  localStorage.removeItem('sessionID');
  if (!logged && !window.location.pathname.includes("login")) {
    const history = yield getContext('history');
    // localStorage.removeItem('sessionID');
    history.push("/login");     
  }  
  if(action.payload.when !== 'first') {
    yield put(showInfoMessage({
      msg: "로그인 지속 시간이 초과하였습니다. 다시 로그인해주세요",
      autoClose: 3000,   
      width: 370,
    }));
  }
}

function* loginFailureSaga(action: any) {
  yield put(showErrorMessage({
    msg: `로그인 실패 : ${action.payload.message}`,
    errorMessage: action.payload.message,
    autoClose: 3000,   
    width: 400,
  }));
}

function* loginSaga(action: any) {
  const userid = action.payload.userid;
  const password = action.payload.password;

  try {
    const response = yield call(loginAPI, userid, password);       
    if(response.data.success) {
      // console.log('login_success')
      const history = yield getContext('history');
      // console.log('history check')
      yield put({
        type: LOGIN_SUCCESS,
        payload: response.data,       
      }); 
      // console.log('login_success action')
      localStorage.setItem('sessionID', userid);
      yield put(showInfoMessage({
        msg: "LH MAP PLATFORM에 오신 걸 환영합니다",
        autoClose: 3000,   
        width: 370,
      }));
      // console.log('storage set')
      yield history.push({pathname: "/"});        
      // console.log('history set')
    } else {
      yield put({
        type: LOGIN_FAILURE,
        payload: response.data,
        meta: response,    
      });    
    }
  } catch (e) {    
    console.log(e.msg);    
    yield put({
      type: LOGIN_FAILURE,
      payload: e.msg,
      meta: e,
    });
  }
}

function* logoutSaga(action: any) {
  try {
    yield put(initializeConfirm());   // 먼저 생성된 다이얼로그가 먼저 떠 있는 경우, 초기화를 실행하기 위해  
    yield put(setConfirmType({
      title: '로그아웃',
      children: '정말로 로그아웃하시겠습니까?'
    }));
    const { yes } = yield race({
      yes: take(OK_CONFIRM),
      no: take(CANCEL_CONFIRM)
    });
    if(yes) {
      const response = yield call(logoutAPI);  
      localStorage.removeItem('sessionID');     
      if(response.data.success) {
        const history = yield getContext('history');      
        yield put({
          type: LOGOUT_SUCCESS,
          payload: response.data,
          meta: response,    
        });       
            
        yield put(initializeAddressInfo());
        yield put(initializeAuth());
        yield put(initializeFieldInfo());
        yield put(initializeInformMessage());
        yield put(initializeJiguByArea());
        yield put(initializeParcelInfo());
        yield put(initializePriceInfo());
        yield put(initializePriceInfoConfig());
        yield put(initializeProject());
        yield put(initializeProjectDefault());
        yield put(initializeProjectErrors ());
        yield put(initializeProjectList());
        yield put(initializeResultInfo());      
        yield history.push({pathname: "/login"}); 
      } else {
        yield put({
          type: LOGOUT_FAILURE,
          payload: response.data,
          meta: response,    
        });    
      }
    }
  } catch (e) {    
    console.log(e.msg);    
    yield put({
      type: LOGOUT_FAILURE,
      payload: e.msg,
      meta: e,
    });
  }
}

export function* watchAuth() {
  yield takeLatest(login, loginSaga);
  yield takeLatest(loginFailure, loginFailureSaga);  
  yield takeLatest(logout, logoutSaga);
  
  yield takeLatest(sessionExpired, sessionExpiredSaga);
  yield takeLatest(sessionCheck, sessionCheckSaga);
}
  
const initialState: any = {  
  loading: false,
  logged: false,
  session: undefined,  
  
};

const auth = handleActions<any, any>(
  {  
    [LOGIN]: (state, { payload }) => ({
      ...state,
      loading: true,     
    }),
    [LOGIN_SUCCESS]: (state, { payload }) => ({
      ...state,
      logged: true,
      loading: false,   
      session: {
        id: payload.userid,        
        sessionLoaded: payload.success,
      }
    }),
    [LOGIN_FAILURE]: (state, { payload }) => ({
      ...state,
      logged: false,   
      loading: false,        
    }),
    [LOGOUT_SUCCESS]: (state, { payload }) => ({
      ...state,
      logged: false,
      session: undefined,
    }),
    [SET_SESSION_SUCCESS]: (state, { payload }) => ({
      ...state,     
      session: {
        id: payload.id,        
        sessionLoaded: payload.success,
      }
    }),
    // [LOGOUT_FAILURE]: (state, { payload }) => ({
    //   ...state,      
     
    // }),
    [SESSION_EXPIRED]: (state, { payload }) => ({
      ...state,
      logged: false,  
      session: undefined,
    }),
    [INITIALIZE_AUTH]: () => initialState,
  },
  initialState,
);

export default auth;