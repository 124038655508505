import React from 'react';
import { useSelector, useDispatch  } from 'react-redux';
import { RootState } from '../../modules/Index';
import { modifyProject } from '../../modules/Project';
import { FlexContainer, HorizontalEmptyDivide } from '../../styledComponents/layout';
import { RowsKeyValue } from '../common/RowsKeyValue';

import { RowsKeyInputCalendar } from '../common/input/RowsKeyInputCalendar';

export const ExtraInfoSection = () => {

  const { projectData } = useSelector(
    ({ project }: RootState) => ({
      projectData: project.data,     
    }),
  );
  
  const dispatch = useDispatch();
 

  const onChangeDate = (date: any, value?: any) => {
    dispatch(modifyProject({
      접수날짜: date,
    }));
  }

  return (
    <FlexContainer
      direction="column"
      height="auto"
      width="100%"
      justifyContent="space-between"          
    >
      <HorizontalEmptyDivide />
      <FlexContainer width="90%" height="auto" justifyContent="space-between" alignItems="flex-start">
        <RowsKeyValue width="50%" 
          keyName="총 대지 면적(m²)"
          value={ projectData!.project_site_area!.toFixed(1).toString()}
          unit="m2" />  
        <RowsKeyInputCalendar
          keyName="접수날짜"
          selectedDate={projectData.접수날짜 || new Date()}                   
          width="50%"
          onChangeDate={onChangeDate}          
        />
      </FlexContainer>               
    </FlexContainer>
  )
}


export default ExtraInfoSection;