import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../modules/Index";
import "../../css/ResultInfo.scss";

import SiteAnalysis from "./SiteAnalysis";
import ArchitectureOverview from "./ArchitectureOverview";
import FloorDivision from "./FloorDivision";
import BusinessFeasibility from "./BusinessFeasibility";
import ResultOverview from "./ResultOverview";
import FloorHouseholdSummaryWrapper from "./FloorHouseholdSummaryWrapper";

import OfficeSiteAnalysis from "./OfficeSiteAnalysis";
import OfficeArchitectureOverview from "./OfficeArchitectureOverview";
import OfficeFloorDivision from "./OfficeFloorDivision";
import OfficeBusinessFeasibility from "./OfficeBusinessFeasibility";
import OfficeResultOverview from "./OfficeResultOverview";
import OfficeFloorHouseholdSummaryWrapper from "./OfficeFloorHouseholdSummaryWrapper";

export interface ResultPageProps {
  open?: boolean;
  setUnitState?: Function;
  unitState?: string; // ㎡ | 평
  address?: string;
}

const ResultInfo = () => {
  const componentRef = useRef(null);
  const [unitState, setUnitState] = useState("㎡");
  // const [open, setOpen] = useState(true);
  const convertUnit = () => {
    // 단위 변환
    if (unitState === "㎡") {
      setUnitState("평");
    } else {
      setUnitState("㎡");
    }
  };
  const { address, buildingType } = useSelector(({ selectedProject }: RootState) => ({
    address: selectedProject.project.주소목록,
    buildingType: selectedProject.project.건물타입,
  }));
  // const toggleAddressList = () => { // 프린트 직전 컴포넌트 변경
  //   return new Promise((resolve, reject) => {
  //     setOpen(false)
  //     resolve('v');
  //   })
  // }
  return (
    <>
      <ReactToPrint
        // onBeforeGetContent={toggleAddressList}
        // onAfterPrint={() => setOpen(true)}
        trigger={() => <Button className="btn btn-primary btn-outline print-btn">인쇄</Button>}
        content={() => componentRef.current}
        documentTitle={address && address.length === 1 ? `${address[0]}` : `${address[0]}외 ${address.length - 1}필지`}
      />
      <div className="ResultInfo">
        <div className="unit-btns">
          <button className={`btn btn-primary btn-outline btn-unit ${unitState === "㎡" ? "active" : ""}`} onClick={convertUnit}>
            ㎡
          </button>
          <button className={`btn btn-primary btn-outline btn-unit ${unitState === "평" ? "active" : ""}`} onClick={convertUnit}>
            평
          </button>
        </div>
        {buildingType === "오피스텔" ? (
          <div className="print-wrap" ref={componentRef}>
            <OfficeResultOverview unitState={unitState}></OfficeResultOverview>
            <OfficeSiteAnalysis unitState={unitState} setUnitState={setUnitState}></OfficeSiteAnalysis>
            <OfficeArchitectureOverview unitState={unitState} setUnitState={setUnitState}></OfficeArchitectureOverview>
            <OfficeFloorDivision></OfficeFloorDivision>
            <OfficeFloorHouseholdSummaryWrapper unitState={unitState} setUnitState={setUnitState}></OfficeFloorHouseholdSummaryWrapper>
            <OfficeBusinessFeasibility unitState={unitState}></OfficeBusinessFeasibility>
          </div>
        ) : (
          <div className="print-wrap" ref={componentRef}>
            <ResultOverview unitState={unitState}></ResultOverview>
            <SiteAnalysis unitState={unitState} setUnitState={setUnitState}></SiteAnalysis>
            <ArchitectureOverview unitState={unitState} setUnitState={setUnitState}></ArchitectureOverview>
            <FloorDivision></FloorDivision>
            <FloorHouseholdSummaryWrapper unitState={unitState} setUnitState={setUnitState}></FloorHouseholdSummaryWrapper>
            <BusinessFeasibility unitState={unitState}></BusinessFeasibility>
          </div>
        )}
      </div>
    </>
  );
};
export default ResultInfo;
