import React  from 'react';
import { PieChart, Pie, Label, Cell } from 'recharts';
import { default as _ } from 'lodash';
import { FlexContainer } from '../../styledComponents/layout';
import { LegendCircleText } from '../common/LegendCircleText';

// const data = [
//   { name: '청년 인구 비율', value: 400 },
//   { name: '고령자 인구 비율', value: 300 },
//   { name: '그 외 인구 비율', value: 300 },  
// ];
const COLORS = {
  '청년 인구 비율': '#8FDCFF',
  '고령자 인구 비율': '#5494FF',
  '그 외 인구 비율': '#00BFA5'
}

const getChartData = (data: any) => {
  let result = [];
  let sum = 0;
  for (const [key, value] of Object.entries(data)) {
    switch (key) {
      case '청년비율':
        result.push({ name: '청년 인구 비율', value: Number.parseInt(Number(value).toFixed(2)) });
        sum += Number.parseInt(Number(value).toFixed(2));
        break;
      case '고령비율':
        result.push({ name: '고령자 인구 비율', value: Number.parseInt(Number(value).toFixed(2)) });
        sum += Number.parseInt(Number(value).toFixed(2));
        break;
    }
  }
  result.push({ name: '그 외 인구 비율', value: Number(100 - sum) });
  return result;
}

const CustomLabel = ({ ...props }) => {  
  return (    
    <text x={props.cx} y={props.cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central" >
      <tspan alignmentBaseline="middle" fontSize={props.size} style={{ textAlign: 'center', fontWeight: 700, fill: `${props.color ? props.color : '#808080'}` }}>{props.value}</tspan>      
    </text>
  )
}

export const PopulationChart = ({...props}) => {
  const { data } = props;
  const chartData = getChartData(data);  
  return (
    <FlexContainer direction="column" width="200" height="300" justifyContent="flex-start" alignItems="center">
      <PieChart width={200} height={200}>
        <Pie
          data={chartData}
          innerRadius={75}
          outerRadius={90}
          fill="#8884d8"          
          dataKey="value"          
        >
          <Label position="center" cx={100} cy={85} content={<CustomLabel value={data.주소} size="14" />}>
          </Label>
          <Label position="center" cx={100} cy={105} content={<CustomLabel value="총 인구수" size="14" />}>
          </Label>
          <Label position="center" cx={100} cy={125} 
            content={<CustomLabel value={Math.round(Number(data.총인구수 * 0.0001)).toString().concat('만')} size="16" color="#00897B" />}>
          </Label>
          
          {chartData.map((item: any, index: Number) => (
            <Cell key={`cell-${index}`} fill={_.get(COLORS, item.name)}/>
          ))}
        </Pie>      
      </PieChart>
      <FlexContainer direction="column" width="80%" height="300" justifyContent="flex-start" alignItems="center">
        {chartData.map((item: any, index: Number) => (
          <LegendCircleText key={`${item.name}_${index}`} width="100%" text={`${item.name}  ${Math.round(item.value)}%`} color={_.get(COLORS, item.name)} />
        ))}
      </FlexContainer>
    </FlexContainer>
  )
}
  

 