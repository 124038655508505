

import axios from 'axios';
const APIURL = '/api';

export const getDistrictInfoAPI = (firstDistrict: string, fullDistrict: string) => {
  return axios.post(`${APIURL}/GetDistrictInfo`, {
    firstDistrict,
    fullDistrict,
  });
}
