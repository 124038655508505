/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FlexContainer } from '../../styledComponents/layout'
import { LegendCircle, LegendPlain } from '../../styledComponents/text';

interface CircleTextProps {
  color: string;
  width: string;
  text: string;
}

export const LegendCircleText = ({ ...props }: CircleTextProps) => {
  
  return (
    <FlexContainer     
      width={props.width}
      height="auto"
      justifyContent="space-between"
    >
      <LegendCircle color={props.color} />
      <LegendPlain>{props.text}</LegendPlain>
    </FlexContainer>
  )
}

