/* eslint-disable */
import React, { useEffect, useState } from "react";
import { FlexContainer, KeyValueContainer } from "../../../styledComponents/layout";
import { KeyValueTitle } from "../../../styledComponents/text";
import { TextField, InputAdornment } from "@material-ui/core";
import { HelpQuestion } from "../HelpQuestion";
import { useDispatch } from "react-redux";
import { setMainScrollable } from "../../../modules/InputControls";
import { DottedBoxContainer, BoxValue, BoxUnit } from "../../../styledComponents/info";

interface RowsKeyNumInputValueProps {
  keyName: string;
  value: number | string | undefined;
  width: string;
  inputName: string;
  onChange?: (event: any) => void;
  unit?: string;
  helperText?: string;
  disabled?: boolean;
  inputProps?: any;
  error?: any;
  boxWidth?: string;
  readonly?: boolean;
  disableUnderline?: boolean;
  help?: string;
}

export const RowsKeyNumInputValue = ({ onChange, ...props }: RowsKeyNumInputValueProps) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setMainScrollable({ scrollable: false }));
  };

  return (
    <KeyValueContainer width={props.width} height="auto">
      <FlexContainer justifyContent="space-between" width={props.boxWidth ? props.boxWidth : "100%"} padding="10px 20px 0px 0px">
        <KeyValueTitle>{props.keyName}</KeyValueTitle>
        {props.help && <HelpQuestion helpString={props.help} id={props.inputName} />}
      </FlexContainer>
      <FlexContainer direction="row" justifyContent="flex-start" width="100%">
        {props.readonly && (
          <DottedBoxContainer>
            <BoxValue>{props.value}</BoxValue>
            {props.unit && <BoxUnit>{props.unit}</BoxUnit>}
          </DottedBoxContainer>
        )}
        {!props.readonly && (
          <TextField
            type="number"
            name={props.inputName}
            value={props.value}
            onChange={onChange}
            onClick={handleClick}
            // onBlur={(event) => dispatch(setMainScrollable({ scrollable: true }))}
            onMouseDown={(event) => dispatch(setMainScrollable({ scrollable: false }))}
            onMouseLeave={(event) => dispatch(setMainScrollable({ scrollable: true }))}
            helperText={props.helperText || ""}
            disabled={props.disabled ? true : false}
            error={props.error}
            InputProps={{
              disableUnderline: props.disableUnderline || props.readonly,
              endAdornment: props.unit ? (
                <InputAdornment position="end" style={{ marginLeft: "2px" }}>
                  {props.unit}
                </InputAdornment>
              ) : (
                <span />
              ),
            }}
            inputProps={{
              min: 0,
              ...props.inputProps,
              style: { textAlign: "right", padding: "0px", margin: "0px" },
            }}
            style={{
              width: props.boxWidth || "98%",
              borderBottom: `1px solid #808080`,
            }}
          />
        )}
      </FlexContainer>
    </KeyValueContainer>
  );
};
