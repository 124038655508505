import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PlaceResult, AddressResult, setMapCenter } from "../../modules/AddressInfo";
import { getFieldInfoAtPoint } from "../../modules/FieldInfo";
import Highlighter from "react-highlight-words";
import { RootState } from "../../modules/Index";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import "../../css/AddressSearchSection.scss";
// import { default as _ } from 'lodash';
// import { time } from 'console';
import { NCoordinate } from "../../MapManager";
import { searchAddress } from "../../modules/AddressInfo";
import DrawingManager from "../../DrawingManager";
import proj4 from "proj4";

export default function AddressSearchSection() {
  const [searchAddressStr, setSearchAddressStr] = useState("");

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [displayList, setDisplayList] = useState(false);
  const [onMouse, setOnMouse] = useState(false);
  const addressWrapperRef = useRef<HTMLDivElement>();

  // const [selectIndex, setSelectedIndex] = useState(0);
  const dispatch = useDispatch();
  const { data, result } = useSelector(({ addressInfo }: RootState) => ({
    data: addressInfo.data,
    result: addressInfo.result,
    // loading: addressInfo.loading,
  }));

  useEffect(() => {
    const trimmedAddress = searchAddressStr.trim().replace(/ +/g, " ");
    // console.log("searchAddressStr", searchAddressStr);
    // console.log("trimmedAddress", trimmedAddress);
    let debouncer = setTimeout(() => {
      if (trimmedAddress.length > 0) {
        dispatch(
          searchAddress({
            address: trimmedAddress,
          })
        );
      }
    }, 200);
    return () => {
      clearTimeout(debouncer);
    };

    /* 
      if (trimmedAddress.length > 0) {
        const timer = setTimeout(() => { 
          // setDisplayList(true); 
        
          
          // setSelectedItemIndex(0);
        }, 200);
        return () => { clearTimeout(timer); }
      } else {
        setSelectedItemIndex(0);
        setAddressInfo([]);
      }
      eslint-disable-next-line react-hooks/exhaustive-deps
    */
  }, [searchAddressStr]);

  useEffect(() => {
    // console.log("a");
    if (result && result!.datalength! > 0) {
      // console.log("display", result);
      setDisplayList(true);
      setSelectedItemIndex(0);
    }
  }, [result]);

  // const searchAddress = () => {
  //   naver.maps.Service.geocode({
  //     query: searchAddressStr.trim()
  //   }, (status: any, res: any) => {
  //     const address: Array<AddressInfo> = res.v2.addresses.map((r: any) => {
  //       const landIndex = r.addressElements.findIndex((e: any) => e.types.includes('LAND_NUMBER'));
  //       let isLand = false;
  //       if (landIndex > -1) {
  //         const ae = r.addressElements[landIndex];
  //         isLand = (ae.longName && ae.longName.length > 0) || (ae.shortName && ae.shortName.length > 0);
  //       }
  //       return {
  //         roadAddress: r.roadAddress,
  //         jibunAddress: r.jibunAddress,
  //         coord: { lat: Number(r.y), lng: Number(r.x) },
  //         isLand: isLand
  //       }
  //     })
  //     setAddressInfo(address);
  //   })
  // }

  // const selectAddress = (index: number = selectedItemIndex) => {
  //   if (addressInfo.length > 0) {
  //     const ai = addressInfo[index];
  //     const coord = ai.coord;

  //     // console.log('selected ai', ai);
  //     /*
  //      * coord로 지도 이동하는 부분 추가
  //      * isLand인 경우는 필지 정보 받아와서 project로 설정까지
  //      */
  //     setDisplayList(false);
  //     dispatch(selectedAddressInfo(ai))
  //     if (ai.isLand) {
  //       getFieldInfo(coord);
  //     }
  //   }
  // }

  const moveToAddress = (lat: number, lng: number) => {
    dispatch(
      setMapCenter({
        lat,
        lng,
      })
    );
  };

  const selectPlace = (item: PlaceResult) => {
    setDisplayList(false);
    moveToAddress(Number(item.y), Number(item.x));
    if (item.address_type === "REGION" || item.address_type === "ROAD" || (item.address_type === "KEYWORD" && item.road_address.length === 0)) {
      // dispatch(initializeAddressInfo());
      setSelectedItemIndex(undefined);
    } else {
      getFieldInfo({ lat: Number(item.y), lng: Number(item.x) });
    }
  };

  const selectAddress = (item: AddressResult) => {
    setDisplayList(false);
    const isMultiPolygon = Boolean(item.geom.match("MULTIPOLYGON"));
    const path = DrawingManager.toGeom(item.geom, isMultiPolygon ? "Field" : "Polygon").coordinates[0];
    let sumLat = 0;
    let sumLng = 0;
    for (let i = 0; i < path.length; i++) {
      sumLat += path[i][1];
      sumLng += path[i][0];
    }
    moveToAddress(sumLat, sumLng);
    getFieldInfo({ lat: sumLat / path.length, lng: sumLng / path.length });
  };

  useEffect(() => {
    // console.log('selected Store Data', data);
  }, [data]);

  // MapContent의 getfieldInfo와 같음
  const getFieldInfo = (coord: NCoordinate) => {
    const coordr = proj4(DrawingManager.nProj, DrawingManager.bProj, [coord.lng, coord.lat]);
    dispatch(
      getFieldInfoAtPoint({
        x: coordr[0],
        y: coordr[1],
      })
    );
  };

  const selectAddressResult = (index: number) => {
    let findResult = undefined;
    if (index > result.placeResults.length) {
      findResult = result.addressResults[index - result.placeResults.length];
    } else {
      findResult = result.placeResults[index];
    }
    if (findResult && findResult.hasOwnProperty("address_type")) {
      selectPlace(findResult);
    } else if (findResult) {
      selectAddress(findResult);
    }
    setSelectedItemIndex(0);
  };

  return (
    <div className="AddressSearchSection" style={{ marginBottom: "20px" }}>
      <TextField
        className="address-input"
        placeholder="주소 검색"
        variant="outlined"
        value={searchAddressStr}
        onBlur={(e) => {
          if (!onMouse) setDisplayList(false);
          else setOnMouse(false);
        }}
        onChange={(e) => {
          setSearchAddressStr(e.target.value);
        }}
        onKeyDown={(e: any) => {
          switch (e.keyCode) {
            case 40: {
              // down
              const nextIndex = selectedItemIndex + 1 < result!.datalength! ? selectedItemIndex + 1 : selectedItemIndex;
              if (displayList && selectedItemIndex > 7 && addressWrapperRef.current.scrollTop < addressWrapperRef.current.scrollHeight - 500) {
                addressWrapperRef.current.scroll({ top: addressWrapperRef.current.scrollTop + 44 });
              }
              setSelectedItemIndex(nextIndex);
              break;
            }
            case 38: {
              // up
              const nextIndex = selectedItemIndex - 1 < 0 ? selectedItemIndex : selectedItemIndex - 1;
              if (displayList && selectedItemIndex > 7 && addressWrapperRef.current.scrollTop <= addressWrapperRef.current.scrollHeight - 500) {
                addressWrapperRef.current.scroll({ top: addressWrapperRef.current.scrollTop - 44 });
              }
              setSelectedItemIndex(nextIndex);
              break;
            }
            case 13: {
              // enter
              if (!displayList) setDisplayList(true);
              else if (result!.datalength! > 0) {
                selectAddressResult(selectedItemIndex);
              }
              break;
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {displayList && (
        <div className="address-wrapper" onMouseEnter={(e) => setOnMouse(true)} onMouseLeave={(e) => setOnMouse(false)} ref={addressWrapperRef}>
          {result!.placeResults!.map((p, i) => {
            return (
              <div
                className={`item place ${selectedItemIndex === i ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  selectPlace(p);
                }}
              >
                <div className="body">
                  <div className="name">
                    <Highlighter highlightClassName="highlight" searchWords={searchAddressStr.trim().replace(/ +/g, " ").split(" ")} textToHighlight={p.name} autoEscape={true} />
                  </div>
                  <div className="address">{p.jibun_address}</div>
                </div>
                {p.address_type === "KEYWORD" && <div className="keyword">{p.category_name!.split(">").pop()!.toString().split(",").pop()}</div>}
              </div>
            );
          })}
          {result.addressResults.map((p, i) => {
            return (
              <div
                className={`item address ${selectedItemIndex === i + result.placeResults!.length ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  selectAddress(p);
                }}
              >
                <div className="body">
                  <div className="name">
                    <Highlighter highlightClassName="highlight" searchWords={searchAddressStr.trim().replace(/ +/g, " ").split(" ")} textToHighlight={p.address} autoEscape={true} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
