import { createAction, handleActions } from "redux-actions";
import { stage } from "../App";
import { createRequestActionTypes } from "../lib/createRequestSaga";
import { default as _ } from "lodash";
import { RootState } from "./Index";
import { createProjectAPI, saveProjectAPI, runSolverAPI } from "../api/ProjectApi";
import { generateRoadLineAPI } from "../api/GeneratorApi";
import { getJiguByArea, initializeJiguByArea } from "./JiguByArea";
import { getParcelPnu, initializeParcelInfo } from "./ParcelInfo";
import { getProjectDefault, initializeProjectDefault } from "./ProjectDefault";
import { initializeFieldInfo } from "./FieldInfo";
import { showSuccessMessage, showErrorMessage, showWarningMessage } from "./InformMessage";
import { setDefaultErrors, initializeProjectDefaultErrors, initializeProjectErrors, setRoadGeneratorErrors, setRoadGeneratorLoading } from "./ProjectErrors";
import { put, select, call, takeEvery, retry } from "redux-saga/effects";
import { Project } from "../model/Project";
import { lhmapInputValidCheck } from "../Constraints";
import { sessionExpired } from "./Auth";

type ProjectState = {
  data: ProjectData;
  pnuList: Array<string>;
  saveResult?: any;
  genRoad?: any;
  solverloading: boolean;
  error?: string;
};

const [CREATE_PROJECT, CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAILURE] = createRequestActionTypes("CREATE_PROJECT");

const [SAVE_PROJECT, SAVE_PROJECT_SUCCESS, SAVE_PROJECT_FAILURE] = createRequestActionTypes("SAVE_PROJECT");

const [UPDATE_PROJECT, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAILURE] = createRequestActionTypes("UPDATE_PROJECT");

const [GENERATE_ROAD_LINE, GENERATE_ROAD_LINE_SUCCESS, GENERATE_ROAD_LINE_FAILURE] = createRequestActionTypes("GENERATE_ROAD_LINE");

const INITIALIZE_PROJECT = "INITIALIZE_PROJECT";
const MODIFY_PROJECT = "MODIFY_PROJECT";
const MODIFY_PNU_LIST = "MODIFY_PNU_LIST";
const MODIFY_PROJECT_CONFIG = "MODIFY_PROJECT_CONFIG";
// const SET_PROJECT_RUNNABLE = "SET_PROJECT_RUNNABLE";
const UPDATE_USE_DISTRICT = "UPDATE_USE_DISTRICT";

const RUN_PROJECT = "RUN_PROJECT";
const RUNNIG_SOLVER = "RUNNIG_SOLVER";

const SET_PURCHASE_CONDITION = "SET_PURCHASE_CONDITION";
const SET_SOLVER_TYPE = "SET_SOLVER_TYPE";
const SET_SUPPORT_PAY = "SET_SUPPORT_PAY";


// const DELETE_PROJECT = 'DELETE_PROJECT';

// const GENERATE_ROAD_LINE = 'GENERATE_ROAD_LINE';

//export const deleteProject= createAction(DELETE_PROJECT);
export const createProject = createAction(CREATE_PROJECT);
export const saveProject = createAction(SAVE_PROJECT);
export const updateProject = createAction(UPDATE_PROJECT);
export const initializeProject = createAction(INITIALIZE_PROJECT);
export const modifyProject = createAction(MODIFY_PROJECT); // 해당 Action 처리시 saga effect -> pnu Parcel 정보 불러오기.
export const modifyPnuList = createAction(MODIFY_PNU_LIST);
// export const setProjectRunnable = createAction(SET_PROJECT_RUNNABLE);
export const generateRoadLine = createAction(GENERATE_ROAD_LINE);
export const updateUseDistrict = createAction(UPDATE_USE_DISTRICT);
export const setSolverType = createAction(SET_SOLVER_TYPE);
export const setSupportPay = createAction(SET_SUPPORT_PAY);
export const runProject = createAction(RUN_PROJECT);
export const modifyProjectConfig = createAction(MODIFY_PROJECT_CONFIG);
export const runningSolver = createAction(RUNNIG_SOLVER);
export const setPurchaseCondition = createAction(SET_PURCHASE_CONDITION);

function* runProjectSaga() {
  // const projectData = yield select((state: RootState) => state.project.data);
  yield put(createProject());
}

function* generateRoadLineSaga() {
  try {
    yield put(setRoadGeneratorLoading({ loading: true }));
    const projectData = yield select((state: RootState) => state.project.data);
    const response = yield retry(3, 2000, generateRoadLineAPI, projectData.project_site);
    const p2 = response.data.body;
    // const p2 = JSON.parse(r2.Payload as string);
    let boundarySite = [];
    boundarySite.push(p2);
    yield put(
      modifyProject({
        boundary_site: boundarySite,
      })
    );
    yield put({
      type: GENERATE_ROAD_LINE_SUCCESS,
      payload: p2,
      meta: response,
    });
    yield put(setRoadGeneratorLoading({ loading: false }));
    yield put(setRoadGeneratorErrors({}));
  } catch (e) {
    yield put(setRoadGeneratorLoading({ loading: false }));
    if (e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    // if(e.response.status === 504) { // 504 gateway timeout : generator error에 대응
    //   // yield put(sessionExpired());
    //   yield put(showErrorMessage({
    //     msg: "주변 도로 데이터 수신 실패",
    //     errorMessage: e.msg,
    //     autoClose: 3000,
    //   }));
    //   return;
    // }
    yield put(
      showErrorMessage({
        msg: "주변 도로 데이터 수신 실패: 프로젝트를 생성할 수 없습니다.",
        errorMessage: e.msg,
        autoClose: 3000,
      })
    );
    yield put(
      setRoadGeneratorErrors({
        msg: "주변 도로 데이터 생성에 문제가 있습니다.",
      })
    );
    // yield put({
    //   type: CREATE_PROJECT_FAILURE,
    //   payload: e.msg,
    // });
  }
}

function* createProjectSaga(action: any) {
  try {
    const response = yield call(createProjectAPI);

    yield put({
      type: CREATE_PROJECT_SUCCESS,
      payload: response.data,
      meta: response,
    });

    // 처음 프로젝트 생성 된 후 save
    yield put(saveProject());
  } catch (e) {
    if (e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    yield put(
      showErrorMessage({
        msg: "프로젝트 생성 실패",
        errorMessage: e.msg,
        autoClose: 3000,
      })
    );
    yield put({
      type: CREATE_PROJECT_FAILURE,
      payload: e.msg,
    });
  }
}

function* modifyPnuListSaga(action: any) {
  if (action.payload.length) yield put(getParcelPnu(action.payload));
}

function* updateProjectSaga(action: any) {
  const projectData = yield select((state: RootState) => state.project.data);
  yield put(modifyPnuList(projectData.field_info_pnu));

  if (projectData.project_site.length) {
    yield put(
      getProjectDefault({
        project_site: projectData.project_site,
        use_district: undefined,
      })
    );
    yield put(getJiguByArea(projectData.project_site));
    yield put(generateRoadLine());
  } else {
    yield put(initializeProjectDefault());
    yield put(initializeProject());
    yield put(initializeProjectErrors());
    yield put(initializeJiguByArea());
    yield put(initializeFieldInfo());
    yield put(initializeParcelInfo());
    yield put(initializeProjectDefaultErrors());
    
  }
}

function* updateProjectUseDistrictSaga(action: any) {
  // 프로젝트 default 정보를 불러와야 하는 경우. project_site가 변경되는 경우,
  const projectData = yield select((state: RootState) => state.project.data);
  if (projectData.project_site.length) {
    yield put(
      getProjectDefault({
        project_site: projectData.project_site,
        use_district: projectData.project_use_district,
      })
    );
  } else {
    yield put(
      showWarningMessage({
        msg: "하나 이상의 필지를 선택하셔야 합니다.",
        autoClose: 3000,
      })
    );
  }
}

// stage: string, project: Project
function* saveProjectSaga(action: any) {
  const projectData = yield select((state: RootState) => state.project.data);
  const newProject = _.cloneDeep(projectData);
  newProject.created_at = new Date().toISOString();
  newProject.modified_at = new Date().toISOString();
  newProject.viewed_at = new Date().toISOString();

  yield put(
    modifyProject({
      created_at: new Date().toISOString(),
      modified_at: newProject.modified_at,
      viewed_at: newProject.viewed_at,
    })
  );
  const sProject: Project = {};
  Object.keys(newProject).forEach((e) => {
    if (newProject[e as keyof Project] !== "" && newProject[e as keyof Project] !== undefined) {
      // @ts-ignore
      sProject[e as keyof Project] = newProject[e as keyof Project];
    }
  });
  try {
    const response = yield call(saveProjectAPI, stage, sProject);
    yield put({
      type: SAVE_PROJECT_SUCCESS,
      payload: response.data,
      meta: response,
    });
    yield put(
      showSuccessMessage({
        msg: "프로젝트가 저장되었습니다",
        autoClose: 3000,
      })
    );
  } catch (e) {
    yield put(
      showErrorMessage({
        msg: "프로젝트 데이터 저장 실패",
        errorMessage: e.msg,
        autoClose: 3000,
      })
    );
    yield put({
      type: SAVE_PROJECT_FAILURE,
      payload: e.msg,
    });
  }
}
function* runSolverSaga() {
  const projectData = yield select((state: RootState) => state.project.data);
  // eslint-disable-next-line
  const response = yield call(runSolverAPI, projectData.project_id);
  yield put(
    showSuccessMessage({
      msg: "프로젝트에 대한 분석이 수행중입니다",
      autoClose: 3000,
    })
  );
  yield put(initializeProjectDefault());
  yield put(initializeProject());
  yield put(initializeJiguByArea());
  yield put(initializeFieldInfo());
  yield put(initializeParcelInfo());
  yield put(initializeProjectErrors());
  yield put(runningSolver()); // 로딩 표시를 위한 action 처리.
}
function* modifyProjectConfigSaga(action: any) {
  yield put(modifyProject(action.payload));
  const currentProject = yield select((state: RootState) => state.project.data);
  const projectDefaultData = yield select((state: RootState) => state.projectDefault.data);
  const defaultErrors = yield select((state: RootState) => state.projectErrors.defaultErrors);
  const projectErrors = projectDefaultData !== undefined ? lhmapInputValidCheck(currentProject) : { errs: defaultErrors };

  if (Object.keys(projectErrors.errs).length) {
    yield put(setDefaultErrors(projectErrors.errs)); // projectErros state에 해당 error 정보를 업데이트 해둔다.
  } else {
    yield put(initializeProjectDefaultErrors());
  }
}
// const savaProjectSaga = createRequestSaga(SAVE_PROJECT, saveProjectAPI);

export function* watchProject() {
  yield takeEvery(CREATE_PROJECT, createProjectSaga);
  yield takeEvery(UPDATE_PROJECT, updateProjectSaga);
  yield takeEvery(SAVE_PROJECT, saveProjectSaga);
  yield takeEvery(SAVE_PROJECT_SUCCESS, runSolverSaga); // 프로젝트 생성 및 저장이 성공하면 곧바로 runSolver 동작
  yield takeEvery(MODIFY_PNU_LIST, modifyPnuListSaga); // saga effect -> pnu Parcel 정보 불러오기
  yield takeEvery(GENERATE_ROAD_LINE, generateRoadLineSaga);
  yield takeEvery(RUN_PROJECT, runProjectSaga);
  yield takeEvery(UPDATE_USE_DISTRICT, updateProjectUseDistrictSaga);
  yield takeEvery(MODIFY_PROJECT_CONFIG, modifyProjectConfigSaga);
}

export interface ProjectData extends Project {}

const initialState: ProjectState = {
  data: new Project({ 평당공사비: 200, 접수날짜: new Date() }),
  pnuList: [],
  saveResult: {},
  solverloading: false,
  genRoad: undefined,
};

const project = handleActions<ProjectState, any>(
  {
    [CREATE_PROJECT]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
      solverloading: true,
    }),
    [RUNNIG_SOLVER]: (state, { payload }) => ({
      ...state,
      solverloading: false,
    }),
    [CREATE_PROJECT_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
    [CREATE_PROJECT_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload.error,
    }),
    [UPDATE_PROJECT]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
    [UPDATE_PROJECT_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
    [SET_PURCHASE_CONDITION]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        customer: {
          ...state.data.customer,
          purchaseCondition: payload,
        },
      },
    }),
    [SET_SOLVER_TYPE]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        customer: {
          ...state.data.customer,
          solver_type: payload,
        },
      },
    }),
    [SET_SUPPORT_PAY]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        customer: {
          ...state.data.customer,
          supportPay: payload,
        },
      },
    }),
    [UPDATE_PROJECT_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload.error,
    }),
    [MODIFY_PROJECT]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
    // MODIFY_PROJECT_CONFIG
    [UPDATE_USE_DISTRICT]: (state, { payload }) => ({
      // project_use_district가 변경될때, projectDefault 갱신을 위한 Action 분리.
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),

    [MODIFY_PNU_LIST]: (state, { payload }) => ({
      ...state,
      pnuList: payload,
    }),
    // [SET_PROJECT_RUNNABLE]: (state, { payload }) => ({
    //   ...state,
    //   projectRunnable: payload,
    // }),
    [SAVE_PROJECT]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
    [SAVE_PROJECT_SUCCESS]: (state, { payload }) => ({
      ...state,
      saveResult: {
        ...state.saveResult,
        ...payload,
      },
    }),
    [SAVE_PROJECT_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload.error,
    }),
    [GENERATE_ROAD_LINE_SUCCESS]: (state, { payload }) => ({
      ...state,
      genRoad: {
        ...state.genRoad,
        payload,
      },
    }),
    [GENERATE_ROAD_LINE_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload.error,
    }),

    // ({
    //   ...state,
    //   error: payload.error,
    // }),
    [INITIALIZE_PROJECT]: () => initialState,
  },
  initialState
);

export default project;
