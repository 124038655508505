import React from 'react';
import { KeyValueContainer, FlexContainer } from '../../../styledComponents/layout'
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

interface RowsKeyNumInputValueProps {
  keyName: string,
  width: string,  
  selectedDate: Date,
  onChangeDate: (date: any, value?: any) => void;
}

export const RowsKeyInputCalendar = ({ selectedDate, onChangeDate, ...props}: RowsKeyNumInputValueProps) => {
  
  return (
    <KeyValueContainer     
      width={props.width}
      height="auto"
    >
      <FlexContainer
        direction="row"
        justifyContent="flex-start"
        width="98%"
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <div style={{ width: '100%', padding: '0px'}}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="normal"
                id="date-picker-inline"
                label={ props.keyName }
                style={{marginTop: '0px'}}
                value={selectedDate}
                onChange={onChangeDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />       
            </div>
          </Grid>
        </MuiPickersUtilsProvider>
      </FlexContainer>
      
    </KeyValueContainer>
  )
}

