import React, { useState } from 'react';
import '../../css/Login.scss';
import { useSelector, useDispatch  } from 'react-redux';
import { RootState } from '../../modules/Index';
import { Button, CircularProgress, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { login } from '../../modules/Auth';
import { showWarningMessage } from '../../modules/InformMessage';
import { TextField } from '@material-ui/core';

export const Login = () => {
  const { loading } = useSelector(
    ({ auth }: RootState) => ({
      loading: auth.loading,     
    }),
  );
  const [userid, setUserid] = useState('')
  const [pass, setPass] = useState('')
  const dispatch = useDispatch();
  
  const mainTheme = createMuiTheme({
    palette: {
      primary: {
        main: '#26A69A',
      },
    },
  });

  const loginPatch = () => {
    if(userid.trim() === '') {
      dispatch(showWarningMessage({
        msg: "ID를 입력해주세요",
        width: 370,
        autoClose: 3000,   
      }));
      return;
    } else if(pass.trim() === '') {      
      dispatch(showWarningMessage({
        msg: "ID를 입력해주세요",
        width: 370,
        autoClose: 3000,   
      }));   
      return; 
    }
    dispatch(login({
      userid,
      password: pass,
    }));
  }

  const onKeyDown = (e: any) => {
    switch (e.keyCode) {
      case 38: // up   
        break;  
      case 13: // enter                
        loginPatch();
        break;
    }
  }
  
  return (
    <div className="Login">
      {
        loading && 
        <div className="dim">
          <CircularProgress className="circular-progress" />
        </div>
      }
      <div className="login-wrapper">
        
        <div className="loginConatainer">     
          <div className="header">
            LH MAP PLATFORM <br /> 
          </div>    
          <div className="loginheader">
            LOGIN <br /> 
          </div>   
          <MuiThemeProvider theme={mainTheme}> 
          <TextField   
          className="textInput"
            name="userid"
            label="ID"   
            placeholder=""
            onChange={(e) => { setUserid(e.target.value); }}
            value={userid} 
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
          />
          <TextField           
            className="textInput"
            label="PASSWORD"   
            type="password"
            name="password"
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={onKeyDown}            
            onChange={(e) => { setPass(e.target.value); }}
            value={pass}      
            autoComplete="off"      
          />
          </MuiThemeProvider>  
          <Button
            className="btn loginBtn"
            onClick={() => loginPatch()}
          >LOGIN</Button> 
         
                 
        </div>
       
      </div>
      <div className="logoDiv" />
    </div>
  )
}


export default Login;