import { createAction, handleActions } from 'redux-actions';
// import { stage } from '../App';
import { createRequestActionTypes } from '../lib/createRequestSaga';

import { getProjectResultAPI, modRentPossibilityAPI, getProjectFailReasonAPI } from '../api/ProjectApi';
import { getJiguByAreaAPI } from '../api/JiguByAreaApi';
import { takeLatest, put, call, select,  } from 'redux-saga/effects';
import { showErrorMessage } from './InformMessage';
import { sessionExpired } from './Auth';
import { RootState } from './Index';

const INITIALIZE_RESULT_INFO = 'INITIALIZE_RESULT_INFO';

// const MODIFY_RUN_STATE = 'MODIFY_RUN_STATE';
const SET_PROJECT_SELECT = 'SET_PROJECT_SELECT';
const [
  GET_PROJECT_RESULT,
  GET_PROJECT_RESULT_SUCCESS,
  GET_PROJECT_RESULT_FAILURE,
] = createRequestActionTypes('GET_PROJECT_RESULT');
// const MOD_RENT_POSSIBILITY = 'MOD_RENT_POSSIBILITY';    
const [
  MOD_RENT_POSSIBILITY,       // 임대가능성 수정을 위한 
  MOD_RENT_POSSIBILITY_SUCCESS,
  MOD_RENT_POSSIBILITY_FAILURE,
] = createRequestActionTypes('MOD_RENT_POSSIBILITY');

const [
  GET_PROJECT_FAIL_RESEAON,
  GET_PROJECT_FAIL_RESEAON_SUCCESS,
  GET_PROJECT_FAIL_RESEAON_FAILURE,
] = createRequestActionTypes('GET_PROJECT_FAIL_RESEAON');


const [
  GET_RESULT_JIGU_BY_AREA,
  GET_RESULT_JIGU_BY_AREA_SUCCESS,
  GET_RESULT_JIGU_BY_AREA_FAILURE,
] = createRequestActionTypes('GET_RESULT_JIGU_BY_AREA');

const MOD_CONSTRUCT_PAY = 'MOD_CONSTRUCT_PAY';    // 로컬에서 공사비 항목을 바꿀 때,

export const setProjectSelect = createAction(SET_PROJECT_SELECT);
export const getProjectResult = createAction(GET_PROJECT_RESULT);
export const modRentPossibility = createAction(MOD_RENT_POSSIBILITY);
export const getProjectFailReason = createAction(GET_PROJECT_FAIL_RESEAON);
export const getResultJiguByArea = createAction(GET_RESULT_JIGU_BY_AREA);
export const modConstructPay = createAction(MOD_CONSTRUCT_PAY);       // 로컬에서 공사비 항목을 바꿀 때,
export const initializeResultInfo = createAction(INITIALIZE_RESULT_INFO);

function* modRentPossibilitySaga(action: any) {
  // console.log('action', action)
  try {      
    const response = yield call(modRentPossibilityAPI, action.payload.project_id, action.payload.value);
    // console.log('response.result_org', response.result_org)  
    yield put({                     // projectDefault 저장
      type: MOD_RENT_POSSIBILITY_SUCCESS,
      payload: response.data.result,
      meta: response,
    });
  } catch (e) {
    if(e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    yield put(showErrorMessage({
      msg: "임대가능성 변수 저장에 실패",
      errorMessage: e.msg,
      autoClose: 3000,   
    }));
    yield put({
      type: MOD_RENT_POSSIBILITY_FAILURE,
      // payload: e,
      error: true,
    });
  }
}

function* getProjectResultSaga(action: any) {   
  try {      
    const response = yield call(getProjectResultAPI, action.payload.project);    
    
    yield put({                     // projectDefault 저장
      type: GET_PROJECT_RESULT_SUCCESS,
      payload: response.data.result,
      meta: response,
    });    
    const projectSite = yield select((state: RootState) => state.selectedProject.result.project_site);
    yield put(getResultJiguByArea(projectSite));
  } catch (e) {    
    if(e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    yield put(showErrorMessage({
      msg: "프로젝트 결과 조회 실패",
      errorMessage: e.msg,
      autoClose: 3000,   
    }));
    yield put({
      type: GET_PROJECT_RESULT_FAILURE,
      // payload: e,
      error: true,
    });
  }
}

function* getProjectFailReasonSaga(action: any) {   
  try {      
    const response = yield call(getProjectFailReasonAPI, action.payload.project);    
    yield put({                     // projectDefault 저장
      type: GET_PROJECT_FAIL_RESEAON_SUCCESS,
      payload: response.data,
      meta: response,
    });
    
  } catch (e) {    
    if(e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    yield put(showErrorMessage({
      msg: "프로젝트 결과 조회 실패",
      errorMessage: e.msg,
      autoClose: 3000,   
    }));
    yield put({
      type: GET_PROJECT_FAIL_RESEAON_FAILURE,
      payload: e,
      error: true,
    });
  }
}

function* getResultJiguByAreaSaga (action:any) {
  try {
    const response = yield call(getJiguByAreaAPI, action.payload);    
    const result = response.data.result;    
    const recal = result.map((item: any) => { 
      return {
        url: item.url,
        pnu: item.pnu,
        wkt: item.geom,
        address: item['주소'],
        jimog: item['지목'],
        info: {
          '국토법': item['국토법'],
          '기타법': item['기타법'],
        }
      }
    });
    const data1 = recal[0].info!['국토법']['포함'].length > 0 ? "[포함] ".concat(recal[0].info!['국토법']['포함'].join(", ")) : ""; 
    const data2 = recal[0].info!['국토법']['저촉'].length > 0 ? "[저촉] ".concat(recal[0].info!['국토법']['저촉'].join(", ")) : ""; 
    const data3 = recal[0].info!['국토법']['접함'].length > 0 ? "[접함] ".concat(recal[0].info!['국토법']['접함'].join(", ")) : ""; 
  
    yield put({
      type: GET_RESULT_JIGU_BY_AREA_SUCCESS,
      payload: data1.concat(' ', data2, ' ', data3),
      meta: response,
    });   
  } catch (e) {        
    if(e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    yield put(showErrorMessage({
      msg: "토지이용계획 정보 수신 실패",
      errorMessage: e.msg,
      autoClose: 3000,   
    }));
    yield put({
      type: GET_RESULT_JIGU_BY_AREA_FAILURE,
      payload: e.msg,
    });
  }
}

export function* watchResultInfo() { 
  yield takeLatest(GET_PROJECT_RESULT, getProjectResultSaga);
  yield takeLatest(MOD_RENT_POSSIBILITY, modRentPossibilitySaga);
  yield takeLatest(GET_PROJECT_FAIL_RESEAON, getProjectFailReasonSaga);
  yield takeLatest(GET_RESULT_JIGU_BY_AREA, getResultJiguByAreaSaga);
}

const initialState: any = { 
  project: undefined,
  result: {},  
  loading: false,
  failreason: undefined,
};
  
const selectedProject = handleActions<any, any>(
  {   
    [GET_PROJECT_RESULT]: (state, { payload }) => ({
      ...state,
      loading: true,
    }),
    [SET_PROJECT_SELECT]: (state, { payload }) => ({
      ...state,
      project: payload,
      result: {},
      loading: true,
    }),
    [GET_PROJECT_RESULT_SUCCESS]: (state, { payload }) => ({
      ...state,
      result: payload,
      loading: false,
    }),
    [GET_PROJECT_RESULT_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),    
    [MOD_RENT_POSSIBILITY_SUCCESS]: (state, { payload }) => ({
      ...state,
      result: { 
        ...state.result, 
        ...payload,
      },
      loading: false,
    }),
    [MOD_RENT_POSSIBILITY_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),  
    [GET_PROJECT_FAIL_RESEAON]: (state, { payload }) => ({
      ...state,
      failreason: undefined,
    }),  
    [GET_PROJECT_FAIL_RESEAON_SUCCESS]: (state, { payload }) => ({
      ...state,
      failreason: payload,
    }),  
    [GET_PROJECT_FAIL_RESEAON_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload,
    }), 
    [GET_RESULT_JIGU_BY_AREA_SUCCESS]: (state, { payload }) => ({
      ...state,
      result: { 
        ...state.result,         
        jiguInfo: payload,
      },
    }), 
    [GET_RESULT_JIGU_BY_AREA_FAILURE]: (state, { payload }) => ({
      ...state,
      result: { 
        ...state.result,         
        jiguInfo: '-',
      },
    }),
    [MOD_CONSTRUCT_PAY]:  (state, { payload }) => ({
      ...state,
      result: { 
        ...state.result,         
        평당공사비: payload,
      },
    }),
    // [MOD_RENT_POSSIBILITY]: (state, { payload }) => ({

    // }),
    [INITIALIZE_RESULT_INFO]: () => initialState,
  },
  initialState,
);

export default selectedProject;