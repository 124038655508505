import { createAction, handleActions } from 'redux-actions';
import { takeLatest, put, select } from 'redux-saga/effects';
import { RootState } from './Index';

const INITIALIZE_PROJECT_ERRORS = 'INITIALIZE_PROJECT_ERRORS';
const INITIALIZE_PROJECT_DEFAULT_ERRORS = 'INITIALIZE_PROJECT_DEFAULT_ERRORS';
const INITIALIZE_PROJECT_OTHER_ERRORS = 'INITIALIZE_PROJECT_OTHER_ERRORS';
const INITIALIZE_DISTRICT_ERRORS = 'INITIALIZE_DISTRICT_ERRORS';
const SET_DEFAULT_ERRORS = 'SET_DEFAULT_ERRORS';
const SET_OTHER_ERRORS = 'SET_OTHER_ERRORS';
const ADD_OTHER_ERRORS = 'ADD_OTHER_ERRORS';
const SET_DISTRICT_ERRORS = 'SET_DISTRICT_ERRORS';
const SET_RUNNABLE_PROJECT = 'SET_RUNNABLE_PROJECT';
const SET_ROAD_GENERATOR_ERRORS = 'ROAD_GENERATOR_ERRORS';
const SET_ROAD_GENERATOR_LOADING =  'SET_ROAD_GENERATOR_LOADING';


export const initializeProjectErrors = createAction(INITIALIZE_PROJECT_ERRORS);
export const initializeProjectOtherErrors = createAction(INITIALIZE_PROJECT_OTHER_ERRORS);
export const initializeProjectDefaultErrors = createAction(INITIALIZE_PROJECT_DEFAULT_ERRORS);
export const initializeDistrictErrors = createAction(INITIALIZE_DISTRICT_ERRORS);

export const setDefaultErrors = createAction(SET_DEFAULT_ERRORS);
export const setOtherErrors = createAction(SET_OTHER_ERRORS);       // put all, delete with omit
export const addOtherErrors = createAction(ADD_OTHER_ERRORS);       // put
export const setDistrictErrors = createAction(SET_DISTRICT_ERRORS);
export const setRunnableProject = createAction(SET_RUNNABLE_PROJECT);
export const setRoadGeneratorErrors = createAction(SET_ROAD_GENERATOR_ERRORS);    // Road Generator 수신에 실패할 경우
export const setRoadGeneratorLoading = createAction(SET_ROAD_GENERATOR_LOADING);  // Road Generator 수신 시작시 -> retry로 인한 시간 동안 loading 처리 . runnnable false -> true

function* projectConditionCheckSaga () {
  // console.log('projectConditionCheckSaga')
  const defaultErrors = yield select((state: RootState) => state.projectErrors.defaultErrors);
  const otherErrors = yield select((state: RootState) => state.projectErrors.otherErrors);
  const projectData = yield select((state: RootState) => state.project.data);
  const districtErrors = yield select((state: RootState) => state.projectErrors.districtErrors);
  const roadgenErrors = yield select((state: RootState) => state.projectErrors.roadgenErrors);

  const roadLoading =  yield select((state: RootState) => state.projectErrors.roadloading);

  if(Object.keys(defaultErrors).length > 0 || Object.keys(otherErrors).length > 0
     ||  Object.keys(districtErrors).length > 0 ||  Object.keys(roadgenErrors).length > 0 || roadLoading) {     
    yield put(setRunnableProject({ canRunProject: false }));
  } else {
    if(projectData.project_site.length) {  
      yield put(setRunnableProject({ canRunProject: true }));
    }
  }
}

export function* watchProjectErrors() { 
  yield takeLatest(SET_DEFAULT_ERRORS, projectConditionCheckSaga);
  yield takeLatest(SET_OTHER_ERRORS, projectConditionCheckSaga);
  yield takeLatest(ADD_OTHER_ERRORS, projectConditionCheckSaga);
  yield takeLatest(SET_DISTRICT_ERRORS, projectConditionCheckSaga);
  yield takeLatest(SET_ROAD_GENERATOR_ERRORS, projectConditionCheckSaga);
  yield takeLatest(INITIALIZE_PROJECT_DEFAULT_ERRORS, projectConditionCheckSaga);
  yield takeLatest(INITIALIZE_PROJECT_OTHER_ERRORS, projectConditionCheckSaga);
}

const initialState: any = { 
  defaultErrors: {},
  otherErrors: {},
  districtErrors: {},
  roadgenErrors: {},
  canRunProject: false,
  roadloading: false,
};

const projectErrors = handleActions<any, any>(
  {   
    [SET_DEFAULT_ERRORS]: (state, { payload }) => ({
      ...state,
      defaultErrors: payload,
    }),
    [SET_OTHER_ERRORS]: (state, { payload }) => ({
      ...state,
      otherErrors: payload,
    }), 
    [SET_DISTRICT_ERRORS]: (state, { payload }) => ({
      ...state,
      districtErrors: payload,
    }), 
    [SET_ROAD_GENERATOR_LOADING]: (state, { payload }) => ({
      ...state,
      roadloading: payload.loading,
    }), 
    [SET_ROAD_GENERATOR_ERRORS]: (state, { payload }) => ({
      ...state,
      roadgenErrors: payload,
    }), 
    [SET_RUNNABLE_PROJECT]: (state, { payload }) => ({
      ...state,
      canRunProject: payload.canRunProject,
    }),
    [ADD_OTHER_ERRORS]: (state, { payload }) => ({
      ...state,
      otherErrors: {
        ...state.otherErrors,
        ...payload,
      }
    }),
    [INITIALIZE_PROJECT_DEFAULT_ERRORS]: (state) => ({
      ...state, 
      defaultErrors: initialState.defaultErrors,    
    }),
    [INITIALIZE_PROJECT_OTHER_ERRORS]: (state) => ({
      ...state, 
      otherErrors: initialState.otherErrors,       
    }),
    [INITIALIZE_DISTRICT_ERRORS]: (state) => ({
      ...state, 
      districtErrors: initialState.districtErrors,       
    }),
    [INITIALIZE_PROJECT_ERRORS]: () => initialState,
  },
  initialState,
);

export default projectErrors;