import React, { useState } from 'react';
// import styled from '@emotion/styled'
import DoneIcon from '@material-ui/icons/Done';
import { CheckButton } from '../../styledComponents/buttons';

interface DoneIconDivProps {
  name: string;
  active: boolean;  
  hover: boolean;
  onClick: (e:any) => void;
}

interface DoneIconButtonProps {
  name: string;
  active: boolean;  
  onClick: (e:any) => void;
}


export const IconDiv = ({...props}: DoneIconDivProps) => {
  return (
    <div>
      {
        props.active && (
          <div 
            style={{ display: 'flex', alignItems: 'center', color: `${props.hover ? '#cccccc':'#00897B'}`}}
          >
            <DoneIcon />
          </div>
        )
      }
      {
        !props.active && (
          <div 
            style={{ display: 'flex', alignItems: 'center', color: `${props.hover ? '#00897B':'#cccccc'}`}}
          > 
            <DoneIcon />
          </div>
        )
      }
    </div>    
  )
}


export const DoneIconButton = ({...props}: DoneIconButtonProps) => {
  const [hover, setHover] = useState(false)
  
  return (   
    <CheckButton 
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props.name} active={props.active} onClick={() => props.onClick({ name: props.name, active: props.active})}>      
      <IconDiv {...props} hover={hover} /><span>{props.name}</span>
    </CheckButton>
  )
}