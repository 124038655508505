import { createAction, handleActions } from "redux-actions";

import createRequestSaga, { createRequestActionTypes } from "../lib/createRequestSaga";
import { getParcelAddressAPI, getParcelPnuAPI } from "../api/SearchESApi";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { RootState } from "./Index";
// import { NCoordinate } from '../MapManager'
import { modifyProject } from "./Project";
import { showErrorMessage, showWarningMessage } from "./InformMessage";
import { setOtherErrors, addOtherErrors } from "./ProjectErrors";
import { default as _ } from "lodash";
import { sessionExpired } from "./Auth";
import { getDistrictInfo } from "./District";
const [GET_PARCEL_ADDRESS, GET_PARCEL_ADDRESS_SUCCESS, GET_PARCEL_ADDRESS_FAILURE] = createRequestActionTypes("GET_PARCEL_ADDRESS");

const [GET_PARCEL_PNU, GET_PARCEL_PNU_SUCCESS, GET_PARCEL_PNU_FAILURE] = createRequestActionTypes("GET_PARCEL_PNU");

const INITIALIZE_PARCEL_INFO = "INITIALIZE_PARCEL_INFO";

export const getParcelAddress = createAction(GET_PARCEL_ADDRESS, (address: string) => address);

export const getParcelPnu = createAction(GET_PARCEL_PNU, (pnuList: Array<string>) => pnuList);
export const initializeParcelInfo = createAction(INITIALIZE_PARCEL_INFO);

const getParcelAddressSaga = createRequestSaga(GET_PARCEL_ADDRESS, getParcelAddressAPI);

export function* fetchgetParcelPnuSaga(action: any) {
  try {
    const response = yield call(getParcelPnuAPI, action.payload);
    console.log('response', response)
    yield put({
      type: GET_PARCEL_PNU_SUCCESS,
      payload: response.data,
      meta: response,
    });
    const hitsParcelInfo = yield select((state: RootState) => state.parcelInfo.data);
    // const project_site_area = hitsParcelInfo
    //   .map((rrr: any) => {
    //     if (rrr._source["토지면적"] !== undefined) {
    //       return rrr._source["토지면적"];
    //     } else {
    //       return rrr._source["토지면적_계산"];
    //     }
    //   })
    //   .reduce((a: any, b: any) => a + b, 0);
    console.log(hitsParcelInfo)
    const project_site_area = hitsParcelInfo
      .map((rrr: any) => {
        if (rrr["토지면적"] !== undefined) {
          return rrr["토지면적"];
        } else {
          return rrr["토지면적_계산"];
        }
      })
      .reduce((a: any, b: any) => a + b, 0);

    if (project_site_area < 70 || project_site_area > 5000) {
      yield put(
        showWarningMessage({
          msg: "검토 가능한 필지의 총 면적은 70~5000㎡ 입니다",
          autoClose: 3000,
          width: 370,
        })
      );
      yield put(addOtherErrors({ project_area_can_run: false })); // 면적에 대한 에러를 처리하기 위해 추가.
    } else {
      const otherErrors = yield select((state: RootState) => state.projectErrors.otherErrors);
      yield put(setOtherErrors(_.omit(otherErrors, ["project_area_can_run"]))); // 면적에 이상이 없을 경우
    }

    const projectData = yield select((state: RootState) => state.project.data);
    let address = "";
    if (hitsParcelInfo.length > 0) {
      if (projectData.field_info_pnu!.length > 1) {
        address = hitsParcelInfo[0]["주소"] + ` 외 ${projectData.field_info_pnu!.length - 1}필지`;
      } else {
        address = hitsParcelInfo[0]["주소"];
      }
    }
    yield put(
      modifyProject({
        project_site_area: project_site_area,
        project_address: hitsParcelInfo[0]["주소"],
        project_name: projectData.auto_name ? `${address} - 프로젝트` : projectData.project_name,
        project_jimog: hitsParcelInfo[0]["지목"],
      })
    );
    yield put(getDistrictInfo()); // 현재 주소에 대한 지구 정보 (매입 기초자료) [인구 등]
  } catch (e) {
    console.log(e)
    if (e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    yield put(
      showErrorMessage({
        msg: "필지 상세 정보 수신 실패",
        errorMessage: e.msg,
        autoClose: 3000,
      })
    );
    yield put({
      type: GET_PARCEL_PNU_FAILURE,
      payload: e.msg,
    });
  }
}

export function* watchParcelInfo() {
  yield takeLatest(GET_PARCEL_ADDRESS, getParcelAddressSaga);
  yield takeLatest(GET_PARCEL_PNU, fetchgetParcelPnuSaga);
}

type ParcelInfoState = {
  data?: any;
  error?: string;
};

const initialState: ParcelInfoState = {
  data: {},
};

const parcelInfo = handleActions<ParcelInfoState, any>(
  {
    [GET_PARCEL_ADDRESS_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload.hits!.hits!,
    }),
    [GET_PARCEL_ADDRESS_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [GET_PARCEL_PNU_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
    [GET_PARCEL_PNU_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [INITIALIZE_PARCEL_INFO]: () => initialState,
  },
  initialState
);

export default parcelInfo;
