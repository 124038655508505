/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FlexContainer, KeyValueContainer } from '../../styledComponents/layout'
import { KeyValueTitle, KeyValueContents } from '../../styledComponents/text';
import { HelpQuestion } from './HelpQuestion' 
interface RowsKeyValueProps {
  keyName: string,
  value: string,
  width: string,
  unit?: string,
  help?: string,
}

export const RowsKeyValue = (props: RowsKeyValueProps) => {
  const [unit, setUnit] = useState("");

  useEffect(() => {
    switch(props.unit) {
      case "m2": 
        setUnit('㎡');
        break;
      case "sqft":
        setUnit('평');
        break;
      default:
        setUnit(`${props.unit ? props.unit : ''}`);
        break;
    }
  },[props.unit]);

  return (
    <KeyValueContainer     
      width={props.width}
      height="auto"
    >
      <FlexContainer
        justifyContent="space-between"
        width="98%"
        padding="0px 20px 0px 0px"
      >
        <KeyValueTitle>{ props.keyName }</KeyValueTitle>
        {props.help && <HelpQuestion helpString={props.help} id={props.keyName}  />}
        {props.help === undefined && <div></div>}
      </FlexContainer>
      
      <KeyValueContents>{ props.value }<span style={{ fontWeight: 400}}>{unit}</span></KeyValueContents>
    </KeyValueContainer>
  )
}

export default RowsKeyValue;