import React from 'react';
import styled from '@emotion/styled'
import ReactTooltip from 'react-tooltip'


export const QuestionImg = styled.div`
  width: 14px;
  height: 14px;
  background-image: url('img/Question.png');
`;
interface HelpQuestionProps {
  helpString: string;
  id: string;
}
export const HelpQuestion = ({...props}: HelpQuestionProps) => {
  
  return (
    <div style={{ paddingRight: "5px", maxWidth: "300px" }}>
      <ReactTooltip id={props.id} type="info" effect="solid">
        <span style={{ "whiteSpace": "pre-wrap" }}>{props.helpString}</span>
      </ReactTooltip>
      <QuestionImg data-tip data-for={props.id} />
    </div>
  );
}
