import React, { Component } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Sky } from "./sky";
import { MakeBuildingMesh } from "./MeshManager";
import { DEMData } from "./DataManager";
import "../css/Visualizer.scss";
import { ReactComponent as CompassIcon } from "../img/icon_compass.svg";
import ApartmentIcon from "@material-ui/icons/Apartment";
import DirectionsWalk from "@material-ui/icons/DirectionsWalk";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { SAOPass } from "three/examples/jsm/postprocessing/SAOPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";

interface SceneProps {
  siteCenter: THREE.Vector3;
  subBuilding: THREE.Group;
  dem: THREE.Group;
  demData: DEMData[];
  siteLine: THREE.Line[];
  pnu: string;
  summary: any;
  districtUnitPlanInfo: any;
  resultJson: any;
  lightIndex: number;
  demTextureWithRoad: boolean;
  buildingYHeight: number;
  switchDemTexture: () => void;
  getPos: (x: number, y: number) => THREE.Vector3;
}

interface SceneState {
  sceneWidth: number;
  sceneHeight: number;
  subBuildingVisible: boolean;
  floorAreaRatio: number;
  totalHouse: number;
  coverAreaRatio: number;
  siteCenter: THREE.Vector3;
  sunButtonOver: boolean;
  topViewButtonOver: boolean;
  cameraMode: boolean;
  hideInfo: boolean;
}

export interface reportInformation {
  type: string;
  floorAreaRatio: number;
  coreAreaRatio: number;
  aveLevelNum: number;
  aveLevelArea: number;
  aveLevelHouse: number;
  maxLevel: number;
  minLevel: number;
  totalBuildingNumber: number;
  totalHouse: number;
  coast: number;
  daylightHoursAvg: number;
  daylightHoursMin: number;
  viewPointAvg: number;
  reportID: number;
  realReportID: number;
}

export class Scene extends Component<SceneProps, SceneState> {
  state: SceneState = {
    sceneHeight: 374, // window.innerHeight,
    sceneWidth: 374, //window.innerWidth,
    subBuildingVisible: true,
    floorAreaRatio: 0,
    totalHouse: 0,
    coverAreaRatio: 0,
    siteCenter: new THREE.Vector3(0),
    sunButtonOver: false,
    topViewButtonOver: false,
    cameraMode: true,
    hideInfo: true,
  };

  mount: HTMLDivElement | null = null;
  renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
  scene = new THREE.Scene();

  perspectiveCamera = new THREE.PerspectiveCamera(40, this.state.sceneWidth / this.state.sceneHeight, 0.1, 1000.0);
  perspectiveControls = new OrbitControls(this.perspectiveCamera, this.renderer.domElement);
  dirLight = new THREE.DirectionalLight(0xffffff, 1);
  lightPosOffset = 500;

  renderCamera: THREE.Camera = this.perspectiveCamera;
  mainControl = this.perspectiveControls;

  sunPos: THREE.Vector3[] = [];
  referenceMatrix = new THREE.Matrix4().identity();
  frustumSize = 10;
  buildingGroup = new THREE.Group();
  subbuildingGroup = new THREE.Group();
  demGroup = new THREE.Group();

  sky = new THREE.Mesh();
  mouse = new THREE.Vector2();
  raycaster = new THREE.Raycaster();

  composer = new EffectComposer(this.renderer);
  fxaaPass = new ShaderPass(FXAAShader);
  renderPass = new RenderPass(this.scene, this.renderCamera);
  saoPass = new SAOPass(this.scene, this.renderCamera, false, true);

  initSky = () => {
    this.sky = Sky();

    this.sky.geometry = new THREE.SphereBufferGeometry(1, 32, 15);
    this.sky.scale.setScalar(40);
    this.sky.position.set(0, 0, 0);

    (this.sky.material as THREE.ShaderMaterial).depthTest = false;
    this.sky.renderOrder = -1;

    let uniforms = (this.sky.material as THREE.ShaderMaterial).uniforms;
    uniforms["turbidity"].value = 10;
    uniforms["rayleigh"].value = 2;
    uniforms["luminance"].value = 1;
    uniforms["mieCoefficient"].value = 0.005;
    uniforms["mieDirectionalG"].value = 0.8;
    uniforms["cameraPos"].value = this.sky.position;
    uniforms["sunPosition"].value = new THREE.Vector3(10, 10, 10);

    this.scene.add(this.sky);
  };

  roundTimeData = (time: number) => {
    let nt = Math.round(time * 60);
    return nt / 60;
  };

  componentDidMount = async () => {
    this.mount!.appendChild(this.renderer.domElement);
    this.scene.add(this.buildingGroup);

    let index = 0;
    (this.props.resultJson.buildingInfo as []).forEach((b: any) => {
      let building = new THREE.Group();
      if (!b.name.includes("Basement")) {
        building = MakeBuildingMesh(b, index == 0);
        building.position.set(0, b.baseHeight * 0.1, 0);
        console.log(building);
        this.buildingGroup.add(building);
        index++;
      }
    });
    try {
    } catch (e) {
      console.log(e);
    }
    var baseHeight = 0;
    var stories = 0;
    for (var idx = 0; idx < this.props.resultJson.buildingInfo.length; idx++) {
      if (!this.props.resultJson.buildingInfo[idx].name.includes("Basement")) {
        if (baseHeight == 0) {
          baseHeight = this.props.resultJson.buildingInfo[idx].baseHeight;
        }
        stories += this.props.resultJson.buildingInfo[idx].stories;
      }
    }
    var initDist = 3 + stories * 0.5;
    let cameraHeight = (baseHeight + 2.8 * 1.5) * 0.1;
    let newCenter = new THREE.Vector3(this.props.siteCenter.x, cameraHeight, this.props.siteCenter.z);

    this.renderer.setSize(this.state.sceneWidth, this.state.sceneHeight);
    this.composer.setSize(this.state.sceneWidth, this.state.sceneHeight);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFShadowMap;
    this.renderer.toneMapping = THREE.LinearToneMapping;
    this.renderer.toneMappingExposure = 1.1;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.perspectiveControls.target.set(newCenter.x, newCenter.y, newCenter.z);
    this.perspectiveControls.object.position.set(newCenter.x, newCenter.y + initDist, newCenter.z + initDist);
    // this.perspectiveControls.enablePan = false;
    // this.perspectiveControls.screenSpacePanning = true;
    this.perspectiveControls.rotateSpeed = 0.3;
    this.perspectiveControls.maxPolarAngle = Math.PI / 2;
    this.perspectiveControls.maxDistance = 20;
    this.perspectiveControls.minDistance = 1;
    this.perspectiveControls.update();

    this.subbuildingGroup = this.props.subBuilding; //.clone();
    this.demGroup = this.props.dem; //.clone();
    this.scene.add(this.subbuildingGroup);
    this.scene.add(this.demGroup);

    this.props.siteLine.forEach((l) => {
      this.scene.add(l.clone());
    });

    this.initSky();
    this.initLight();
    this.animate();

    window.addEventListener("mousemove", this.updateCampass);
    window.addEventListener("touchmove", this.updateCampass, { capture: true });
    window.addEventListener("resize", this.onWindowResize, false);
    window.addEventListener("orientationchange", this.handleOrientation, false);
    // window.addEventListener("keyup", this.onKeyUp, false);
    document.getElementsByTagName("canvas")[0].tabIndex = 1;
    // var renderPass, saoPass;
    // renderPass = new RenderPass(this.scene, this.renderCamera);
    this.composer.addPass(this.renderPass);
    // saoPass = new SAOPass(this.scene, this.renderCamera, false, true);
    this.composer.addPass(this.saoPass);
    this.saoPass.params.saoIntensity = 0.001;
    this.saoPass.params.saoKernelRadius = 6;
    this.saoPass.params.saoScale = 10;
    this.saoPass.params.saoBlur = 0;

    var pixelRatio = this.renderer.getPixelRatio();
    this.fxaaPass.material.uniforms["resolution"].value.x = 1 / (this.state.sceneWidth * pixelRatio);
    this.fxaaPass.material.uniforms["resolution"].value.y = 1 / (this.state.sceneHeight * pixelRatio);
    this.composer.addPass(this.fxaaPass);

    this.setState({
      //@ts-ignore
      realReportID: this.props.resultJson.real_report_number,
      siteCenter: newCenter,
      totalHouse: this.props.resultJson.totalHousehold,
    });
  };

  componentWillUnmount = () => {
    this.scene.remove(this.buildingGroup);
    this.scene.remove(this.subbuildingGroup);
    this.scene.remove(this.demGroup);

    while (this.buildingGroup.children.length > 0) {
      let c = this.buildingGroup.children[0];
      this.buildingGroup.remove(c);
      while (c.children.length > 0) {
        let cc = c.children[0];
        c.remove(cc);
        while (cc.children.length > 0) {
          let ccc = cc.children[0];
          cc.remove(ccc);
          this.scene.remove(ccc);
          (ccc as THREE.Mesh).geometry.dispose();
          ((ccc as THREE.Mesh).material as THREE.Material).dispose();
        }
      }
    }

    while (this.subbuildingGroup.children.length > 0) {
      this.subbuildingGroup.children.forEach((c) => {
        if (c.children.length > 0) {
          c.children.forEach((cc) => {
            (cc as THREE.Mesh).geometry.dispose();
            ((cc as THREE.Mesh).material as THREE.Material).dispose();
            c.remove(cc);
          });
        }
        this.subbuildingGroup.remove(c);
      });
    }

    while (this.demGroup.children.length > 0) {
      (this.demGroup.children[0] as THREE.Mesh).geometry.dispose();
      ((this.demGroup.children[0] as THREE.Mesh).material as THREE.Material).dispose();
      this.demGroup.remove(this.demGroup.children[0]);
    }

    this.scene.remove.apply(this.scene, this.scene.children);
    this.sky.geometry.dispose();
    (this.sky.material as THREE.Material).dispose();
    this.renderer.dispose();

    this.mount!.removeChild(this.renderer.domElement);
  };

  componentWillUpdate(previousProps: Readonly<SceneProps>, previousState: Readonly<SceneState>) {
    if (this.state.subBuildingVisible !== previousState.subBuildingVisible) {
      this.subbuildingGroup.visible = !this.state.subBuildingVisible;
    }
  }

  handleOrientation = (event: Event) => {
    this.onWindowResize();
  };

  initLight = () => {
    this.sunPos.push(new THREE.Vector3(0.848571342589131, 0.03840209002455263, 0.5276854707287532).normalize());
    this.sunPos.push(new THREE.Vector3(0.7294381932261496, 0.19926061275844523, 0.65438148695245).normalize());
    this.sunPos.push(new THREE.Vector3(0.5607083834604033, 0.33320777126215184, 0.758009689837624).normalize());
    this.sunPos.push(new THREE.Vector3(0.35379583173435697, 0.4283571676667184, 0.8314677662759642).normalize());
    this.sunPos.push(new THREE.Vector3(0.12278797350096553, 0.47797378146026087, 0.869749491405488).normalize());
    this.sunPos.push(new THREE.Vector3(-0.11658293357671677, 0.4786221150404067, 0.8702466837586309).normalize());
    this.sunPos.push(new THREE.Vector3(-0.3480144436430954, 0.4302574326654993, 0.8329252599434598).normalize());
    this.sunPos.push(new THREE.Vector3(-0.5557453329483792, 0.3362277998664823, 0.7603275554016439).normalize());
    this.sunPos.push(new THREE.Vector3(-0.7256320736487277, 0.20318087432540594, 0.6574006586554436).normalize());

    this.setSunDir(this.props.lightIndex);

    this.dirLight.castShadow = true;
    this.dirLight.shadow.bias = -0.00004;

    let width = 8;
    this.dirLight.shadow.camera.far = 1000;
    this.dirLight.shadow.camera.left = -width;
    this.dirLight.shadow.camera.right = width;
    this.dirLight.shadow.camera.top = width;
    this.dirLight.shadow.camera.bottom = -width;

    this.dirLight.shadow.mapSize.width = 4096;
    this.dirLight.shadow.mapSize.height = 4096;
    this.dirLight.intensity = 0.5;

    this.scene.add(this.dirLight);
    this.scene.add(this.dirLight.target);

    this.scene.add(new THREE.AmbientLight(0xeeeeee, 0.8));
  };

  setSunDir = (index: number) => {
    let minValue = Math.floor(index);
    let maxValue = minValue + 1;
    if (maxValue > 8) {
      maxValue = 8;
    }
    let minDir = new THREE.Vector3(this.sunPos[minValue].x, this.sunPos[minValue].y, this.sunPos[minValue].z);
    let maxDir = new THREE.Vector3(this.sunPos[maxValue].x, this.sunPos[maxValue].y, this.sunPos[maxValue].z);
    let newDir = minDir.add(maxDir.sub(minDir).multiplyScalar(index - minValue)).multiplyScalar(this.lightPosOffset);

    (this.sky.material as THREE.ShaderMaterial).uniforms["sunPosition"].value = newDir;
    newDir.add(this.props.siteCenter);
    // this.dirLight.shadow.bias = -0.00003 + (-0.000004 * Math.abs(index - 4));

    this.dirLight.target.position.set(this.props.siteCenter.x, this.props.siteCenter.y, this.props.siteCenter.z);
    this.dirLight.position.set(newDir.x, newDir.y, newDir.z);
  };

  onKeyUp = async (event: KeyboardEvent) => {
    switch (event.key) {
      case "a":
        break;
      case "b":
        break;
      case "s":
        break;
      case "i":
      case "I":
        this.setState({ hideInfo: !this.state.hideInfo });
        break;
      default:
        break;
    }
  };

  onWindowResize = () => {
    this.setState({
      sceneWidth: 374, // window.innerWidth,// this.props.indexList.length > 1 ? (window.innerWidth - 120) / 2 : (window.innerWidth - 120),
      sceneHeight: 374, //window.innerHeight,// this.props.indexList.length > 2 ? (window.innerHeight - 60) / 2 : (window.innerHeight - 60),
    });
  };

  animate = () => {
    requestAnimationFrame(this.animate);

    this.sky.position.set(this.renderCamera.position.x, this.renderCamera.position.y, this.renderCamera.position.z);
    this.composer.render();
  };

  lookAtNorth = () => {
    this.perspectiveControls.target.set(this.state.siteCenter.x, this.state.siteCenter.y, this.state.siteCenter.z);
    this.perspectiveControls.object.position.set(this.state.siteCenter.x, this.state.siteCenter.y + 4, this.state.siteCenter.z + 5);
    this.perspectiveControls.update();
    this.updateCampass();
  };

  ScreenShot = () => {
    let imgData;
    var strMime = "image/jpeg";
    try {
      let ssRenderer = new THREE.WebGLRenderer({ antialias: true });
      ssRenderer.setSize(2560, 1440);
      let camera = new THREE.PerspectiveCamera(60, 2560 / 1440, 0.1, 450.0);
      camera.position.set(this.renderCamera.position.x, this.renderCamera.position.y, this.renderCamera.position.z);
      camera.matrix = this.renderCamera.matrix.clone();
      camera.lookAt(this.mainControl.target);
      camera.updateMatrix();

      ssRenderer.shadowMap.enabled = true;
      ssRenderer.shadowMap.type = THREE.VSMShadowMap;
      ssRenderer.render(this.scene, camera);

      imgData = ssRenderer.domElement.toDataURL(strMime);
      var link = document.createElement("a");
      if (typeof link.download === "string") {
        document.body.appendChild(link);
        link.download = `test.jpg`;
        link.href = imgData;
        link.click();
        document.body.removeChild(link);
      }
      ssRenderer.dispose();
      //@ts-ignore
      camera = null;
    } catch (e) {}
  };

  updateCampass = () => {
    let dir = new THREE.Vector3(0, 0, 0);
    this.renderCamera.getWorldDirection(dir);
    dir.projectOnPlane(new THREE.Vector3(0, 1, 0));
    dir.normalize();
    const worldDir = new THREE.Vector3(0, 0, -1);
    let angle = Math.acos(worldDir.dot(dir));
    angle = THREE.MathUtils.radToDeg(angle);

    worldDir.set(1, 0, 0);
    if (worldDir.dot(dir) < 0) {
      angle = 360 - angle;
    }

    let c = document.querySelector(`.compassImg0`) as HTMLImageElement;
    if (c) {
      c.style.transform = `rotate(${Math.floor(-angle)}deg)`;
    }
  };

  showLightSlider = () => {
    this.setState({ sunButtonOver: !this.state.sunButtonOver });
  };

  // 1: 용적률 2: 건폐율 3: 최고층수 4: 최고높이 5: 최대개발규모
  getDistrictUnitPlanInfo = (type: number) => {
    let planInfo = this.props.summary.districtUnitPlanInfo;
    if (!planInfo) {
      return "null";
    } else {
      switch (type) {
        case 1:
          if (planInfo.floorAreaRatio.allowed) return planInfo.floorAreaRatio.allowed;
          else if (planInfo.floorAreaRatio.basic) return planInfo.floorAreaRatio.basic;
          else return planInfo.floorAreaRatio.standard;
        case 2:
          return planInfo.coverAreaRatio;
        case 3:
          return planInfo.stories;
        case 4:
          if (planInfo.height.maximum) return planInfo.height.maximum;
          else return planInfo.height.standard;
        case 5:
          return planInfo.totalArea;
        default:
          return "null";
      }
    }
  };

  render = () => {
    return (
      <React.Fragment>
        <div
          className={`Canvas `}
          ref={(mount) => {
            this.mount = mount;
          }}
        />
        <div className="rightTop">
          <div className="imageButton" onClick={this.lookAtNorth}>
            <CompassIcon className={`compassImg0`} />
          </div>

          <div className={`imageButton ${(this.state.subBuildingVisible && "active") || ""}`} onClick={() => this.setState({ subBuildingVisible: !this.state.subBuildingVisible })}>
            <ApartmentIcon className={"image"} />
          </div>
        </div>
      </React.Fragment>
    );
  };
}
