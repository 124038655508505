import axios from 'axios';

const APIURL = '/api';

export const GetS3UrlAPI = (url: string, bucketName: string) => {
  return axios.post(`${APIURL}/GetS3Url`, {
    url,
    bucketName,
  });
}
