import wkx from 'wkx';
import Events from 'events';
import * as turf from '@turf/turf';
import { GeometryTypes } from '@turf/turf';
// @ts-ignore
import { reproject } from 'reproject';

import { Map } from './MapManager';
import { default as _ } from 'lodash';
// @ts-ignore
const { naver } = window;
// const jsts = require('jsts');


export default class DrawingManager extends Events {
  dm?: any;
  map?: Map;
  static nProj: string = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
  static bProj: string = '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs';

  constructor(map: Map) {
    super();
    this.map = map;
  }

  static toWKT = (shape: Shape) => {
    
    let path = shape.getPath();
    let type = shape.getType();
    if (type === "Polygon") {
      if ((shape as Polygon).multiPolygonPath !== undefined) {
        // @ts-ignore
        path = (shape as Polygon).multiPolygonPath!;
        // @ts-ignore
        for (let i = 0; i < path.length; i++) {
          // @ts-ignore
          for (let j = 0; j < path[i].length; j++) {
            // @ts-ignore
            path[i][j].push(path[i][j][0])
          }
        }
        type = "MultiPolygon";
      } else {
        // @ts-ignore
        for (let i = 0; i < path.length; i++) {
          // @ts-ignore
          path[i].push(path[i][0]);  
        }
      }
    } 
    return wkx.Geometry.parseGeoJSON(reproject({
      type: type, 
      coordinates: path,
    }, DrawingManager.nProj, DrawingManager.bProj)).toWkt();
    
  }


  // addInfoWindow = () => {
  //   const infoWindow = new InfoWindow();
  //   const nInfoWindow = new App.naver.maps.InfoWindow({
  //     maxWidth: 140,
  //     backgroundColor: "#eee",
  //     borderColor: "#2db400",
  //     borderWidth: 5,
  //     disableAnchor: true,
  //     pixelOffset: new App.naver.maps.Point(20, -20),
  //     content: '하이'
  //   });
  //   infoWindow.infoWindow = nInfoWindow;

  //   return infoWindow;
  // }
  addPolygon = (paths?: number[][][] , options?: PolygonOptions, controllable: boolean = false) => {
    
    const drawingOptions = {
      drawingControl: null,
      map: this.map!.getNaverMap(),
    }
    const nPolygon = new naver.maps.Polygon({
      ...options,
      paths: paths
    });

    const polygon = new Polygon();
        
    if (controllable) {
      this.dm = new naver.maps.drawing.DrawingManager(drawingOptions);
      this.dm.addListener('drawing_removed', () => polygon.emit('removed'));
      this.dm.addDrawing(nPolygon);
      
      // nPolygon.addListener('mouseup', (e: any) => this.overlayMoveEvent(e, polygon));
      nPolygon.addListener('click', (e: any) => polygon.emit('click'));

      // nPolygon.getPath().addListener('set_at', _.debounce(() => polygon.emit('changed')), 10);
      // nPolygon.getPath().addListener('insert_at', (index: number) => polygon.emit('vertex_added', index));
    } else {
      nPolygon.setMap(this.map!.getNaverMap());
    }
    polygon.setOverlay(nPolygon);
    polygon.setId(nPolygon.id);
    polygon.setType('Polygon');

    options && options.simplify && this.clearVertex(polygon);
    this.ccwVertex(polygon);

    if (controllable) {
      nPolygon.getPath().addListener('set_at', _.debounce(() => polygon.emit('changed')), 50);
      nPolygon.getPath().addListener('insert_at', (index: number) => polygon.emit('vertex_added', index));
    }


    return polygon;
  }
  static getProjectSiteCenter = (polygons: Polygon[]) => {
    if (polygons.length > 0) {
      let center: number[] = polygons.map(s => turf.center({ type:"Polygon", coordinates: s.getPath() }).geometry!.coordinates)
      .reduce((a: number[], b: number[]) => [a[0] + b[0], a[1] + b[1]]);
      const len = polygons.length;
      center = [center[0] / len, center[1] / len];
      return {
        centerCoordinates: center,
        centerWKT: wkx.Geometry.parseGeoJSON(reproject({
          type: 'Point',
          coordinates: center,
        }, DrawingManager.nProj, DrawingManager.bProj)).toWkt()
      };
    } else {
      return {
        centerCoordinates: [0, 0],
        centerWKT: ""
      }
    }
  }
  static toGeom = (wkt: string, type: GeometryTypes | "Field") => {
    let coordinates = undefined;
    switch (type) {
      case "Field":
        // @ts-ignore
        coordinates = wkx.Geometry.parse(wkt).toGeoJSON().coordinates[0];
        break;
      default:
        // @ts-ignore
        coordinates = wkx.Geometry.parse(wkt).toGeoJSON().coordinates;
      break;      
    }

    const gj = {
      type: type,
      coordinates: coordinates,
    }
    const geom = reproject(gj, DrawingManager.bProj, DrawingManager.nProj);
    if (type === "Polygon") {
      for (let i = 0; i < geom.coordinates.length; i++) {
        geom.coordinates[i].splice(geom.coordinates[i].length - 1, 1);
      }
    }
    return geom;
  }

  ccwVertex = (shape: Polygon) => {
    let path = shape.getPath();
    // console.log(path)
    for (let i = 0; i < path.length; i++) {
      path[i].push(path[i][0]);
    }
    
    const ccwPolygon = turf.rewind(turf.polygon(path));
    path = ccwPolygon.geometry!.coordinates;
    path.map((p: any) => p.splice(p.length - 1, 1));
    shape.setPath(path); 
  }

  clearVertex = (shape: Polygon) => {
    let path = shape.getPath();
    // input first vertex at last
    for (let i = 0; i < path.length; i++) {
      path[i].push(path[i][0]);
    }
    // clear equal and very near vertex
    const simplifiedPolygon = turf.simplify(turf.polygon(path), { tolerance: 0.00001 });
    path = simplifiedPolygon.geometry!.coordinates;
    path.map((p: any) => p.splice(p.length - 1, 1));
    if (shape.getType() === 'Polygon') {

      (shape as Polygon).setPath(path);
    } else {

    }
    
  }
  static DrawingOption = {
    JIGU_HOVER: {
      fillOpacity: 0,
      strokeColor: '#FA00FF',
      strokeWeight: 2,
      strokeStyle: 'solid',
      clickable: false, 
    },
    JIGU_ACTIVE: {
      fillColor: '#FA00FF',
      fillOpacity: 0.2,
      strokeColor: '#FA00FF',
      strokeStyle: 'solid',
      clickable: false, 
    },
    CAL_DISTANCE: {
      strokeColor: '#DC0091', 
      strokeWeight: 3,
      endIcon: 3,
      endIconSize: 7,
      startIcon: 3,
      startIconSize: 7,
    },
    CAL_AREA: {
      fillColor: '#B311FF',
      fillOpacity: 0.2,
      strokeColor: '#B311FF',
      strokeWeight: 1,
      strokeStyle: 'solid',
      clickable: false, 
    },
    CAL_MARKER: {
      draggable: false,
      icon: {
        content: '<div class="">지우기</div>',
      }
    },
    PROJECT_SITE_UNSELECTED: {
      fillColor: 'black',
      fillOpacity: 0.2,
      strokeColor: 'black',
      strokeWeight: 1,
      strokeStyle: 'solid',
      strokeOpacity: 0.5,
      zIndex: -10,
      clickable: true
    },
    PROJECT_SITE: {    
      fillColor: 'rgb(35, 39, 50)',
      fillOpacity: 0.3,
      strokeColor: "#232732",
      strokeWeight: 2,
      strokeStyle: 'shortdashdotdot',
      zIndex: -10,
      clickable: true,
      isProjectSite: true,
    },
    VACANCY_OUTSIDE: {
      fillColor: 'rgb(0, 161, 94)',
      fillOpacity: 0.3,
      strokeColor: 'rgb(0, 161, 94)',
      strokeWeight: 1,
      zIndex: -8,
      clickable: true,
    },
    VACANCY_INSIDE: {  
      fillColor: 'rgb(214, 48, 0)',
      fillOpacity: 0.3,
      strokeColor: 'rgb(214, 48, 0)',
      strokeWeight: 1,
      zIndex: -6,
      clickable: true,
    },
    ROAD_SPACE: {
      // fillColor: '#999999',
      // fillOpacity: 0.5,
      strokeWeight: 0,
      clickable: false,
      zIndex: -9,
      simple: true,
    },
    ROAD_SITE: {
      fillColor: 'rgb(90, 121, 165)',
      fillOpacity: 0.5,
      strokeWeight: 0,
      clickable: false,
      zIndex: -9,
      simple: true,
    },
    ROAD_VERTICAL: {
      strokeColor: 'rgb(90, 121, 165)',
      strokeWeight: 1,
      startIcon: 2,
      endIcon: 2,
      clickable: false,
      zIndex: -8,
      simple: true,
    },
    ROAD_VERTICAL_ACTIVE: {
      strokeColor: '#FA00FF',
      strokeWeight: 2,
      startIcon: 2,
      endIcon: 2,
      clickable: false,
      zIndex: -8,
      simple: true,
    },
    ROAD_HORIZONTAL: {
      strokeColor: 'rgb(90, 121, 165)',
      strokeWeight: 1,
      startIcon: 3,
      endIcon: 3,
      clickable: false,
      zIndex: -8,
      simple: true,
    },
    ROAD_HORIZONTAL_ACTIVE: {
      strokeColor: '#FA00FF',
      strokeWeight: 2,
      startIcon: 3,
      endIcon: 3,
      clickable: false,
      zIndex: -8,
      simple: true,
    },
    SKYLINE_LINE: {
      strokeColor: '#0076E3', 
      strokeWeight: 3,
      endIcon: 3,
      endIconSize: 7 ,
      startIcon: 3,
      startIconSize: 7,
      clickable: true,
      zIndex: -4
    },
    SKYLINE_CIRCLE: {
      strokeColor: '#0076E3',
      strokeWeight: 2 ,
      fillOpacity: 0.3,
      fillColor: '#0076E3',
      clickable: true,
      zIndex: -4,
    },
    MAX_FLOOR_MARKER: {
      clickable: true,
      draggable: true,
      icon: {
        url: "/img/max_floor.svg",
      },
      visible: true,
    },
    MIN_FLOOR_MARKER: {
      clickable: true,
      draggable: true,
      icon: {
        url: "/img/min_floor.svg",
      },
      visible: true,
    },
    HOME_MARKER: {
      draggable: false,
      icon: {
        content: '<div class="project-marker-wrap"></div>'
      }
    },
    BOUNDARY_SITE: {
      strokeColor: "#1919E6",
      strokeWeight: 2,
      fillColor: "#1919E6",
      zIndex: -18,
      fillOpacity: 0,
      clickable: false,
      simplify: false,
    },
    BOUNDARY_SITE_EXTENDED: {
      fillColor: 'rgb(0, 161, 94)',
      fillOpacity: 0.3,
      zIndex: -20,
      strokeColor: '#00A15E',
      strokeWeight: 1,
      clickable: false,
      simplify: false
    },
  };
}

export abstract class Shape extends Events {
  selected?: boolean;
  overlay: any;
  id: string | null;
  type: GeometryTypes | null;

  constructor() {
    super();
    this.id = null;
    this.type = null;
  }
  setOverlay = (overlay: any) => {
    this.overlay = overlay;
  }
  getOverlay = () => {
    return this.overlay;
  }

  setSelected = (select: boolean) => {
    this.selected = select;
  }
  getSelected = () => {
    return this.selected;
  }
  setVisible = (visible: boolean) => {
    this.overlay.setVisible(visible);
  }
  setId = (id: string) => {
    this.id = id;
  }
  getId = () => {
    return this.id;
  }
  setType = (type: GeometryTypes) => {
    this.type = type;
  }
  getType = () => {
    return this.type;
  }
  setEditable = (editable: boolean) => {
    if (this.overlay.getEditable() === undefined) {
      return;
    }
    this.overlay.setEditable(editable);
  }
  getEditable = (): boolean => {
    return this.overlay.getEditable();
  }
  getPath() {};

  remove = (all: boolean = false) => {
    this.overlay.setMap(null);
    if (all === false) {
      this.emit('removed');
    }
  }
}

export class Polygon extends Shape {
  multiPolygonPath?: [][][][] = undefined;
  pnu?: string;
  setPnu = (pnu: string) => {
    this.pnu = pnu;
  }
  getPnu = () => {
    return this.pnu;
  }
  setOptions = (option: PolygonOptions) => {
    this.overlay.setOptions({...option});
  }

  getPath = (): number[][][] => {
    return this.overlay.getPaths()._array.map((r: any) => r._array.map((rr: any) => [rr.x, rr.y]));
  }

  setPath = (paths: number[][][]) => {
    this.overlay.setPaths(paths);
    this.emit('changed');
  }

  getCenter = () => {
    return this.getPath()[0].reduce((a: number[], b: number[]) => [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2]);
  }

  getLastVertex = () => {
    const path = this.getPath();
    return path[0][path[0].length - 1];
  }

  setRightClick = (on: boolean, callback?: Function) => {

    if (on) {
      this.overlay.__event_relations__.rightclick = callback && callback;
    } else {
      this.overlay.__event_relations__.rightclick = null;
    }

  }

  getRightClick = () => {
    return this.overlay.__event_relations__.rightclick;
  }



}

  export interface PolygonOptions {
    map?: any;
    fillColor?: string; // #xxxxxx
    fillOpacity?: number; // 0~1
    strokeColor?: string; // #xxxxxx
    strokeOpacity?: number; // 0~1
    strokeWeight?: number; // 1~5
    strokeStyle?: string; // 'shortdashdotdot' ... naver api 참고
    zIndex?: number; // -xx ~ +xx
    clickable?: boolean;
    visible?: boolean;
    isProjectSite?: boolean;
    simplify?: boolean;
    controllable?: boolean;
  }
