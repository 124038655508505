import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { default as _ } from 'lodash';
import { FlexContainer, HorizontalEmptyDivide } from '../../styledComponents/layout';
import { LegendCircleText } from '../common/LegendCircleText';

const COLORS = {
  '단독주택': '#FF8042',
  '다세대주택': '#CD6EDD',
  '다가구주택': '#FFD54F',
  '연립주택': '#A0AFFF',
  '다중주택': '#666666',
  '아파트': '#80DEEA',
}

const getChartData = (data: any) => {
  let result = [];  
  
  const sum = Object.keys(data)
    .filter((item: any) => {
      if(item.indexOf('세대수') >= 0) return true;
      else return false;
    })
    .map((item: any) => data[item])
    .reduce((a: number, b: number) => Number(a + b));
  
  for (const [key, value] of Object.entries(data)) {
    switch (key) {
      case '세대수_단독주택':
        result.push({ name: '단독주택', value: parseFloat(Number(Number(value) / sum * 100).toFixed(1)) });        
        break;
      case '세대수_다세대주택':
        result.push({ name: '다세대주택', value: parseFloat(Number(Number(value) / sum * 100).toFixed(1)) });        
        break;
      case '세대수_다가구주택':
        result.push({ name: '다가구주택', value: parseFloat(Number(Number(value) / sum * 100).toFixed(1)) });        
        break;
      case '세대수_다중주택':
        result.push({ name: '다중주택', value: parseFloat(Number(Number(value) / sum * 100).toFixed(1)) });        
        break;
      case '세대수_연립주택':
        result.push({ name: '연립주택', value: parseFloat(Number(Number(value) / sum * 100).toFixed(1)) });        
        break;
      case '세대수_아파트':
        result.push({ name: '아파트', value: parseFloat(Number(Number(value) / sum * 100).toFixed(1)) });        
        break;
    }
  } 
  return result;
}

export const BuildingTypeChart = ({...props}) => {

  const { data } = props;
  const chartData = getChartData(data);  
  
  return (
    <FlexContainer width="100%" height="100%" justifyContent="space-between">      
      <PieChart width={200} height={200}>
        <Pie
          data={chartData}
          cx="60%"
          cy="50%"
          labelLine={false}          
          outerRadius={80}
          fill="#fff"
          dataKey="value"
        >
          {chartData.map((item: any, index: any) => (
            <Cell key={`cell-${index}`} fill={_.get(COLORS, item.name)} />
          ))}
        </Pie>
      </PieChart>
      
      
      <FlexContainer direction="column" width="40%" height="200px" justifyContent="center" alignItems="flex-between">
        {chartData.map((item: any, index: Number) => (
          <div key={`${item.name}_${index}_div`} style={{ width: '100%'}}>
            <LegendCircleText key={`${item.name}_${index}`} width="90%" text={`${item.name}  ${item.value}%`} color={_.get(COLORS, item.name)} />
            <HorizontalEmptyDivide key={`Divide_${index}`}/>
          </div>
          
        ))}

      </FlexContainer>
    </FlexContainer>
  )
}

 