/*eslint array-callback-return: "off"*/
import React, { useEffect, useRef, useState } from "react";
// import { default as _ } from 'lodash';
import ProjectResultTitle from "./ProjectResultTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../modules/Index";
import Grid from "@material-ui/core/Grid";

const OfficeFloorDivision = () => {
  const { resultInfoData } = useSelector(({ selectedProject }: RootState) => ({
    resultInfoData: selectedProject.result,
  }));
  const resImgLength = resultInfoData.resImages.length; // 결과이미지 수
  const pageRef = useRef(1);
  const [pageIndex, setPageIndex] = useState([] as Array<Array<number>>);
  //  const [floorDescript, setFloorDescript] = useState<Array<string>>([]);
  const DIVISION_NUM = 3; // 페이지 분할할 이미지 수 (오피스텔은 이미지 한 개)

  useEffect(() => {
    pageRef.current = Math.ceil(resImgLength / DIVISION_NUM);
    let page_index: Array<Array<number>> = [...pageIndex]; // _.cloneDeep(pageIndex);
    let tempNumber = 0;
    let startIndex = 0;
    let endIndex = DIVISION_NUM - 1;
    for (let i = 0; i < pageRef.current; i++) {
      page_index.push([startIndex, endIndex]);
      tempNumber += DIVISION_NUM;
      startIndex = tempNumber;
      endIndex = startIndex + DIVISION_NUM - 1;
    }
    setPageIndex(page_index);
    // console.log("page_index", page_index);
    /* 사진 설명 */
    // const imgFloor = resultInfoData.resImages.map((url: string) => {
    //   const floor = /image_\d*.png/.exec(url)![0].replace(/[^0-9]/g , "");
    //   return Number(floor);
    // })
    // imgFloor.forEach((floor:number, idx:number) => {
    //   if (floor === 1){
    //     floorDescript.push('배치도')
    //   }
    //   else if (idx === imgFloor.length - 1) { // 마지막 이미지
    //     const maxFloor = resultInfoData.loc_building_stories_min;
    //     floorDescript.push(
    //       floor ===  maxFloor ? floor + '층 평면도' : floor + '~' + (maxFloor) + '층 평면도')
    //   }
    //   else {
    //     const limitFloor = imgFloor[idx+1] - 1
    //     floorDescript.push(
    //       floor === limitFloor ? floor + '층 평면도' : floor + '~' + (imgFloor[idx+1] - 1) + '층 평면도')
    //   }
    // });
    // setFloorDescript(floorDescript)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="OfficeFloorDivision">
        <ProjectResultTitle></ProjectResultTitle>
        <div className={`sub-title`}>{`4. 층별 평면도`}</div>
        <Grid container spacing={1} justify={`center`}>
          {resultInfoData.resImages.map((url: string, index: number) => {
            return (
              <Grid item spacing={3}>
                <article key={url}>
                  <img className="result-img" src={url} alt="결과 이미지"></img>
                </article>
              </Grid>
            );
          })}
        </Grid>
      </section>
    </>
  );
};

export default OfficeFloorDivision;
