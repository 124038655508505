import { createAction, handleActions } from 'redux-actions';
import  { createRequestActionTypes } from '../lib/createRequestSaga';
import { getFieldInfoAPI } from '../api/FieldInfoApi';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import { TMCoordinate } from '../MapManager'
import { RootState } from './Index';
import { getPriceInfo } from './PriceInfo';
import { sessionExpired } from './Auth';
import { showErrorMessage } from './InformMessage';

const [
  GET_FIELD_INFO_AT_POINT,
  GET_FIELD_INFO_AT_POINT_SUCCESS,
  GET_FIELD_INFO_AT_POINT_FAILURE,
] = createRequestActionTypes('GET_FIELD_INFO_AT_POINT');

const INITIALIZE_FIELD_INFO = 'INITIALIZE_FIELD_INFO';

export const getFieldInfoAtPoint = createAction(
  GET_FIELD_INFO_AT_POINT,
  (coord: TMCoordinate) => coord
);
export const initializeFieldInfo = createAction(INITIALIZE_FIELD_INFO);

function* getFieldInfoAtPointSaga(action: any) {
  //console.log(action.payload.x, action.payload.y, action.payload,'aa')

  const solverloading = yield select((state: RootState) => state.project.solverloading);

  if(!solverloading) {          // 프로젝트 run 실행중이 아닐때.    
    try {
      const response = yield call(getFieldInfoAPI, action.payload);       
      yield put({
        type: GET_FIELD_INFO_AT_POINT_SUCCESS,
        payload: response.data,
        meta: response,    
      });    
      yield put(getPriceInfo(response.data.pnu));
    } catch (e) {
      console.log('e',e)
      if(e.response.status === 440) {
        yield put(sessionExpired());
        return;
      }
      // console.log(e.msg)
      yield put(showErrorMessage({
        msg: "필지정보 수신 실패",
        errorMessage: e.msg,
        autoClose: 3000,   
      }));
      yield put({
        type: GET_FIELD_INFO_AT_POINT_FAILURE,
        payload: e.msg,
      });
    }
    
  }
  
}
//solverloading
export function* watchFieldInfo() {
  yield takeEvery(GET_FIELD_INFO_AT_POINT, getFieldInfoAtPointSaga);
}

interface FieldInfoData {
  pnu?: string;
  geom?: string;
  jimog?: string;
  address?: string;
  use_district?: string;
}

interface FieldInfoState {
  type?: string;
  data?: FieldInfoData;
  meta?: string;
  error?: string;
};

const initialState: FieldInfoState = {
  data: {},
};


  
const fieldInfo = handleActions<FieldInfoState, any>(
  {
    [GET_FIELD_INFO_AT_POINT_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
    [GET_FIELD_INFO_AT_POINT_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [INITIALIZE_FIELD_INFO]: () => initialState,
  },
  initialState,
);

export default fieldInfo;